import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';
import { useAppContext } from '../../App';
import {toTimeLength} from '../../pages/Garage/index.jsx'
import { toTopia } from '../../web3-hooks';
import { useMemo } from 'react';
const Documentation = () => {
  const appContext: any = useAppContext();
  const {
    reservePrice,
    duration,
    winnerSettlementTime,
    freeForAllSettlementTime
  } = appContext;
  const playgroundLink = <Link text="Playground" url="/playground" leavesPage={false} />;
  const SweepersTreasury = <Link text="Sweepers DAO" url="https://sweepers.wtf" leavesPage={true} />;
  const publicDomainLink = (
    <Link
      text="public domain"
      url="https://creativecommons.org/publicdomain/zero/1.0/"
      leavesPage={true}
    />
  );
  const compoundGovLink = (
    <Link text="Compound Governance" url="https://compound.finance/governance" leavesPage={true} />
  );
  const auctionPrice = useMemo(() => parseFloat(toTopia(reservePrice?.toString())), [reservePrice])
  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper} style={{ marginBottom: '1rem' }}>
          <h1>Summary</h1>
          <br></br>
          <p>Welcome to the Sweepers Club! Never before has $DUST been in such high demand. A new Sweeper NFT is born every {toTimeLength(duration, true, false, true)} and sent to the community auction forever. Once holders own a Sweeper NFT, they gain a comprehensive set of utilities and unlimited access to top-tier blue-chip NFT projects.<br></br><br></br>
            Each Sweeper NFT holder is an irrevocable member and entitled to one vote per Sweeper NFT held in all governance matters.<br></br><br></br>
            In its entirety, Sweepers Club will consist of the following platforms:</p>
          <ul>
            <li>THE GARAGE (Stake your Sweeper NFT and earn $DUST)</li>
            <li>THE VAULT (Purchase “swept” NFTs here using your $DUST tokens)</li>
            <li>The Peer-to-Peer Exchange (Holders can buy and sell $DUST tokens to other holders)</li>
          </ul>
          <p>Read the information below for more details on Sweepers Club governance, utility, and platforms. And get ready to start sweeping!</p>
          <br></br>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>A Brief Introduction to Sweepers</Accordion.Header>
            <Accordion.Body>
              <h3>What are Sweepers?</h3>
              <p>The 32x32 pixel characters are inspired physically by brooms and figuratively inspired by “sweeping the floor” of blue-chip NFT projects.</p>
              <h3>What is the LegacyLab Program?</h3>
              <p>LegacyLab is a program that allows artists and investors to create and own different projects on the platform - LegacyLab. The LegacyLab will enable developers to bring new and innovative Web3 projects to life with the support of the LegacyBad team. All projects built with the help of the Legacy Lab will run on the $DUST token.</p>
              <h3>Sweepers Governance</h3>
              <p>The Sweepers Governance receives 95% of ETH proceeds from daily Sweeper Auctions, excluding every tenth completed auction. The proceeds of the tenth one go to the Founders of Sweepers for future development and marketing.
                <br></br><br></br>
                Sweepers, based on a fork of Compound Governance, is also the main governing body of the Sweepers ecosystem. Each Sweeper is an irrevocable member of Sweepers and entitled to one vote in all governance matters.
                <br></br><br></br>
                Sweepers use their voting abilities (remember, 1 Sweeper = 1 vote) to direct the treasury. Sweepers can create and vote on governance proposals, executing transactions on the Ethereum blockchain when approved.</p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              {toTimeLength(duration, true, true)} Auctions
            </Accordion.Header>
            <Accordion.Body>
              <p>The Sweepers Auction Contract will act as a self-sufficient Sweeper NFT generation and distribution mechanism.
                <br></br><br></br>
                95% of Sweepers auction proceeds are sent to the Sweepers Treasury. Membership proposals, voting, and treasury information are outlined in the Sweepers Governance Protocol.
                <br></br><br></br>
                Each time an auction is settled, the transaction will also cause a new Sweeper to be minted, and a new auction begins.
                <br></br><br></br>
                While settlement is incentivized for the winning bidder, it can be triggered by anyone, allowing the system to trustlessly auction Sweepers as long as Ethereum is operational and there are active bidders.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Bidding and Settling Auction Bids
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Once an auction starts, everyone has {toTimeLength(duration, true, false, true)} to bid. Anyone can initiate the bidding with an amount at or above {auctionPrice} ETH.
                <br></br><br></br>
                Until the auction concludes, due to the above settlement process, bids are locked until the settlement is processed.
                <br></br><br></br>
                The amount bid is returned to the bidder if they lose the auction.
                <br></br><br></br>
                Any bids at the last minute increase the auction time by one minute.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Bid Refunds</Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>Unsuccessful bids are refunded in full. One bidder may offset the timing of repayments, which means a refund is processed for an unsuccessful bid when a higher bid is submitted.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Auto-Settlement</Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>Sweepers Club holders will not have to wait for auction winners to settle and claim their NFT for a new one to occur. The automatic settlement feature includes the settlement cost in the bid amount.
For example, if a holder wins the Sweeper NFT auction at the amount of {auctionPrice} ETH. The {auctionPrice} ETH will include the settlement cost, and once the auction is over, the automatic settlement feature will send the NFT to the winner's wallet, and the next one will begin.

              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Sweepers Governance</Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>Sweepers utilize a fork of Compound Governance as the main governing body of the Sweepers ecosystem. Each Sweeper NFT holder is an irrevocable member of Sweepers and entitled to one vote in all governance matters. Sweeper votes are non-transferable (if you sell your Sweeper, the vote goes with it) but delegatable, which means you can assign your vote to someone else as long as you own your Sweeper.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Sweepers Governance ‘Slow Start’ Protocol</Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>In addition to the precautions taken by Compound Governance, the founders of Sweepers have given themselves a special veto right to ensure that no malicious proposals can be passed while the Sweepers supply is low. Sweepers Founders will only use this veto right if a harmful governance proposal has been passed and is intended as a last resort.
                <br></br><br></br>
                The Founders will revoke this veto right when they deem it safe. This decision will be based on a healthy Sweepers distribution and a community engaged in the governance process.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Sweepers Members Governance Protocols</Accordion.Header>
            <Accordion.Body>
              <p>Sweepers Governance Protocols are outlined to describe the benefit of members voting for the owners of any Sweepers NFT.</p>
              <p>Every owner of at least one Sweepers NFT is considered a member of Sweepers and therefore has voting rights based on the number of Sweepers NFTs the member owns.</p>
              <p>All Sweepers Auctions (non-founder) proceeds are sent trustlessly to the following:</p>
              <ul>
                <li>95% of auction proceeds (ETH) are automatically deposited in the Sweepers Treasury, where Sweepers owners govern them. Sweeper NFT holders will have the opportunity to propose which NFT project floor the collective membership of Sweepers will sweep next and the number of funds requested for the sweep. Funds deposited into the Sweepers Member Sweep Treasury will also be subject to proposals made for the weekly SweepTank Events. Sweepers community will host weekly SweepTank events (including but not limited to Twitter Spaces, Discord, and Twitch). These events will host up to, but not limited to, ten NFT Project developers who are pitching the Sweepers Members as to why Sweepers should sweep their project. These events are hosted by Project Founders, NFT Influencers, and Advisers in the space. </li>
                <li>5% will go to the Legacy Builders Program, which Sweepers Club is its first initiative,  and be used further to develop Sweepers and other community-driven projects. </li>
              </ul>
              <p>A member of Sweepers can make a proposal to the Sweepers Treasury if they own a minimum of 5 (subject to a governance vote to change as holders grow) of the total Sweepers. Each proposal is voted on collectively by the members of Sweepers. Sweepers is genuinely a community-driven and decentralized organization with regards to the decisions that are made on which projects are swept and the assets that end up in THE VAULT for Sweepers Members to have the ability to acquire via auction.</p>
              <p>If you are considering proposing, please read the rules in the “Make a Proposal” Document.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Sweeper Traits and Attributes</Accordion.Header>
            <Accordion.Body>
              <p>Sweepers generate randomly based on Ethereum block hashes. As of this writing, Sweepers consist of the following attributes:</p>
              <ul>
                <li>Backgrounds (2)</li>
                <li>Cuts (30)</li>
                <li>Colors (137)</li>
                <li>Angles (234)</li>
                <li>Sizes (21)</li>
              </ul>
              <p>Some rare traits can offer multipliers and increase the amount of $DUST Tokens accumulated daily for holders.</p>
              <ul>
                <li>Bathroom (Background) - 5 extra $DUST per day (3%)</li>
                <li>Garage (Background) - 10 extra $DUST per day (1%)</li>
                <li>Vault (Background) - 15 extra $DUST per day (.50%)</li>
                <li>Dusty (Background) - 25 extra $DUST per day (.25%)</li>
              </ul>
              <p>Sweepers are stored directly on the blockchain and do not utilize pointers to other networks like IPFS because Sweepers parts are compressed and stored on-chain using a custom run-length encoding (RLE), a form of lossless compression.</p>
              <p>The compressed parts convert into a single base64 encoded SVG image on-chain. Each part is decoded into an intermediate format before being converted into a series of SVG rects using batched, on-chain string concatenation. Once the entire SVG generates, it is base64 encoded.</p>
              <p>The Sweepers Seeder contract determines Sweeper traits during the minting process. The seeder contract can be replaced to allow for future trait generation algorithm upgrades. Additionally, it can be locked by the Sweepers to prevent any future updates.</p>
              <p>Currently, Sweepers traits are determined using pseudo-random number generation.</p>
              <p>Trait generation is not truly random. Traits can be predicted when minting a Sweeper NFT on the pending block.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Sweepers Founder's Reward
            </Accordion.Header>
            <Accordion.Body>
              <p>'Sweepers Founders' are the group of developers that initiated Sweepers.</p>
              <p>Because almost all of the regular Sweepers Auction proceeds are sent to Sweepers Governance to benefit holders, Founders have chosen to compensate themselves with profits of every tenth Sweeper NFT auctioned. Every tenth Sweepers auction is considered the Founder's Auction.</p>
              <p>All proceeds for every 10th Sweeper of the project auctions (Sweepers ids #0, #10, #20, #30, etc.) will be automatically sent to the Founders. This transaction allows the Founders to either buy in the open market to build their voting power or to use the proceeds to support the project through development, collaborations, partnerships, marketing, and awareness campaigns.</p>
              <p>Founders' distributions do not interfere with the cadence of 10-minute auctions.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Sweepers - How do they work?
            </Accordion.Header>
            <Accordion.Body>
              <p>Each Sweeper held by its owner will have the opportunity to participate in every round of Sweepers Governance voting. As soon as one takes ownership of a Sweeper, they are encouraged to do the following:</p>
              <ul>
                <li>Stake a Sweeper in the GARAGE.</li>
                <li>Earn $DUST Token while staked in the GARAGE.</li>
                <li>$DUST Token will be exclusive to Sweepers Members and will have two utilities.
                  <ul>
                    <li>Earn $DUST Tokens and spend them in THE VAULT to acquire NFTs swept by the Sweepers Member vote.</li>
                    <li>Offer $DUST Tokens in the $DUST Exchange, a peer-to-peer exchange platform where Sweepers Members can acquire more $DUST Tokens.</li>
                  </ul>
                </li>
                <li>The daily $DUST token earned is a maximum of 10 $DUST Tokens.</li>
                <li>While staked in the GARAGE and earning $DUST Token, each Sweeper can propose and vote on all proposals that come through the Governance System.</li>
                <li>The goal is to earn as many $DUST Tokens as possible to have buying power in THE VAULT. THE VAULT is an auction platform where all swept NFT assets from all Sweepers Member Proposals, Sweep Tank Events, and Reserve Funds are sent to be auctioned off for $DUST Tokens.</li>
                <li>In THE VAULT, each NFT auction will start at a bid of one $DUST Token and will be on a predetermined number of days for each auction.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              The $DUST Token
            </Accordion.Header>
            <Accordion.Body>
              <p>$DUST Token is the reward token earned daily by all Sweepers Members who stake their Sweeper NFT in the GARAGE. The GARAGE will be a platform on the Sweepers website, allowing members to stake their Sweeper NFT to earn $DUST Tokens. $DUST Token will have two utilities.</p>
              <p>The first utility will allow members to collect a balance of $DUST Tokens and use their earned $DUST Tokens to bid on auctions in THE VAULT platform, an auction marketplace where all swept NFT assets will be listed for auction.</p>
              <p>$DUST Token's second utility will be to sell any balance of $DUST Token in the Peer-to-Peer exchange called The $DUST Exchange. Here Sweepers Members can offer any amount of $DUST Tokens for Sale and accept offers.</p>
              <p>The $DUST Token will never have a liquidity pool added to it or be tradeable on an exchange. $DUST Token is to be used inside of the Sweepers Project only.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              THE VAULT
            </Accordion.Header>
            <Accordion.Body>
              <p>THE VAULT is an online auction marketplace where swept NFT assets are sent to be offered off at auction starting at one $DUST Token. All swept assets from Sweepers Member Proposals, Sweep Tank Events, and Reserve Fund sweeps get sent to THE VAULT.</p>
              <p>The VAULT will host many different auction lengths based on the type of sweep, type of project, etc. The idea is for everyone to participate in THE VAULT Auctions regardless of the number of Sweeper NFTs each holder owns or stakes.</p>
              <p>With $DUST Tokens, the buying power of the Sweepers Members will rotate, and the idea is that everyone will have a chance to participate in the long run.</p>
              <p>The strategy will be everything, some will buy as soon as possible, and others will wait for the grail NFT they have wanted for a long time.</p>
              <p>Pick the strategy that fits you best!</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              The $DUST Exchange
            </Accordion.Header>
            <Accordion.Body>
              <p>The $DUST Exchange is a place that allows Sweepers Members the ability to buy or sell their $DUST Token amongst members of Sweepers. Some members will be waiting for the grail NFT they always wanted, and some will be just short of being able to win a bid and in need of a top-off of more $DUST Tokens.</p>
              <p>The $DUST Exchange will allow Sweepers Members to list their $DUST Token for a buy-it-now price and accept offers on their chosen balance.</p>
              <p>Members of Sweepers can choose to either Buy $DUST Tokens at the list price or make an offer to buy at a price offered on a secondary market, which allows for a healthy exchange environment for $DUST Tokens.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
