import { useContractCall, useContractFunction, useEthers } from '@usedapp/core';
import { BigNumber as EthersBN, ethers, utils } from 'ethers';
import { SweepersTokenABI, SweepersTokenFactory, SweepersDescriptorABI } from '@nouns/contracts';
import config from '../config';

interface SweeperToken {
  name: string;
  description: string;
  image: string;
}

export interface ISweeperSeed {
  accessory: number;
  background: number;
  head: number;
  body: number;
  eyes: number;
  mouth: number;
}

export enum SweepersTokenContractFunction {
  delegateVotes = 'votesToDelegate',
}

const abi = new utils.Interface(SweepersTokenABI);
const abiDesc = new utils.Interface(SweepersDescriptorABI);

export const useSweeperToken = (sweeperId: EthersBN) => {
  const [sweeper] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.sweepersToken,
      method: 'dataURI',
      args: [sweeperId],
    }) || [];

  if (!sweeper) {
    return;
  }

  const sweeperImgData = sweeper.split(';base64,').pop() as string;
  const json: SweeperToken = JSON.parse(atob(sweeperImgData));

  return json;
};

export const useSweeperSeed = (sweeperId: EthersBN) => {
  const seed = useContractCall<ISweeperSeed>({
    abi,
    address: config.addresses.sweepersToken,
    method: 'seeds',
    args: [sweeperId],
  });
  return seed;
};

export const useSweeperSVG = (seed: ISweeperSeed | undefined) => {
  const svg = useContractCall<string>({
    abiDesc,
    address: "0x798654cCbdC18b66eEaE893362135A121236D640",
    method: 'generateSVGImage',
    args: [seed],
  });
  console.log('mainSVG', svg);
  return svg?.toString();
};

export const useBigSweeperSeed = (sweeperId: EthersBN) => {
  const seed = useContractCall<ISweeperSeed>({
    abi,
    address: "0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03",
    method: 'seeds',
    args: [sweeperId],
  });
  return seed;
};

export const useUserVotes = (): number | undefined => {
  const { account } = useEthers();
  return useAccountVotes(account ?? ethers.constants.AddressZero);
};

export const useAccountVotes = (account?: string): number | undefined => {
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.sweepersToken,
      method: 'getCurrentVotes',
      args: [account],
    }) || [];
  return votes?.toNumber();
};

export const useUserDelegatee = (): string | undefined => {
  const { account } = useEthers();
  const [delegate] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.sweepersToken,
      method: 'delegates',
      args: [account],
    }) || [];
  return delegate;
};

export const useUserVotesAsOfBlock = (block: number | undefined): number | undefined => {
  const { account } = useEthers();

  // Check for available votes
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.sweepersToken,
      method: 'getPriorVotes',
      args: [account, block],
    }) || [];
  return votes?.toNumber();
};

export const useDelegateVotes = () => {
  const sweepersToken = new SweepersTokenFactory().attach(config.addresses.sweepersToken);

  const { send, state } = useContractFunction(sweepersToken, 'delegate');

  return { send, state };
};

export const useSweeperTokenBalance = (address: string | undefined): number | undefined => {


  //  const { account } = useEthers();

    const [tokenBalance] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.sweepersToken,
      method: 'balanceOf',
      args: [address],
    }) || [];

    return tokenBalance?.toNumber();

};