/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, createContext, useContext, useLayoutEffect, useMemo, useCallback } from 'react';
import { useEthers } from '@usedapp/core';
import { useAppDispatch, useAppSelector } from './hooks';
import { setActiveAccount } from './state/slices/account';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import { setAlertModal } from './state/slices/application';
import classes from './App.module.css';
import AlertModal from './components/Modal';
import NavBar from './components/NavBar';
import NetworkAlert from './components/NetworkAlert';
import Footer from './components/Footer/index.jsx';
import AuctionPage from './pages/Auction';
import PreLaunch from './pages/PreLaunch';
import GovernancePage from './pages/Governance';
import CreateProposalPage from './pages/CreateProposal';
import VotePage from './pages/Vote';
import SweepersVotePage from './pages/SweepersVote';
import FoundersPage from './pages/Founders';
import SweeperersPage from './pages/Sweeperers';
import NotFoundPage from './pages/NotFound';
import Vault from './pages/Vault';
import Playground from './pages/Playground';
import Garage from './pages/Garage';
import Sweeperverse from './pages/Sweeperverse';
import config, { CHAIN_ID } from './config';
import { Col, Row } from 'react-bootstrap';
import { useWallet, useEthBalance, useTokenBalance, useEthToUSD, useNewNFTWallet } from './web3-hooks'
import { useInactiveListener } from './web3-hooks';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useContract } from './web3-hooks';
import AuctionHouseABI from './abis/AuctionHouseABI.json';
// import emojiPage from './pages/Emojis';
import EmojiBubble from './components/EmojiShower/EmojiBubble';
import { BigNumber } from 'ethers';
import { AvatarProvider } from '@davatar/react';
import IdeasPage from './pages/Ideas';
import IdeaPage from './pages/Ideas/_id';
import CreateIdeaPage from './pages/Ideas/Create';
import Exchange from './pages/Exchange';
import Referral from './pages/Referral';
import Migrate from './pages/Migrate';

export const AppContext = createContext(undefined);
export const useAppContext = () => useContext(AppContext);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const initialState = {

}
const changeState = (state, change) => {
  if (typeof change === 'string') {
    switch (change) {

      default: return state
    }
  }
  if (typeof change === 'function') return change(state)
  if (typeof change === 'object') return { ...state, ...change }
  return state
}
function App({ location, history }) {
  const { account, chainId, library } = useEthers();
  const { wallet: oldWallet, refreshWallet: refreshOldWallet, loading: loadingOldWallet } = useWallet()
  const { wallet, refresh: refreshWallet, loading: loadingWallet } = useNewNFTWallet()
  const { balance: ethBalance, refreshBalance: refreshEthBalance } = useEthBalance()
  const { balance: tokenBalance, refreshBalance: refreshTokenBalance } = useTokenBalance()
  const { price: ethPrice, refresh: refreshEthPrice } = useEthToUSD()
  const [reservePrice, setReservePrice] = useState(BigNumber.from(0));
  const [duration, setDuration] = useState(0);
  const [winnerSettlementTime, setWinnerSettlementTime] = useState(0);
  const [freeForAllSettlementTime, setFreeForAllSettlementTime] = useState(0);
  const [auctionBufferTimeIncrease, setAuctionBufferTimeIncrease] = useState(0);
  const [gettingAuctionHouseInfo, setGettingAuctionHouseInfo] = useState(false);
  const dispatch = useAppDispatch();
  useInactiveListener(true)
  dayjs.extend(relativeTime);
  const [width, setWidth] = useState(window.innerWidth);
  const queries = useMemo(() => location?.search?.split('?')?.[1]?.split('&').reduce((a, b) => {
    let c = b.split('=')
    return { ...a, [c[0]]: c[1] }
  }, {}) || {}, [location])
  const { pathname } = location
  const redirect = (path = '/') => history.push(path)
  const alertModal = useAppSelector(state => state.application.alertModal);

  const [emojiQueue, setEmojiQueue] = useState(['']);
  // const [isFirst, setIsFirst] = useState(false);

  // const randomSize = (min: number, max: number) =>
  //   (Math.random() * (max - min + 1) + min).toFixed(2);

  // const randomLeft = () => {
  //   const maths = Math.floor(Math.random() * 97 + 1);
  //   console.log(`RANDOMLEFT: ${maths}`);
  //   return maths;
  // };
  const contract = useContract(config.addresses.sweepersAuctionHouseProxy, AuctionHouseABI, false);
  useEffect(() => {
    if (contract && reservePrice.eq(0) && !gettingAuctionHouseInfo) {
      setGettingAuctionHouseInfo(true);
      contract.reservePrice().then(price => {
        setReservePrice(price);
        return contract.duration()
      }).then(duration => {
        setDuration(duration.toNumber() * 1000);
        return contract.winnerSettlementTime();
      }).then(winnerTime => {
        setWinnerSettlementTime(winnerTime.toNumber() * 1000);
        return contract.settlementTimeinterval();
      }).then(freeTime => {
        setFreeForAllSettlementTime(freeTime.toNumber() * 1000);
        return contract.timeBuffer()
      }).then(int => {
        setAuctionBufferTimeIncrease(int.toNumber() * 1000);
      }).catch((e) => { console.error(e); setReservePrice(BigNumber.from(10).pow(16)); }).finally(() => setGettingAuctionHouseInfo(false));
    }
  }, [contract])
  const isPreLaunch = config.isPreLaunch === 'true';
  useLayoutEffect(() => {
    const resize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  })
  const startPath = useMemo(() => pathname, [])
  const [previousPathname, setPreviousPathname] = useState(pathname)
  const [hasNavigated, setHasNavigated] = useState(false)
  useLayoutEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0
    if (pathname !== startPath || hasNavigated) {
      setHasNavigated(true);
      if (typeof window.analyze === 'function') {
        window.analyze(2, { pathname, originalPathname: startPath, previousPathname })
      }
      setPreviousPathname(pathname);
    }
  }, [pathname, queries])
  useEffect(() => {
    // Local account array updated
    dispatch(setActiveAccount(account));
  }, [account, dispatch]);

  const emojiBubbleMarkup = emojiQueue.map((emojiVals, i) => (
    <EmojiBubble key={i} {...emojiVals} />
  ));

  const flash = useCallback(message => {
    alert(message)
  }, [])

  return (<AppContext.Provider value={{
    flash,
    account,
    wallet,
    refreshWallet,
    loadingWallet,
    oldWallet,
    refreshOldWallet,
    loadingOldWallet,
    refreshEthPrice,
    ethBalance,
    tokenBalance,
    ethPrice,
    balance: tokenBalance,
    refreshEthBalance,
    refreshTokenBalance,
    refreshBalance: refreshTokenBalance,
    pathname,
    redirect,
    queries,
    width,
    reservePrice,
    duration,
    winnerSettlementTime,
    freeForAllSettlementTime,
    auctionBufferTimeIncrease
  }}>
    <div className={`${classes.wrapper}`}>
      {Number(CHAIN_ID) !== chainId && <NetworkAlert />}
      {alertModal.show && (
        <>
          <AlertModal
            title={alertModal.title}
            content={
              <>
                <p>{alertModal.message}</p>

                {alertModal.isActionPrompt && (
                  <>
                    {
                      <Row>
                        <Col>
                          <button
                            className={classes.alertButton}
                            onClick={() => dispatch(setAlertModal({ ...alertModal, show: false }))}
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col>
                          <button className={classes.alertButton} onClick={alertModal.action}>
                            {alertModal.actionMessage}
                          </button>
                        </Col>
                      </Row>
                    }
                  </>
                )}
              </>
            }
            onDismiss={() => dispatch(setAlertModal({ ...alertModal, show: false }))}
          />

          {alertModal.isMilestone && <>{emojiBubbleMarkup}</>}
        </>
      )}
      <AvatarProvider provider={chainId === 1 ? (library) : undefined} batchLookups={true}>
        <NavBar />

        <Switch>
          <Route exact path="/" component={PreLaunch} />
          <Route exact path="/dust-pan" component={Playground} />
          <Route exact path="/vault" component={Vault} />
          <Route exact path="/exchange" component={Exchange} />
          <Route exact path="/garage" component={Garage} />
          <Route exact path="/migrate" component={Migrate} />
          <Route exact path="/ideas" component={IdeasPage} />
          <Route exact path="/ideas/create" component={CreateIdeaPage} />
          <Route exact path="/ideas/:id" component={IdeaPage} />
          <Route exact path="/create-proposal" component={CreateProposalPage} />
          <Route exact path="/governance" component={GovernancePage} />
          <Route exact path="/vote" component={GovernancePage} />
          <Route exact path="/vote/:id" component={VotePage} />
          <Route exact path="/vote/sweepersdao/:id" component={SweepersVotePage} />
          <Route exact path="/referral-program" component={Referral} />
          <Route
            exact
            path="/sweeper/:id"
            render={props => <AuctionPage initialAuctionId={Number(props.match.params.id)} />}
          />
          <Route component={NotFoundPage} />
        </Switch>
        {/* <Switch>
            <Route exact path="/" component={AuctionPage} />
            <Route exact path="/lilfounders" component={FoundersPage} />
            <Route exact path="/sweeperers" component={SweeperersPage} />
            <Route exact path="/create-proposal" component={CreateProposalPage} />
            <Route exact path="/vote" component={GovernancePage} />
            <Route exact path="/ideas" component={IdeasPage} />
            <Route exact path="/ideas/create" component={CreateIdeaPage} />
            <Route exact path="/ideas/:id" component={IdeaPage} />
            <Route exact path="/vote/:id" component={VotePage} />
            <Route exact path="/vote/sweepersdao/:id" component={SweepersVotePage} />
            <Route exact path="/playground" component={Playground} />
            <Route exact path="/sweeperverse/:id" component={Sweeperverse} />
            <Route exact path="/sweeperverse" component={Sweeperverse} />
            <Route component={NotFoundPage} />
          </Switch> */}

        <Footer />
      </AvatarProvider>
    </div>
  </AppContext.Provider>
  );
}

export default withRouter(App);

// function timeout(delay: number) {
//   return new Promise(res => setTimeout(res, delay));
// }
