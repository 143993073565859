import classes from './Sweeper.module.css';
import React, { useMemo } from 'react';
import loadingSweeper from '../../assets/main-sweeper.png';
import loadingBigSweeper from '../../assets/loading-skull-sweeper.gif';
import Image from 'react-bootstrap/Image';
import SweeperTraitsOverlay from '../SweeperTraitsOverlay';

export const LoadingSweeper = () => {
  return (
    <div className={classes.imgWrapper}>
      <Image style={{ maxHeight: '100%' }} className={classes.img} src={loadingSweeper} alt={'loading sweeper'} fluid />
    </div>
  );
};

const Sweeper: React.FC<{
  imgPath: string;
  isBigSweeper?: boolean;
  alt: string;
  className?: string;
  wrapperClassName?: string;
  parts?: { filename: string }[];
  showBanner?: boolean;
}> = props => {
  const { imgPath, alt, className, wrapperClassName, parts, isBigSweeper, showBanner = false } = props;
  const sweeperImage = useMemo(() => <Image
    className={`${classes.img} ${className}`}
    src={imgPath ? imgPath : isBigSweeper ? loadingBigSweeper : loadingSweeper}
    alt={alt}
    fluid
  />, [imgPath, isBigSweeper, alt, className]);
  if (!showBanner) return <div className={`${classes.imgWrapper} ${wrapperClassName}`}>
    {sweeperImage}
  </div>
  return (
    <div className={`${classes.imgWrapper} ${wrapperClassName}`} data-tip data-for="sweeper-traits">
      {sweeperImage}
      {Boolean(parts?.length) && showBanner && <SweeperTraitsOverlay parts={parts!} />}
    </div>
  );
};

export default Sweeper;
