"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractAddressesForChainOrThrow = void 0;
const types_1 = require("./types");
const chainIdToAddresses = {
    [types_1.ChainId.Mainnet]: {
        sweepersToken: '0x2276C60F53c9a807e182d112f9b37D7277463Fec',
        sweepersSeeder: '0xfc64f3f2793f2a5c1833Ca50A75c36a9583C95e5',
        sweepersDescriptor: '0x16C3b8ce1a7d7E2C26DD2CCCf29e8Fc54513F020',
        nftDescriptor: '0x180D352D60461d85107b1aE781a0Da980540C36b',
        sweepersAuctionHouse: '0xbFe566Bc042Ee94055887B68eF2Cdfc6ED03fc4e',
        sweepersAuctionHouseProxy: '0x07E409EC7A809a660A9dEd0d40d5b73BBE464B54',
        sweepersAuctionHouseProxyAdmin: '0xa6c8B73d774c8c76cBf265f11F634144252fC3d9',
        SweepersTreasuryExecutor: '0x811074e281d86f862dC84e7df80Ed38aA22aa23D',
        SweepersTreasuryProxy: '0x56Fd5d507eEf6b714F4Cd4E922FE26Bbf32fe277',
        SweepersTreasuryLogicV1: '0xF7b413947988D861d0682780e2CbD8019769d5a7',
    },
    [types_1.ChainId.Goerli]: {
        sweepersToken: '0xaDcE023d1858c089e940e5CDd29c8F6cf50328Ff',
        sweepersSeeder: '0xfc64f3f2793f2a5c1833Ca50A75c36a9583C95e5',
        sweepersDescriptor: '0xb6dCCa052f0971b07830FAec411906FC2Faea7e2',
        nftDescriptor: '0xb57AFF830fad5A85A503C16F75A05f5Ee675AB88',
        sweepersAuctionHouse: '0x180D352D60461d85107b1aE781a0Da980540C36b',
        sweepersAuctionHouseProxy: '0xF5f9b80F3b2F8a8784950BF680a2Ac97152FdA96',
        sweepersAuctionHouseProxyAdmin: '0x63a194CD1963f42862A16471e033fd421FF29Ac4',
        SweepersTreasuryExecutor: '0xC3CA6162DdA76A17C17aC2b6803FB8657DDbC140',
        SweepersTreasuryProxy: '0x2F2Bd76df82f73e1C8E40509885DF0ae9643E532',
        SweepersTreasuryLogicV1: '0x5E7F2cD71100b3f5e710699c14bA345438c9B323',
    },
    [types_1.ChainId.Local]: {
        sweepersToken: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
        sweepersSeeder: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
        sweepersDescriptor: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
        nftDescriptor: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
        sweepersAuctionHouse: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',
        sweepersAuctionHouseProxy: '0xa513E6E4b8f2a923D98304ec87F64353C4D5C853',
        sweepersAuctionHouseProxyAdmin: '0x0165878A594ca255338adfa4d48449f69242Eb8F',
        SweepersTreasuryExecutor: '0xC3CA6162DdA76A17C17aC2b6803FB8657DDbC140',
        SweepersTreasuryProxy: '0x2F2Bd76df82f73e1C8E40509885DF0ae9643E532',
        SweepersTreasuryLogicV1: '0x5E7F2cD71100b3f5e710699c14bA345438c9B323',
    },
};
/**
 * Get addresses of contracts that have been deployed to the
 * Ethereum mainnet or a supported testnet. Throws if there are
 * no known contracts deployed on the corresponding chain.
 * @param chainId The desired chainId
 */
const getContractAddressesForChainOrThrow = (chainId) => {
    if (!chainIdToAddresses[chainId]) {
        throw new Error(`Unknown chain id (${chainId}). No known contracts have been deployed on this chain.`);
    }
    return chainIdToAddresses[chainId];
};
exports.getContractAddressesForChainOrThrow = getContractAddressesForChainOrThrow;
