import React, { useState, useEffect, useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { Col } from 'react-bootstrap';
import { StandaloneSweeperWithSeed } from '../StandaloneSweeper';
import AuctionActivity from '../AuctionActivity';
import { Row, Container } from 'react-bootstrap';
import { setStateBackgroundColor } from '../../state/slices/application';
import { LoadingSweeper } from '../Sweeper';
import { Auction as IAuction } from '../../wrappers/sweepersAuction';
import classes from './Auction.module.css';
import { ISweeperSeed } from '../../wrappers/sweeperToken';
import FounderSweeperContent from '../FounderSweeperContent';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { isFounderSweeper, isSweepersTreasurySweeper } from '../../utils/founderSweeper';
import {
  setNextOnDisplayAuctionSweeperId,
  setPrevOnDisplayAuctionSweeperId,
} from '../../state/slices/onDisplayAuction';
import { beige, grey } from '../../utils/sweeperBgColors';
import config from '../../config';
import { BigNumber } from 'ethers';
import './auction.css'
import { useAppContext } from '../../App';

/* interface AuctionProps {
  auction?: IAuction;
}
 */
const Auction = props => {
  /* tslint:disable */
  const { redirect } = useAppContext()
  const { auction: currentAuction = null } = props;
  // console.log(`CURRENT AUCTION: ${JSON.stringify(currentAuction)}`);
  const isPreLaunch = config.isPreLaunch === 'false';
  const history = useHistory();
  const dispatch = useAppDispatch();
  const stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  const lastSweeperId = useAppSelector(state => state.onDisplayAuction.lastAuctionSweeperId);

  const loadedSweeperHandler = (seed) => {
    dispatch(setStateBackgroundColor(seed.background === 0 ? grey : beige));
  };

  const prevAuctionHandler = () => {
    redirect(`/sweeper/${(currentAuction?.sweeperId?.toNumber() ?? 0) - 1}`);
    dispatch(setPrevOnDisplayAuctionSweeperId());
  };
  const nextAuctionHandler = () => {
    redirect(`/sweeper/${(currentAuction?.sweeperId?.toNumber() ?? 0) + 1}`)
    dispatch(setNextOnDisplayAuctionSweeperId());
  };

  const TimeSpan = React.memo(({ endTime: time }) => {
    const [remaining, setRemaining] = useState((time || new Date().getTime()) - new Date().getTime())
    useLayoutEffect(() => {
      setRemaining((time || new Date().getTime()) - new Date().getTime())
      const interval = setInterval(() => {
        setRemaining(t => t - 1000)
      }, 1000)
      return () => clearInterval(interval)
    }, [time])
    let days = Math.floor(remaining / (1000 * 60 * 60 * 24))
    let hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((remaining % (1000 * 60)) / 1000)
    return <span>
      {days > 0 || minutes > 0 || hours > 0 || seconds > 0 ? <>
        {days > 0 && `${days}D`}{hours > 0 && `${days ? ' ' : ''}${hours}H`}{minutes > 0 && `${hours || days ? ' ' : ''}${minutes}M`}{seconds > 0 && `${minutes || hours || days ? ' ' : ''}${seconds}S`}
      </> : <>Ended</>}
    </span>
  });

  const sweeperContent = currentAuction && (
    <div className={classes.sweeperWrapper}>
      <StandaloneSweeperWithSeed
        shouldShowBanner={true}
        sweeperId={currentAuction?.sweeperId || BigNumber.from(0)}
        onLoadSeed={loadedSweeperHandler}
        shouldLinkToProfile={false}
      />
    </div>
  );

  const loadingSweeper = (
    <div className={classes.sweeperWrapper}>
      <LoadingSweeper />
    </div>
  );

  const currentAuctionActivityContent = currentAuction && lastSweeperId && (
    <AuctionActivity
      auction={currentAuction}
      lastAuctionId={lastSweeperId}
      isFirstAuction={currentAuction?.sweeperId?.eq(0) || false}
      isLastAuction={currentAuction?.sweeperId?.eq(lastSweeperId) || false}
      onPrevAuctionClick={prevAuctionHandler}
      onNextAuctionClick={nextAuctionHandler}
      displayGraphDepComps={true}
    />
  );

  const preAuctionLaunch = !currentAuction && (
    <div className='launchTimer'>
      <h2>LOADING AUCTION...</h2>
    </div>
  );
  //TODO:: come back to this

  // if (isPreLaunch) return (
  //   <div style={{ backgroundColor: '#FCF4EB' }} className={classes.wrapper}>
  //     <Container fluid="xl">
  //       <Row>
  //         <Col lg={{ span: 6 }} className={classes.sweeperContentCol} style={{ paddingTop: '1.5rem', position: 'relative' }}>
  //           <span id="launch-date">Launching September 28th</span>
  //           {currentAuction ? sweeperContent : loadingSweeper}
  //         </Col>
  //         <Col lg={{ span: 6 }} className={'b1 jc '}>
  //           <AuctionActivity
  //             auction={{
  //               sweeperId: BigNumber.from(0),
  //               startTime: BigNumber.from(Math.round((Date.now() - (1000 * 60 * 9 + 60000 + (1000 * 60 * 5))) / 1000)),
  //               endTime: BigNumber.from(Math.floor((Date.now() - (1000 * 60 * 5)) / 1000)),
  //               bidder: '0x0000000000000000000000000000000000000000',
  //               amount: BigNumber.from('30000000000000000'),
  //               settled: true
  //             }}
  //             isFirstAuction={true}
  //             isLastAuction={true}
  //             onPrevAuctionClick={prevAuctionHandler}
  //             onNextAuctionClick={nextAuctionHandler}
  //             displayGraphDepComps={true}
  //           />
  //           {/*             <FounderSweeperContent
  //             mintTimestamp={BigNumber.from(Math.floor(new Date().getTime()/1000))}
  //             sweeperId={BigNumber.from(0)}
  //             isFirstAuction={true}
  //             isLastAuction={false}
  //             onPrevAuctionClick={prevAuctionHandler}
  //             onNextAuctionClick={nextAuctionHandler}
  //           /> */}
  //         </Col>
  //       </Row>
  //     </Container>
  //   </div>
  // );
  return (
    <div className="column w100" style={{ background: '#FCF4EB' }}>
      <div id="auction-house">
        <div className="column pad w100">
          {currentAuction ? sweeperContent : loadingSweeper}
        </div>
        <div className="column pad w100">
          {currentAuction ? currentAuctionActivityContent : preAuctionLaunch}
          {/* (isFounderSweeper(currentAuction?.sweeperId || BigNumber.from(0)) || isSweepersTreasurySweeper(currentAuction?.sweeperId || BigNumber.from(0))
                ? founderSweeperContent
                : currentAuctionActivityContent)} */}
        </div>
      </div>
    </div>
  );
};

export default Auction;
