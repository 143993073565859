import { getGrayBackgroundSVG } from '../../utils/grayBackgroundSVG';
import sweeperClasses from '../Sweeper/Sweeper.module.css';
import Sweeper from '../Sweeper';

export const GrayCircle = () => {
  return (
    <Sweeper
      showBanner={true}
      imgPath={getGrayBackgroundSVG()}
      alt={''}
      wrapperClassName={sweeperClasses.circularSweeperWrapper}
      className={sweeperClasses.circular}
    />
  );
};