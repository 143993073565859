import React from 'react';
import ReactDOM from 'react-dom';
import '../src/css/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import { ChainId, DAppProvider } from '@usedapp/core';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import account from './state/slices/account';
import application from './state/slices/application';
import logs from './state/slices/logs';
import auction, {
  reduxSafeAuction,
  reduxSafeNewAuction,
  reduxSafeBid,
  setActiveAuction,
  setAuctionExtended,
  setAuctionSettled,
  setFullAuction,
} from './state/slices/auction';
import onDisplayAuction, {
  setLastAuctionSweeperId,
  setLastAuctionStartTime,
  setOnDisplayAuctionSweeperId,
  setOnDisplayAuctionStartTime,
} from './state/slices/onDisplayAuction';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, Operation, useQuery } from '@apollo/client';
import { clientFactory, latestAuctionsQuery, singularAuctionQuery } from './wrappers/subgraph';
import { useEffect } from 'react';
import pastAuctions, { addPastAuctions } from './state/slices/pastAuctions';
import LogsUpdater from './state/updaters/logs';
import config, { CHAIN_ID, createNetworkHttpUrl } from './config';
import { WebSocketProvider } from '@ethersproject/providers';
import { BigNumber, BigNumberish, providers } from 'ethers';
import { SweepersAuctionHouseFactory } from '@nouns/sdk';
import dotenv from 'dotenv';
import { useAppDispatch, useAppSelector } from './hooks';
import { appendBid } from './state/slices/auction';
import { ConnectedRouter, connectRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { applyMiddleware, createStore, combineReducers, PreloadedState } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { sweeperPath } from './utils/history';
import { push } from 'connected-react-router';
import { createClient, WagmiConfig } from 'wagmi';
import { AuthProvider } from './hooks/useAuth';
import { ErrorModalProvider } from './hooks/useApiError';

import { Provider as RollbarProvider } from '@rollbar/react';
import { isFounderSweeper } from './utils/founderSweeper';

dotenv.config();

export const history = createBrowserHistory();

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    account,
    application,
    auction,
    logs,
    pastAuctions,
    onDisplayAuction,
  });

export default function configureStore(preloadedState: PreloadedState<any>) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
      ),
    ),
  );

  return store;
}

const store = configureStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// prettier-ignore
const useDappConfig = {
  readOnlyChainId: CHAIN_ID,
  readOnlyUrls: {
    [ChainId.Goerli]: createNetworkHttpUrl('goerli'),
    [ChainId.Mainnet]: createNetworkHttpUrl('mainnet'),
    [ChainId.Hardhat]: 'http://localhost:8545',
  },
};

const alchemyId = 'tEAmLPls4-IajaZM2nyTIfG6CqK_uAb0';

const wagmiClient = createClient({
  provider(config) {
    return new providers.AlchemyProvider(config.chainId, alchemyId);
  },
});




const defaultLink = new HttpLink({
  uri: config.app.subgraphApiUri
})

const SweepersTreasuryLink = new HttpLink({
  uri: config.app.SweepersTreasurySubgraphApiUri
})

const SweepersTreasuryVotingSnapshotLink = new HttpLink({
  uri: 'https://hub.snapshot.org/graphql'
})

const zoraAPILink = new HttpLink({
  uri: 'https://api.zora.co/graphql'
})


//pass them to apollo-client config
const client = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === 'SweepersTreasury',
    SweepersTreasuryLink, //if above 
    ApolloLink.split(operation => operation.getContext().clientName === 'SweepersTreasurySnapshot',
      SweepersTreasuryVotingSnapshotLink,
      ApolloLink.split(operation => operation.getContext().clientName === 'ZoraAPI',
        zoraAPILink,
        defaultLink))
  ),
  cache: new InMemoryCache(),
})


const Updaters = () => {
  return (
    <>
      <LogsUpdater />
    </>
  );
};

const BLOCKS_PER_DAY = 6_500;

const ChainSubscriber: React.FC = () => {
  const dispatch = useAppDispatch();

  const loadState = async () => {
    const wsProvider = new WebSocketProvider(config.app.wsRpcUri);
    const sweepersAuctionHouseContract = SweepersAuctionHouseFactory.connect(
      config.addresses.sweepersAuctionHouseProxy,
      wsProvider,
    );

    const bidFilter = sweepersAuctionHouseContract.filters.AuctionBid(null, null, null, null);
    const extendedFilter = sweepersAuctionHouseContract.filters.AuctionExtended(null, null);
    const createdFilter = sweepersAuctionHouseContract.filters.AuctionCreated(null, null, null);
    const settledFilter = sweepersAuctionHouseContract.filters.AuctionSettled(null, null, null, null);
    const processBidFilter = async (
      sweeperId: BigNumberish,
      sender: string,
      value: BigNumberish,
      extended: boolean,
      event: any,
    ) => {
      const timestamp = (await event.getBlock()).timestamp;
      const transactionHash = event.transactionHash;
      dispatch(
        appendBid(reduxSafeBid({ sweeperId, sender, value, extended, transactionHash, timestamp })),
      );
    };
    const processAuctionCreated = (
      sweeperId: BigNumberish,
      startTime: BigNumberish,
      endTime: BigNumberish,
    ) => {
      dispatch(
        setActiveAuction(reduxSafeNewAuction({ sweeperId, startTime, endTime, settled: false })),
      );
      const sweeperIdNumber = BigNumber.from(sweeperId).toNumber();
      const startTimeNumber = BigNumber.from(startTime).toNumber();
      const {pathname} = window.location
      if (pathname === '/' || /\/sweeper/i.test(pathname)) dispatch(push(sweeperPath(sweeperIdNumber)));
      dispatch(setOnDisplayAuctionSweeperId(sweeperIdNumber));
      dispatch(setOnDisplayAuctionStartTime(startTimeNumber));
      dispatch(setLastAuctionSweeperId(sweeperIdNumber));
      dispatch(setLastAuctionStartTime(startTimeNumber));
    };
    const processAuctionExtended = (sweeperId: BigNumberish, endTime: BigNumberish) => {
      dispatch(setAuctionExtended({ sweeperId, endTime }));
    };
    const processAuctionSettled = (sweeperId: BigNumberish, winner: string, amount: BigNumberish) => {
      dispatch(setAuctionSettled({ sweeperId, amount, winner }));
    };

    // Fetch the current auction
    const currentAuction = await sweepersAuctionHouseContract.auction();
    dispatch(setFullAuction(reduxSafeAuction({...currentAuction, sniped: false, snipedBy: null, snipedAmount: BigNumber.from(0)})));
    dispatch(setLastAuctionSweeperId(currentAuction?.sweeperId?.toNumber() ?? 0));

    dispatch(setLastAuctionStartTime(currentAuction.startTime.toNumber()));

    // Fetch the previous 24hours of  bids
    const previousBids = await sweepersAuctionHouseContract.queryFilter(bidFilter, 0 - BLOCKS_PER_DAY);
    for (const event of previousBids) {
      if (event.args === undefined) return;
      processBidFilter(...(event.args as [BigNumber, string, BigNumber, boolean]), event);
    }

    sweepersAuctionHouseContract.on(bidFilter, (sweeperId: any, sender: any, value: any, extended: any, event: any) =>
      processBidFilter(sweeperId, sender, value, extended, event),
    );
    sweepersAuctionHouseContract.on(createdFilter, (sweeperId: any, startTime: any, endTime: any) =>
      processAuctionCreated(sweeperId, startTime, endTime),
    );
    sweepersAuctionHouseContract.on(extendedFilter, (sweeperId: any, endTime: any) =>
      processAuctionExtended(sweeperId, endTime),
    );
    sweepersAuctionHouseContract.on(settledFilter, (sweeperId: any, winner: any, amount: any) =>
      processAuctionSettled(sweeperId, winner, amount),
    );
  };
  loadState();

  return <></>;
};

//UPDATE: Using Auction start timestmap to fetch backwards beyond last 1000 aucitons
const PastAuctions: React.FC = () => {
  const latestAuctionId = useAppSelector(state => state.onDisplayAuction.lastAuctionSweeperId);
  const latestAuctionStartTime = useAppSelector(
    state => state.onDisplayAuction.lastAuctionStartTime,
  );
  const onDisplayAuctionSweeperId = useAppSelector(
    state => state.onDisplayAuction.onDisplayAuctionSweeperId,
  );
  const onDisplayAuctionStartTime = useAppSelector(
    state => state.onDisplayAuction.onDisplayAuctionStartTime,
  );

  const sweeperId = BigNumber.from(onDisplayAuctionSweeperId ?? 0);
  const distanceToAuctionAbove = isFounderSweeper(BigNumber.from(onDisplayAuctionSweeperId ?? 0)) ? 2 : 1;
  const nextSweeperId = sweeperId.add(distanceToAuctionAbove)

  const { data: postData } = useQuery(
    singularAuctionQuery(nextSweeperId?.toString() || '0'),
  );

  const { data } = useQuery(latestAuctionsQuery(postData?.auctions?.[0]?.startTime || onDisplayAuctionStartTime || 0));
  const { data: auctionData } = useQuery(
    singularAuctionQuery(onDisplayAuctionSweeperId?.toString() || '0'),
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    data &&
      auctionData &&
      dispatch(setOnDisplayAuctionStartTime(auctionData?.auctions?.[0]?.startTime)) &&
      dispatch(addPastAuctions({ data }));
  }, [data, auctionData, latestAuctionId, latestAuctionStartTime, dispatch]);

  return <></>;
};


ReactDOM.render(
  <WagmiConfig client={wagmiClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ChainSubscriber />
        <React.StrictMode>
          <Web3ReactProvider
            getLibrary={
              provider => new Web3Provider(provider) // this will vary according to whether you use e.g. ethers or web3.js
            }
          >
            <ApolloProvider client={client}>
              <PastAuctions />
              <DAppProvider config={useDappConfig}>
                <ErrorModalProvider>
                  <AuthProvider>
                    <App />
                    <Updaters />
                  </AuthProvider>
                </ErrorModalProvider>
              </DAppProvider>
            </ApolloProvider>
          </Web3ReactProvider>
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>
  </WagmiConfig>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
