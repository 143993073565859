import { useAppSelector } from '../../hooks';
import classes from './NavBar.module.css';
import logo from './logo.svg';
import logoAlternate from './logo.svg';
import { useEtherBalance } from '@usedapp/core';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import testnetSweeper from '../../assets/testnet-sweeper.png';
import config, { CHAIN_ID, treasuryWallet as ownerWallet } from '../../config';
import { utils } from 'ethers';
import { buildEtherscanHoldingsLink } from '../../utils/etherscan';
import { ExternalURL, externalURL } from '../../utils/externalURL';
import useLidoBalance from '../../hooks/useLidoBalance';
import NavBarButton, { NavBarButtonStyle } from '../NavBarButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faUsers, faPlay, faComments, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import NavBarTreasury from '../NavBarTreasury';
import NavWallet from '../NavWallet';
import { useEffect, useState } from 'react';
import { createClient } from 'urql';
import './navbar.css'
import Icon from '../../icon'
import SVGs from '../../svgs'
import { useAppContext } from '../../App';

const { bolt, reverse, panels, sun, people, book, smile } = SVGs

const NavBar = () => {
  const { pathname, width } = useAppContext()
  const activeAccount = useAppSelector(state => state.account.activeAccount);
  const stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  const isCool = useAppSelector(state => state.application.isCoolBackground);
  const history = useHistory();
  const ethBalance = useEtherBalance('0x811074e281d86f862dC84e7df80Ed38aA22aa23D');
  /*   const lidoBalanceAsETH = useLidoBalance(); */
  const treasuryBalance = ethBalance;
  const daoEtherscanLink = buildEtherscanHoldingsLink(config.addresses.SweepersTreasuryExecutor);
  const [showNavButtons, setShowNavButtons] = useState(window.innerWidth > 960);
  const isPreLaunch = config.isPreLaunch === 'true';
  const useStateBg = isPreLaunch
    ? history.location.pathname.includes('/sweeper/') ||
    history.location.pathname.includes('/auction/')
    : history.location.pathname === '/' ||
    history.location.pathname.includes('/sweeper/') ||
    history.location.pathname.includes('/auction/');

  const navLogo = useStateBg ? logo : logoAlternate;

  const nonWalletButtonStyle = !useStateBg
    ? NavBarButtonStyle.WHITE_INFO
    : isCool
      ? NavBarButtonStyle.COOL_INFO
      : NavBarButtonStyle.WARM_INFO;

  const [bigSweeperBalance, setBigSweeperBalance] = useState('...');

  const fetchSweepersQuery = `
    query {
        accounts(where: {id: "${ownerWallet}" }) {
        id
        tokenBalance
        sweepers {
          id
        }
      }
    }
      `;

  async function fetchData() {
    const repsonse = await createClient({ url: config.app.SweepersTreasurySubgraphApiUri })
      .query(fetchSweepersQuery)
      .toPromise();
    return repsonse.data.accounts[0];
  }

  useEffect(() => {
    fetchData()
      .then(async repsonse => {
        const tokenBalance = repsonse.tokenBalance;
        const sweeperIds = repsonse.sweepers.flatMap((obj) => obj.id);

        setBigSweeperBalance(tokenBalance);
        return;
      })
      .catch(error => {
        console.log(`Sweepers Owned Error ${error}`);
        return;
      });
  }, []);
  useEffect(() => {
    if (width && width < 960 && showNavButtons) { setShowNavButtons(false) } else if (width && width > 960 && !showNavButtons) { setShowNavButtons(true) }
  }, [pathname, width])
  return (
    <>
      <div
        style={{ backgroundColor: (window.location.pathname === '/' || /sweeper/.test(window.location.pathname)) ? '#FCF4EB' : `#FFFFFF` }}
        className={"main-nav-bar"}
      >
        <div className="main-nav">


          <div className={classes.brandAndTreasuryWrapper}>
            <Navbar.Brand as={Link} to="/" className={"logo-container"}>
              <img src={navLogo} className={"logo"} alt="Sweepers logo" />
              {Number(CHAIN_ID) !== 1 && (
                <Nav.Item>
                  TESTNET
                </Nav.Item>
              )}
            </Navbar.Brand>
            <>
              <Nav.Item>
                {(
                  <Nav.Link
                    href={'https://etherscan.io/address/0x811074e281d86f862dC84e7df80Ed38aA22aa23D'}
                    className={classes.sweepersNavLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <NavBarTreasury
                      treasuryBalance={Number(utils.formatEther(treasuryBalance || '0')).toFixed(2)}
                      treasuryStyle={nonWalletButtonStyle}
                      treasuryBigSweeperBalance={bigSweeperBalance}
                    />
                  </Nav.Link>
                )}
              </Nav.Item>
            </>
          </div>
          {showNavButtons && <div className="nav-bar-buttons" style={{ alignContent: 'center', justifyContent: 'flex-end' }}>
            <Link to="/garage">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/garage' ? 'active' : ''}`}
                buttonText={'Garage'}
                buttonIcon={<Icon svg={bolt} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to="/migrate">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/migrate' ? 'active' : ''}`}
                buttonText={'Migrate'}
                buttonIcon={<Icon svg={bolt} />}
                buttonStyle={nonWalletButtonStyle} 
              />
            </Link>
            <Link to="/exchange">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/exchange' ? 'active' : ''}`}
                buttonText={'Exchange'}
                buttonIcon={<Icon svg={reverse} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to="/vault">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/vault' ? 'active' : ''}`}
                buttonText={'Vault'}
                buttonIcon={<Icon svg={panels} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to="/ideas">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/ideas' ? 'active' : ''}`}
                buttonText={'Ideas'}
                buttonIcon={<Icon svg={sun} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to="/governance">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/governance' ? 'active' : ''}`}
                buttonText={'Governance'}
                buttonIcon={<Icon svg={people} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to="/dust-pan">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/dust-pan' ? 'active' : ''}`}
                buttonText={'Dust Pan'}
                buttonIcon={<Icon svg={smile} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to="/referral-program">
              <NavBarButton
                className={`nav-button connect-wallet-button ${pathname === '/referral-program' ? 'active' : ''}`}
                buttonText={'Referrals'}
                buttonIcon={<Icon svg={book} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
{/*             <Nav.Link href={'http://docs.sweepersclub.com/'}
              className={`${classes.sweepersNavLink} `}
              target="_blank"
              rel="noreferrer">
              <NavBarButton
                className="nav-button connect-wallet-button"
                buttonText={'Docs'}
                buttonIcon={<Icon svg={book} />}
                buttonStyle={nonWalletButtonStyle}
              />
            </Nav.Link> */}

            <NavWallet address={activeAccount || '0'} buttonStyle={nonWalletButtonStyle} />{' '}
          </div>}
          <div onClick={() => setShowNavButtons(a => !a)} id="toggle-mobile-nav">☰</div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
