import * as ethers from 'ethers'
import {BigNumber, Contract} from 'ethers'
import { useEffect, useMemo, useState } from "react";
import config, { CHAIN_ID, createNetworkHttpUrl } from "../config";
import settlerABI from './settler-abi.json'

export default function useSettlementContract() {
  const contract = useMemo(() => new Contract(process.env.REACT_APP_SETTLER_CONTRACT || '', settlerABI, new ethers.providers.StaticJsonRpcProvider(createNetworkHttpUrl(CHAIN_ID === 5 ? 'goerli' : 'mainnet'))), [])
  const [isAutoSettling, setIsAutoSettling] = useState(false)
  const [maxGWEI, setMaxGWEI] = useState(BigNumber.from(0))
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (contract && !loading) {
      setLoading(true)
      contract.automating().then((res: boolean) => {
        setIsAutoSettling(res)
        return contract.maxGWEI()
      }).then((max: BigNumber) => {
        setMaxGWEI(max)
      }).catch((e: Error) => {
        console.log(e)
      }).finally(() => setLoading(false))
    }
  }, [contract])
  return {isAutoSettling, maxGWEI}
}