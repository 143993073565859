import { BigNumber } from 'ethers';
import classes from './AuctionActivitySweeperTitle.module.css';

const AuctionActivitySweeperTitle: React.FC<{ sweeperId: BigNumber; isCool?: boolean }> = props => {
  const { sweeperId, isCool } = props;
  const sweeperIdContent = `Sweeper ${sweeperId.toString()}`;
  return (
    <div className={classes.wrapper}>
      <h1 style={{ fontWeight: '900', color: isCool ? 'var(--brand-cool-dark-text)' : 'var(--brand-warm-dark-text)' }}>
        {sweeperIdContent}
      </h1>
    </div>
  );
};
export default AuctionActivitySweeperTitle;
