"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSweeperSeedFromBlockHash = exports.getPseudorandomBackground = exports.getPseudorandomPart = exports.shiftRightAndCast = exports.getRandomSweeperSeed = exports.getBigSweeperData = exports.getSweeperData = void 0;
const solidity_1 = require("@ethersproject/solidity");
const bignumber_1 = require("@ethersproject/bignumber");
const image_data_json_1 = require("./image-data.json");
const big_noun_image_data_json_1 = require("./big-noun-image-data.json");
const { background, body, bristles, accessory, eyes, mouth } = image_data_json_1.images;
/**
 * Get encoded part and background information using a Sweeper seed
 * @param seed The Sweeper seed
 */
const getSweeperData = (seed) => {
    return {
        parts: [
            background[seed.background],
            body[seed.body],
            bristles[seed.head],
            accessory[seed.accessory],
            eyes[seed.eyes],
            mouth[seed.mouth],
        ],
        background: image_data_json_1.bgcolors[seed.background],
    };
};
exports.getSweeperData = getSweeperData;
/**
 * Data fetch for Sweepers propper
 * Get encoded part and background information using a Sweeper seed
 * @param seed The Sweeper seed
 */
const getBigSweeperData = (seed) => {
    return {
        parts: [
            big_noun_image_data_json_1.images.bodies[seed.body],
            big_noun_image_data_json_1.images.accessories[seed.accessory],
            big_noun_image_data_json_1.images.heads[seed.head],
            big_noun_image_data_json_1.images.glasses[seed.eyes],
        ],
        background: big_noun_image_data_json_1.bgcolors[seed.background],
    };
};
exports.getBigSweeperData = getBigSweeperData;
/**
 * Generate a random Sweeper seed
 * @param seed The Sweeper seed
 */
const getRandomSweeperSeed = () => {
    return {
        background: Math.floor(Math.random() * background.length),
        body: Math.floor(Math.random() * body.length),
        head: Math.floor(Math.random() * bristles.length),
        accessory: Math.floor(Math.random() * accessory.length),
        eyes: Math.floor(Math.random() * eyes.length),
        mouth: Math.floor(Math.random() * mouth.length),
    };
};
exports.getRandomSweeperSeed = getRandomSweeperSeed;
/**
 * Emulate bitwise right shift and uint cast
 * @param value A Big Number
 * @param shiftAmount The amount to right shift
 * @param uintSize The uint bit size to cast to
 */
const shiftRightAndCast = (value, shiftAmount, uintSize) => {
    const shifted = bignumber_1.BigNumber.from(value).shr(shiftAmount).toHexString();
    return `0x${shifted.substring(shifted.length - uintSize / 4)}`;
};
exports.shiftRightAndCast = shiftRightAndCast;
/**
 * Emulates the SweepersSeeder.sol methodology for pseudorandomly selecting a part
 * @param pseudorandomness Hex representation of a number
 * @param partCount The number of parts to pseudorandomly choose from
 * @param shiftAmount The amount to right shift
 * @param uintSize The size of the unsigned integer
 */
const getPseudorandomPart = (pseudorandomness, partCount, shiftAmount, uintSize = 48) => {
    const hex = (0, exports.shiftRightAndCast)(pseudorandomness, shiftAmount, uintSize);
    return bignumber_1.BigNumber.from(hex).mod(partCount).toNumber();
};
exports.getPseudorandomPart = getPseudorandomPart;
const getPseudorandomBackground = (pseudorandomness, partCount, shiftAmount, uintSize = 48) => {
    const hex = (0, exports.shiftRightAndCast)(pseudorandomness, shiftAmount, uintSize);
    const seed = bignumber_1.BigNumber.from(hex).mod(partCount).toNumber();
    if (seed == 0) {
        return 3;
    }
    else if (seed <= 2) {
        return 11;
    }
    else if (seed <= 6) {
        return 4;
    }
    else if (seed <= 18) {
        return 0;
    }
    else if (seed <= 56) {
        return 2;
    }
    else if (seed <= 94) {
        return 5;
    }
    else if (seed <= 132) {
        return 6;
    }
    else if (seed <= 170) {
        return 7;
    }
    else if (seed <= 208) {
        return 8;
    }
    else if (seed <= 246) {
        return 9;
    }
    else if (seed <= 284) {
        return 10;
    }
    else if (seed <= 322) {
        return 1;
    }
    else if (seed <= 360) {
        return 12;
    }
    else {
        return 13;
    }
};
exports.getPseudorandomBackground = getPseudorandomBackground;
/**
 * Emulates the SweepersSeeder.sol methodology for generating a Sweeper seed
 * @param SweeperId The Sweeper tokenId used to create pseudorandomness
 * @param blockHash The block hash use to create pseudorandomness
 */
const getSweeperSeedFromBlockHash = (sweeperId, blockHash) => {
    const pseudorandomness = (0, solidity_1.keccak256)(['bytes32', 'uint256'], [blockHash, sweeperId]);
    return {
        background: (0, exports.getPseudorandomBackground)(pseudorandomness, 400, 0),
        body: (0, exports.getPseudorandomPart)(pseudorandomness, body.length, 48),
        accessory: (0, exports.getPseudorandomPart)(pseudorandomness, accessory.length, 96),
        head: (0, exports.getPseudorandomPart)(pseudorandomness, bristles.length, 144),
        eyes: (0, exports.getPseudorandomPart)(pseudorandomness, eyes.length, 192),
        mouth: (0, exports.getPseudorandomPart)(pseudorandomness, mouth.length, 208),
    };
};
exports.getSweeperSeedFromBlockHash = getSweeperSeedFromBlockHash;
