import classes from './NavBarTreasury.module.css';
import { NavBarButtonStyle } from '../NavBarButton';
import clsx from 'clsx';

interface NavBarTreasuryProps {
  treasuryBalance: string;
  treasuryStyle: NavBarButtonStyle;
  treasuryBigSweeperBalance: string;
}

const NavBarTreasury: React.FC<NavBarTreasuryProps> = props => {
  const { treasuryBalance, treasuryStyle, treasuryBigSweeperBalance } = props;

  let treasuryStyleClass;
  switch (treasuryStyle) {
    case NavBarButtonStyle.WARM_INFO:
      treasuryStyleClass = classes.warmInfo;
      break;
    case NavBarButtonStyle.COOL_INFO:
      treasuryStyleClass = classes.coolInfo;
      break;
    case NavBarButtonStyle.WHITE_INFO:
    default:
      treasuryStyleClass = classes.whiteInfo;
      break;
  }


  return (
    <div className={`${classes.wrapper} ${treasuryStyleClass}`} style={{height: 'auto', padding: '.5rem'}}>
      <div className={`${classes.button} treasury-group`}>
        <span>Treasury </span>
        {/* <span>0</span> */}
         <span>{Number(treasuryBalance).toLocaleString('en-US') } ETH</span> 
      </div>
    </div>
  );
};

export default NavBarTreasury;
