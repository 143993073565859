import {
  ContractAddresses as SweepersContractAddresses,
  getContractAddressesForChainOrThrow,
} from '@nouns/sdk';
import { ChainId } from '@usedapp/core';

interface ExternalContractAddresses {
  lidoToken: string | undefined;
  dustToken: string | undefined;
}
export const treasuryWallet = '0x0000000000000000000000000000000000000000';
export type ContractAddresses = SweepersContractAddresses & ExternalContractAddresses;

interface AppConfig {
  jsonRpcUri: string;
  wsRpcUri: string;
  subgraphApiUri: string;
  SweepersTreasurySubgraphApiUri: string;
  enableHistory: boolean;
  sweepersApiUri: string;
  enableRollbar: boolean;
  zoraKey: string;
}

type SupportedChains = ChainId.Goerli | ChainId.Mainnet | ChainId.Hardhat;

interface CacheBucket {
  name: string;
  version: string;
}

export const cache: Record<string, CacheBucket> = {
  bigNounSeed: {
    name: 'bigNounSeed',
    version: 'v1',
  },
  seed: {
    name: 'seed',
    version: 'v1',
  },
  ens: {
    name: 'ens',
    version: 'v1',
  },
};

export const cacheKey = (bucket: CacheBucket, ...parts: (string | number)[]) => {
  return [bucket.name, bucket.version, ...parts].join('-').toLowerCase();
};

export const CHAIN_ID: SupportedChains = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1');

export const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY ?? '';

const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;

//TODO: replaced infura for prod
export const createNetworkHttpUrl = (network: string): string => {
  const custom = process.env[`REACT_APP_${network.toUpperCase()}_JSONRPC`];

  if (network === 'goerli') {
    return custom ||`https://goerli.infura.io/v3/31e4ba6acf134f78a6b16466313a0587`;
  } else {
    return custom || `https://mainnet.infura.io/v3/31e4ba6acf134f78a6b16466313a0587`;
  }
};

export const createNetworkWsUrl = (network: string): string => {
  const custom = process.env[`REACT_APP_${network.toUpperCase()}_WSRPC`];

  if (network === 'goerli') {
    return custom || `wss://${network}.infura.io/ws/v3/${INFURA_PROJECT_ID}`;
  } else {
    return custom || 'wss://mainnet.infura.io/ws/v3/31e4ba6acf134f78a6b16466313a0587';
  }
};

const app: Record<SupportedChains, AppConfig> = {
  [ChainId.Goerli]: {
    jsonRpcUri: createNetworkHttpUrl('goerli'),
    wsRpcUri: createNetworkWsUrl('goerli'),
    subgraphApiUri:
      'https://api.thegraph.com/subgraphs/name/ideadesignmedia/testsweeper',
    SweepersTreasurySubgraphApiUri:
      'https://api.thegraph.com/subgraphs/name/ideadesignmedia/testsweeper',
    enableHistory: process.env.REACT_APP_ENABLE_HISTORY === 'true',
    sweepersApiUri: process.env[`REACT_APP_GOERLI_SWEEPERSAPI`] || '',
    enableRollbar: process.env.REACT_APP_ENABLE_ROLLBAR === 'true',
    zoraKey: process.env.ZORA_API_KEY || '',
  },
  [ChainId.Mainnet]: {
    jsonRpcUri: createNetworkHttpUrl('mainnet'),
    wsRpcUri: createNetworkWsUrl('mainnet'),
    subgraphApiUri: 'https://api.thegraph.com/subgraphs/name/legacy-lab/sweepers',
    SweepersTreasurySubgraphApiUri: 'https://api.thegraph.com/subgraphs/name/legacy-lab/sweepers',
    enableHistory: process.env.REACT_APP_ENABLE_HISTORY === 'true',
    sweepersApiUri: process.env[`REACT_APP_MAINNET_SWEEPERSAPI`] || '',
    enableRollbar: process.env.REACT_APP_ENABLE_ROLLBAR === 'true',
    zoraKey: process.env.ZORA_API_KEY || '',
  },
  [ChainId.Hardhat]: {
    jsonRpcUri: 'http://localhost:8545',
    wsRpcUri: 'ws://localhost:8545',
    subgraphApiUri: '',
    SweepersTreasurySubgraphApiUri: '',
    enableHistory: false,
    sweepersApiUri: 'http://localhost:5001',
    enableRollbar: false,
    zoraKey: '',
  },
};

const externalAddresses: Record<SupportedChains, ExternalContractAddresses> = {
  [ChainId.Goerli]: {
    lidoToken: '0xF4242f9d78DB7218Ad72Ee3aE14469DBDE8731eD',
    dustToken: '',
  },
  [ChainId.Mainnet]: {
    lidoToken: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
    dustToken: '0x68bABA24ee60933c6E22Ea7701D9771285DDE27a',
  },
  [ChainId.Hardhat]: {
    lidoToken: undefined,
    dustToken: undefined,
  },
};

const getAddresses = (): ContractAddresses => {
  let sweepersAddresses = {} as SweepersContractAddresses;
  try {
    sweepersAddresses = getContractAddressesForChainOrThrow(CHAIN_ID);
  } catch {}
  return { ...sweepersAddresses, ...externalAddresses[CHAIN_ID] };
};

const config = {
  app: app[CHAIN_ID],
  isPreLaunch: process.env.REACT_APP_IS_PRELAUNCH || 'false',
  addresses: getAddresses(),
};

export default config;
