import classes from './ProposalEditor.module.css';
import { InputGroup, FormControl, FormText } from 'react-bootstrap';
import remarkBreaks from 'remark-breaks';
import ReactMarkdown from 'react-markdown';
import {ValidateOpenSeaLinkInput} from '../validlinkinput.jsx';
import { useState, useReducer } from 'react';
import { useTreasuryBalance } from '../../hooks/useTreasuryBalance';
import BigNumber from 'bignumber.js';

const ProposalEditor = ({
  title,
  slug,
  project,
  body,
  sweepAmount,
  onTitleInput,
  onSlugInput,
  onProjectInput,
  onBodyInput,
  onSweepAmountInput,
}: {
  title: string;
  slug: string;
  project: string;
  body: string;
  sweepAmount: string;
  onTitleInput: (title: string) => void;
  onSlugInput: (slug: string) => void;
  onProjectInput: (project: string) => void;
  onBodyInput: (body: string) => void;
  onSweepAmountInput: (sweepAmount: string) => void;
}) => {
  const bodyPlaceholder = `## Summary\n\nInsert your summary here\n\n## Methodology\n\nInsert your methodology here\n\n## Conclusion\n\nInsert your conclusion here`;
  const [proposalText, setProposalText] = useState('');
  const treasuryBalance = useTreasuryBalance();
  const [data , setData] = useReducer((state: any, change: any) => {
    if (change) onProjectInput(change?.collection?.primary_asset_contracts?.[0]?.address);
    return change
  }, null)
  const onBodyChange = (body: string) => {
    setProposalText(body);
    onBodyInput(body);
  };

  return (
    <div>
      <InputGroup className={`${classes.proposalEditor} d-flex flex-column`}>
        <FormText>Proposal</FormText>
        <FormControl
          className={classes.titleInput}
          style={{fontSize: '2rem'}}
          value={title}
          onChange={e => onTitleInput(e.target.value)}
          placeholder="Proposal Title"
        />
        <hr className={classes.divider} />
        <ValidateOpenSeaLinkInput className={classes.titleInput} placeholder={"Opensea Slug (eg: sweepers-club)"} data={data} setData={setData} modifier={'https://api.opensea.io/api/v1/collection/'} value={slug} set={onSlugInput}  />
{/*         <FormControl
          className={classes.titleInput}
          value={slug}
          onChange={e => onSlugInput(e.target.value)}
          placeholder="Opensea Slug"
        /> */}
        <hr className={classes.divider} />
        <FormControl
          className={classes.titleInput}
          value={project}
          onChange={e => onProjectInput(e.target.value)}
          placeholder="Project Contract Address"
        />
        <hr className={classes.divider} />
        {data && <span>Floor Price: {data.collection?.stats.floor_price}</span>}
        <FormControl
          className={classes.titleInput}
          onWheel={event => event.currentTarget.blur()}
          type="number"
          min="0"
          max="100"
          value={sweepAmount}
          onChange={e => {
            const canSweep = new BigNumber(e.target.value);
            if (canSweep.isNaN()) return onSweepAmountInput('');
            const treasury = new BigNumber(treasuryBalance?.toString() || '0');
            if (canSweep.multipliedBy(10**18).isGreaterThan(treasury)) return onSweepAmountInput(treasury.dividedBy(10**18).toFixed(2));
            onSweepAmountInput(e.target.value)
          }}
          placeholder="Amount of ETH for Sweep"
        />
        {sweepAmount && data && <h3>{(() => {
          const canSweep = new BigNumber(sweepAmount);
          const floorPrice = new BigNumber(data.collection?.stats.floor_price);
          const numSweepers = canSweep.dividedBy(floorPrice);
          return numSweepers.toFixed(0);
        })()}</h3>}
        <hr className={classes.divider} />
        <FormControl
          className={classes.bodyInput}
          value={body}
          onChange={e => onBodyChange(e.target.value)}
          as="textarea"
          placeholder={bodyPlaceholder}
        />
      </InputGroup>
      {proposalText !== '' && (
        <div className={classes.previewArea}>
          <h3>Preview:</h3>
          <ReactMarkdown
            className={classes.markdown}
            children={proposalText}
            remarkPlugins={[remarkBreaks]}
          />
        </div>
      )}
    </div>
  );
};
export default ProposalEditor;
