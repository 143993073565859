import Banner from '../../components/Banner';
import Auction from '../../components/Auction';
import Documentation from '../../components/Documentation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setOnDisplayAuctionSweeperId, setOnDisplayAuctionStartTime } from '../../state/slices/onDisplayAuction';
import { push } from 'connected-react-router';
import { sweeperPath } from '../../utils/history';
import useOnDisplayAuction from '../../wrappers/onDisplayAuction';
import { useEffect } from 'react';
import ProfileActivityFeed from '../../components/ProfileActivityFeed';

interface AuctionPageProps {
  initialAuctionId?: number;
}

const AuctionPage: React.FC<AuctionPageProps> = props => {
  const { initialAuctionId } = props;
  const onDisplayAuction = useOnDisplayAuction(initialAuctionId);
  const lastAuctionSweeperId = useAppSelector(state => state.onDisplayAuction.lastAuctionSweeperId);
  const lastAuctionStartTime = useAppSelector(state => state.onDisplayAuction.lastAuctionStartTime);
  const onDisplayAuctionSweeperId = onDisplayAuction?.sweeperId?.toNumber() || 0;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!lastAuctionSweeperId) return;
    if (!lastAuctionStartTime) return;

    if (initialAuctionId !== undefined && lastAuctionStartTime !== undefined) {

      // handle out of bounds sweeper path ids
      if (initialAuctionId > lastAuctionSweeperId || initialAuctionId < 0) {
        dispatch(setOnDisplayAuctionSweeperId(lastAuctionSweeperId));
        dispatch(setOnDisplayAuctionStartTime(lastAuctionStartTime!));
        dispatch(push(sweeperPath(lastAuctionSweeperId)));
      } else {
        if (onDisplayAuction === undefined) {
          // handle regular sweeper path ids on first load
          dispatch(setOnDisplayAuctionSweeperId(initialAuctionId));
        }
      }
    } else {
      // no sweeper path id set
      if (lastAuctionSweeperId && lastAuctionStartTime) {
        dispatch(setOnDisplayAuctionSweeperId(lastAuctionSweeperId));
        dispatch(setOnDisplayAuctionStartTime(lastAuctionStartTime!));
      }
    }
  }, [lastAuctionSweeperId, lastAuctionStartTime, dispatch, initialAuctionId, onDisplayAuction]);

  return (
    <>
      <Auction auction={onDisplayAuction} />
      {onDisplayAuctionSweeperId > 0 && onDisplayAuctionSweeperId !== lastAuctionSweeperId && <ProfileActivityFeed sweeperId={onDisplayAuctionSweeperId} />}
      <Banner currentAuction={onDisplayAuctionSweeperId} />
      <Documentation />
      <div className="more-documents">
        <button className="power-button" onClick={() => {
          const a = document.createElement('a')
          a.href = '/sweepers-whitepaper.pdf'
          a.target = '_blank'
          document.querySelector('body')?.append(a)
          a.click()
          a.remove()
        }}>Whitepaper</button>
        <button className="power-button" onClick={() => {
          const a = document.createElement('a')
          a.href = 'http://docs.sweepersclub.com/'
          a.target = '_blank'
          document.querySelector('body')?.append(a)
          a.click()
          a.remove()
        }}>Gitbook</button>
      </div>
    </>
  );
};
export default AuctionPage;
