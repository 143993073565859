import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OnDisplayAuctionState {
  lastAuctionSweeperId: number | undefined;
  lastAuctionStartTime: number | undefined;
  onDisplayAuctionSweeperId: number | undefined;
  onDisplayAuctionStartTime: number | undefined;
}

const initialState: OnDisplayAuctionState = {
  lastAuctionSweeperId: undefined,
  lastAuctionStartTime: undefined,
  onDisplayAuctionSweeperId: undefined,
  onDisplayAuctionStartTime: undefined,
};

const onDisplayAuction = createSlice({
  name: 'onDisplayAuction',
  initialState: initialState,
  reducers: {
    setLastAuctionSweeperId: (state, action: PayloadAction<number>) => {
      state.lastAuctionSweeperId = action.payload;
    },
    setLastAuctionStartTime: (state, action: PayloadAction<number>) => {
      state.lastAuctionStartTime = action.payload;
    },
    setOnDisplayAuctionSweeperId: (state, action: PayloadAction<number>) => {
      state.onDisplayAuctionSweeperId = action.payload;
    },
    setOnDisplayAuctionStartTime: (state, action: PayloadAction<number>) => {
      state.onDisplayAuctionStartTime = action.payload;
    },
    setPrevOnDisplayAuctionSweeperId: state => {
      if (!state.onDisplayAuctionSweeperId) return;
      if (state.onDisplayAuctionSweeperId === 0) return;
      state.onDisplayAuctionSweeperId = state.onDisplayAuctionSweeperId - 1;
    },
    setNextOnDisplayAuctionSweeperId: state => {
      if (state.onDisplayAuctionSweeperId === undefined) return;
      if (state.lastAuctionSweeperId === state.onDisplayAuctionSweeperId) return;
      state.onDisplayAuctionSweeperId = state.onDisplayAuctionSweeperId + 1;
    },
  },
});

export const {
  setLastAuctionSweeperId,
  setLastAuctionStartTime,
  setOnDisplayAuctionStartTime,
  setOnDisplayAuctionSweeperId,
  setPrevOnDisplayAuctionSweeperId,
  setNextOnDisplayAuctionSweeperId,
} = onDisplayAuction.actions;

export default onDisplayAuction.reducer;
