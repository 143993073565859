import { Auction } from '../wrappers/sweepersAuction';
import { AuctionState } from '../state/slices/auction';
import { BigNumber } from '@ethersproject/bignumber';

export const isFounderSweeper = (sweeperId: BigNumber) => {
  return sweeperId.mod(10).eq(0) || sweeperId.eq(0);
};

//? checks for SweepersTreasury gifted sweepers
export const isSweepersTreasurySweeper = (sweeperId: BigNumber) => {
  return sweeperId.mod(10).eq(1) || sweeperId.eq(1);
};

const emptyFounderAuction = (onDisplayAuctionId: number): Auction => {
  return {
    amount: BigNumber.from(0).toJSON(),
    bidder: '',
    startTime: BigNumber.from(0).toJSON(),
    endTime: BigNumber.from(0).toJSON(),
    sweeperId: BigNumber.from(onDisplayAuctionId).toJSON(),
    settled: false,
    sniped: false,
    snipedAmount: BigNumber.from(0).toJSON(),
    snipedBy: null
  };
};

export const findAuction = (id: BigNumber, auctions: AuctionState[]): Auction | undefined => {
  return auctions.find(auction => {
    return BigNumber.from(auction.activeAuction?.sweeperId).eq(id);
  })?.activeAuction;
};



//TODO: checkout
/**
 *
 * @param sweeperId
 * @param pastAuctions
 * @returns empty `Auction` object with `startTime` set to auction after param `sweeperId`
 */
export const generateEmptyFounderAuction = (
  sweeperId: BigNumber,
  pastAuctions: AuctionState[],
): Auction => {
  const founderAuction = emptyFounderAuction(sweeperId.toNumber());

  // When the 9th Sweeper's auction is settled, three events occur:
  // (1) a newly minted Sweeper is sent to the Sweepers DAO
  // (2) a newly minted Sweeper is sent to the Sweepers DAO
  // (3) a new Sweeper auction is started (`auctionAbove`)
  // Since neither (1) nor (2) go through an auction, their `startTime` is derived from the `auctionAbove`.
  const distanceToAuctionAbove = isFounderSweeper(BigNumber.from(sweeperId)) ? 2 : 1;
  const auctionAbove = findAuction(sweeperId.add(distanceToAuctionAbove), pastAuctions);
  const auctionAboveStartTime = auctionAbove && BigNumber.from(auctionAbove.startTime);
  if (auctionAboveStartTime) founderAuction.startTime = auctionAboveStartTime.toJSON();

  return founderAuction;
};
