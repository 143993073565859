import { Alert, Button, Form } from 'react-bootstrap';
import { useEthers } from '@usedapp/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useAccountVotes } from '../../wrappers/sweeperToken';
import classes from './Ideas.module.css';
import IdeaCard from '../IdeaCard';
import { Idea, useIdeas, SORT_BY } from '../../hooks/useIdeas';
import { useMemo, useState } from 'react';

const Ideas = () => {
  const { account } = useEthers();
  const history = useHistory();
  const { getIdeas, voteOnIdeaList, setSortBy } = useIdeas();

  const ideas: any = getIdeas();
  const handleSortChange = (e: any) => {
    setSortBy(e.target.value);
  };

  const sweeperBalance = useAccountVotes(account || undefined) ?? 0;

  const nullStateCopy = () => {
    if (Boolean(account)) {
      return 'You have no Sweepers.';
    }
    return 'Connect wallet to submit an idea.';
  };

  const hasSweepers = sweeperBalance > 0;
  const [onlyActive, setOnlyActive] = useState(true);
  const filteredIdeas = useMemo(() => {
    const thirty = 30 * 24 * 60 * 60 * 1000;
    const now = new Date().getTime();
    return ideas?.filter((a: any) => {
      const updated = new Date(a.updatedAt).getTime();
      return onlyActive ? now - updated < thirty : now - updated > thirty;
    }) || []
  }, [ideas, onlyActive])
  return (
    <div>
      <button className={`historic-button ${!onlyActive ? 'active' : ''}`} onClick={() => setOnlyActive(a => !a)}>{onlyActive ? 'View Historical' : "View Active"}</button>
      <div>
        <h3 className={classes.heading}>Ideas</h3>
        <div className={clsx('d-flex', classes.submitIdeaButtonWrapper)}>
          {ideas?.length > 0 && (
            <div className={classes.sortFilter}>
              <span className={classes.sortLabel}>Sort By:</span>
              <Form.Select
                aria-label="Order by"
                onChange={handleSortChange}
                className={classes.sortSelect}
              >
                {Object.keys(SORT_BY).map(k => (
                  <option value={k} key={k}>
                    {SORT_BY[k]}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
          {account !== undefined && hasSweepers ? (
            <Button className={classes.generateBtn} onClick={() => history.push('/ideas/create')}>
              Submit Idea
            </Button>
          ) : (
            <>
              <div className={classes.nullBtnWrapper}>
                <Button className={classes.generateBtnDisabled}>Submit Idea</Button>
              </div>
            </>
          )}
        </div>
      </div>
      {(!Boolean(account) || !hasSweepers) && (
        <div className={classes.nullStateCopy}>{nullStateCopy()}</div>
      )}
      
      {filteredIdeas?.length ? (
        <span className="space-y-4">
          {filteredIdeas.map((idea: Idea, i: any) => {
            return (
              <IdeaCard
                idea={idea}
                key={`idea-${idea.id}`}
                voteOnIdea={voteOnIdeaList}
                sweeperBalance={sweeperBalance}
              />
            );
          })}
        </span>
      ) : (
        <Alert variant="secondary">
          <Alert.Heading>No ideas found.</Alert.Heading>
          <p>Ideas submitted by community members will appear here.</p>
        </Alert>
      )}
    </div>
  );
};

export default Ideas;
