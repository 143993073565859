import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Auction } from '../../wrappers/sweepersAuction';
import classes from './SettleManuallyBtn.module.css';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import { CHAIN_ID } from '../../config';
import { Bid } from '../../utils/types';
import { shortenAddress } from '@usedapp/core';
import useGasPrice from '../../hooks/useGasPrice';
import useSettlementContract from '../../hooks/useSettlementContract';

const SettleManuallyBtn: React.FC<{
  settleAuctionHandler: () => void;
  auction: Auction;
  reservePrice?: string;
  disabled?: boolean;
}> = props => {
  const { disabled } = props;
  const gasPrice = useGasPrice()
  const { isAutoSettling, maxGWEI } = useSettlementContract()
  const { settleAuctionHandler, auction, reservePrice } = props;
  const autosettle = useMemo(() => isAutoSettling && maxGWEI.gt(gasPrice), [isAutoSettling, maxGWEI, gasPrice])
  const gasToHigh = useMemo(() => gasPrice.gte(maxGWEI), [gasPrice, maxGWEI])
  const canSettle = useMemo(() => !isAutoSettling || auction.amount.eq(0) || gasToHigh, [auction, isAutoSettling, gasToHigh])
  const cantSettle = useCallback(() => {
    console.log('Prevented settlement action to allow auto settle to occur. maxGWEI:', maxGWEI.toString(), '| gasPrice:', gasPrice.toString(), canSettle, !isAutoSettling, gasToHigh)
  }, [maxGWEI, gasPrice])
  const settle = useCallback(canSettle ? settleAuctionHandler : cantSettle, [canSettle, settleAuctionHandler, cantSettle])
  return (
    <p className={classes.emergencySettleWrapper}>
      <Button disabled={disabled || false} className={`${classes.bidBtnAuctionEnded} column`} onClick={settle}>
        <span>{auction.amount.eq(0) ? `Buy Now for ${reservePrice} ETH` : autosettle ? 'Settling Auction...' : 'Settle Auction'}</span>
        {gasToHigh && !auction.amount.eq(0) && <span style={{ opacity: '.7', fontSize: '.9rem' }}>Gas is currently too high for auto settlement.</span>}
      </Button>
    </p >
  );
};

export default SettleManuallyBtn;
