import React, { useState } from 'react';
import { Col, Collapse, Table } from 'react-bootstrap';
import Section from '../../layout/Section';
import classes from './ProfileActivityFeed.module.css';

import { useQuery } from '@apollo/client';
import { Proposal, ProposalState, useAllProposals } from '../../wrappers/SweepersTreasury';
import { createTimestampAllProposals, sweeperVotingHistoryQuery } from '../../wrappers/subgraph';
import SweeperProfileVoteRow from '../SweeperProfileVoteRow';
import { LoadingSweeper } from '../Sweeper';
import { useSweeperCanVoteTimestamp } from '../../wrappers/sweepersAuction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface ProfileActivityFeedProps {
  sweeperId: number;
}

interface ProposalInfo {
  id: number;
}

export interface SweeperVoteHistory {
  proposal: ProposalInfo;
  support: boolean;
  supportDetailed: number;
}

const ProfileActivityFeed: React.FC<ProfileActivityFeedProps> = props => {
  const { sweeperId } = props;

  const MAX_EVENTS_SHOW_ABOVE_FOLD = 5;

  const [truncateProposals, setTruncateProposals] = useState(true);

  const { loading, error, data } = useQuery(sweeperVotingHistoryQuery(sweeperId));
  const {
    loading: proposalTimestampLoading,
    error: proposalTimestampError,
    data: proposalCreatedTimestamps,
  } = useQuery(createTimestampAllProposals());

  const sweeperCanVoteTimestamp = useSweeperCanVoteTimestamp(sweeperId);

  const { data: proposals } = useAllProposals();

  if (loading || !proposals || !proposals.length || proposalTimestampLoading) {
    return <></>;
  } else if (error || proposalTimestampError) {
    return <div>Failed to fetch sweeper activity history</div>;
  }

  const sweeperVotes: { [key: string]: SweeperVoteHistory } = data.sweeper.votes
    .slice(0)
    .reduce((acc: any, h: SweeperVoteHistory, i: number) => {
      acc[h.proposal.id] = h;
      return acc;
    }, {});


  const filteredProposals = proposals.filter((p: Proposal, id: number) => {
    const proposalCreationTimestamp = parseInt(
      proposalCreatedTimestamps.proposals[id].createdTimestamp,
    );

    // console.log(`proposalCreationTimestamp == ${proposalCreationTimestamp}. sweeperId=${sweeperId}. sweeperCanVoteTimestamp==${sweeperCanVoteTimestamp}. id=${id} Proposal=${p.id}`)

    // Filter props from before the Sweeper was born
    if (sweeperCanVoteTimestamp.gt(proposalCreationTimestamp)) {
      return false;
    }
    // Filter props which were cancelled and got 0 votes of any kind
    if (
      p.status === ProposalState.CANCELED &&
      p.forCount + p.abstainCount + p.againstCount === 0
    ) {
      return false;
    }
    return true;
  });

  // console.log(`filteredProposals==${filteredProposals.length} proposals==${proposals.length}`)

  return (
    <div id="profile-feed-auction" className="pad column w100 afs jfs">
      <div className={classes.headerWrapper}>
        <h2 style={{ fontFamily: 'cafeteria, sans serif' }}>Activity</h2>
      </div>
      {filteredProposals && filteredProposals.length ? (
        <>
          <Table responsive hover className={`${classes.aboveTheFoldEventsTable} w100`}>
            <tbody className={classes.sweeperInfoPadding}>
              {filteredProposals?.length ? (
                filteredProposals
                  .slice(0)
                  .reverse()
                  .slice(0, MAX_EVENTS_SHOW_ABOVE_FOLD)
                  .map((p: Proposal, i: number) => {
                    const vote = p.id ? sweeperVotes[p.id] : undefined;
                    return <SweeperProfileVoteRow proposal={p} vote={vote} key={i} />;
                  })
              ) : (
                <LoadingSweeper />
              )}
            </tbody>
          </Table>
          <Collapse in={!truncateProposals}>
            <div>
              <Table responsive hover>
                <tbody className={classes.sweeperInfoPadding}>
                  {filteredProposals?.length ? (
                    filteredProposals
                      .slice(0)
                      .reverse()
                      .slice(MAX_EVENTS_SHOW_ABOVE_FOLD, filteredProposals.length)
                      .map((p: Proposal, i: number) => {
                        const vote = p.id ? sweeperVotes[p.id] : undefined;
                        return <SweeperProfileVoteRow proposal={p} vote={vote} key={i} />;
                      })
                  ) : (
                    <LoadingSweeper />
                  )}
                </tbody>
              </Table>
            </div>
          </Collapse>

          {filteredProposals.length <= MAX_EVENTS_SHOW_ABOVE_FOLD ? (
            <></>
          ) : (
            <>
              {truncateProposals ? (
                <div
                  className={classes.expandCollapseCopy}
                  onClick={() => setTruncateProposals(false)}
                >
                  Show all {filteredProposals.length} events{' '}
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              ) : (
                <div
                  className={classes.expandCollapseCopy}
                  onClick={() => setTruncateProposals(true)}
                >
                  Show fewer <FontAwesomeIcon icon={faChevronUp} />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className={classes.nullStateCopy}>
          This Sweeper has no activity, since it was just created. Check back soon!
        </div>
      )}
    </div>
  );
};

export default ProfileActivityFeed;
