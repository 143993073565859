import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Section from '../../layout/Section';
import { Proposal, useAllProposals, useProposalThreshold } from '../../wrappers/SweepersTreasury';
import { useAllBigSweeperProposals } from '../../wrappers/bigSweepersTreasury';
import Proposals, { SnapshotProposal } from '../../components/Proposals';
import classes from './Governance.module.css';
import { utils } from 'ethers/lib/ethers';
import clsx from 'clsx';
import { useTreasuryBalance, useTreasuryUSDValue } from '../../hooks/useTreasuryBalance';

import SweeperImageInllineTable from '../../components/SweeperImageInllineTable';
import { isMobileScreen } from '../../utils/isMobile';
import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { snapshotProposalsQuery, sweepersInTreasuryQuery } from '../../wrappers/subgraph';
import { useQuery } from '@apollo/client';
import { useEthers } from '@usedapp/core';
import Link from '../../components/Link';


const GovernancePage = () => {
  const {library} = useEthers();
  const { data: proposals } = useAllProposals();
/*   const { data: bigSweeperProposals, loading: loadingBigSweeperProposals } = useAllBigSweeperProposals(); */

  const {
    loading: sweepersInTreasuryLoading,
    error: sweepersInTreasuryError,
    data: sweepersInTreasury,
  } = useQuery(sweepersInTreasuryQuery(), {
    context: { clientName: 'SweepersTreasury' },
  });

  const {
    loading: snapshotProposalLoading,
    error: snapshotProposalError,
    data: snapshotProposalData,
  } = useQuery(snapshotProposalsQuery(), {
    context: { clientName: 'SweepersTreasurySnapshot' },
  });
  const [onlyActive, setOnlyActive] = useState(true);
  const threshold = useProposalThreshold();
  const sweepersRequired = threshold !== undefined ? threshold : '...';
  const sweeperThresholdCopy = `${sweepersRequired} ${threshold === 0 ? 'Sweeper' : 'Sweepers'}`;

  const [isSweepersTreasuryProp, setisSweepersTreasuryProp] = useState(false);
  const [snapshotProposals, setSnapshotProposals] = useState(undefined);

  const treasuryBalance = useTreasuryBalance();
  const treasuryBalanceUSD = useTreasuryUSDValue();

  const [daoButtonActive, setDaoButtonActive] = useState('1');

  const isMobile = isMobileScreen();

  function setLilSweepersTreasuryProps() {
    setDaoButtonActive('1');
    setisSweepersTreasuryProp(false);
  }

  function setSweepersTreasuryProps() {
    setDaoButtonActive('2');
    setisSweepersTreasuryProp(true);
  }
  const [currentBlock, setCurrentBlock] = useState(0);
  useEffect(() => {
    const handleLibrary = setInterval(() => {if (library) setCurrentBlock(library._lastBlockNumber);}, 12000);
    if (library) setCurrentBlock(library._lastBlockNumber)
    return () => clearInterval(handleLibrary);
  },  [library])
  const SweepersTreasuryLink = <Link text="Sweepers Governance" url="https://sweepersclub.com" leavesPage={true} />;
  const snapshotLink = <Link text="Snapshot" url="https://sweepersclub.com" leavesPage={true} />;
  const filteredProposals = useMemo(() => proposals?.filter((a: any) => {
    const isHistoric = currentBlock - a.endBlock
    return !onlyActive ? isHistoric > 216000 : isHistoric < 216000
  }) || [], [proposals, onlyActive, currentBlock]);
  const filteredSnapshotProposals = useMemo(() => snapshotProposalData?.proposals.map((v: any, i: any) => ({
    ...v,
    proposalNo: i + 1,
  })).filter((a: any) => {
    const endTime = new Date(a.end * 1000);
    const isHistoric = new Date().getTime() - (1000*60*60*24*30) - endTime.getTime()
    return !onlyActive ? isHistoric > 0 : isHistoric < 0
  }) || [], [snapshotProposalData, onlyActive]);
  if (sweepersInTreasuryLoading || snapshotProposalLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Section fullWidth={false} className={classes.section}>
      <Col lg={10} className={classes.wrapper}>
        <Row className={classes.headerRow}>
          <span>Governance</span>
          <div className={classes.headerWrapper}>
            <h1>{!isSweepersTreasuryProp ? 'Sweepers Governance' : 'Sweepers Governance'}</h1>
            {/*  <div className="btn-toolbar" role="btn-toolbar" aria-label="Basic example">
              <Button
                key={1}
                className={
                  daoButtonActive === '1'
                    ? classes.governanceSwitchBtnActive
                    : classes.governanceSwitchBtn
                }
                id={'1'}
                onClick={e => setLilSweepersTreasuryProps()}
              >
                Sweepers DAO
              </Button>
             <Button
                key={2}
                className={
                  daoButtonActive === '2'
                    ? classes.governanceSwitchBtn2Active
                    : classes.governanceSwitchBtn2
                }
                onClick={e => setSweepersTreasuryProps()}
              >
                Sweepers DAO
              </Button> 
            </div>*/}
          </div>
        </Row>

        <>
          <p className={classes.subheading}>
            Remember, you must hold a minimum of five Sweeper NFTs to draft and submit a proposal.
          </p>
          <p className={classes.subheading}>
            Each Sweeper NFT holder is an irrevocable member of Sweepers and entitled to one vote in all governance matters. Sweeper votes are non-transferable (if you sell your Sweeper, the vote goes with it) but delegatable, which means you can assign your vote to someone else's wallet as long as you own your Sweeper.
          </p>
          <p className={classes.subheading}>
            For example, if someone holds two Sweeper NFTs and another member holds three. The two individuals can combine and delegate their votes to one of the holders, now making one individual have five total. That person can then draft and submit a proposal.
          </p>



          <Row className={classes.treasuryInfoCard}>
            <Col lg={8} className={classes.treasuryAmtWrapper} style={{ height: '100%' }}>
              <Row className={classes.headerRow}>
                <span>Treasury</span>
              </Row>


              {isSweepersTreasuryProp ? (
                <></>
              ) : (<Row >
                <Col className={clsx(classes.ethTreasuryAmt)} lg={3} style={{ height: '100%' }}>
                  <h1 className={classes.ethSymbol}><FontAwesomeIcon icon={faEthereum} /></h1>
                  <h1>
                    {treasuryBalance &&
                      Number(Number(utils.formatEther(treasuryBalance)).toFixed(2)).toLocaleString(
                        'en-US',
                      )}
                  </h1>
                </Col>
                <Col className={classes.usdTreasuryAmt} style={{ height: '100%' }}>
                  <h1 className={classes.usdBalance}>
                    ${' '}
                    {treasuryBalanceUSD &&
                      Number(treasuryBalanceUSD.toFixed(2)).toLocaleString('en-US')}
                  </h1>
                </Col>
              </Row>
              )}


              {/* <Row>
                <Col className={clsx(classes.ethTreasuryAmt)} lg={3}>
                  <h1 className={classes.BigSweeperBalance}>
                    {sweepersInTreasury?.accounts[0].tokenBalance}
                  </h1>
                  <h1>{' Sweepers'}</h1>
                </Col>

                {!isMobile && (
                  <Col className={classes.usdTreasuryAmt}>
                    <Row className={classes.sweeperProfilePics}>
                      <SweeperImageInllineTable
                        sweeperIds={sweepersInTreasury?.accounts[0].sweepers.flatMap(
                          (obj: { id: any }) => obj.id,
                        )}
                      />
                    </Row>
                  </Col>
                )}
              </Row> */}
            </Col>
            <Col className={classes.treasuryInfoText}>
              {!isSweepersTreasuryProp ? (
                <>
                  This treasury exists for <span className={classes.boldText}>Sweepers Governance</span>{' '}
                  participants to allocate resources for the long-term growth and prosperity of the
                  Sweepers project.
                </>
              ) : (
                <>
                  The Sweepers purchased by Sweepers exists for{' '}
                  <span className={classes.boldText}>Sweepers Governance</span> participants to allocate
                  resources for the long-term growth and prosperity of the Sweepers project.
                </>
              )}
            </Col>
          </Row>
        </>
        <button className={`historic-button ${!onlyActive ? 'active' : ''}`} onClick={() => setOnlyActive(a => !a)}>{onlyActive ? 'View Historical' : "View Active"}</button>
        <Proposals
          proposals={filteredProposals}
          SweepersTreasuryProposals={[]}
          snapshotProposals={filteredSnapshotProposals}
          isSweepersTreasuryProp={isSweepersTreasuryProp}
        />
      </Col>
    </Section>
  );
};
export default GovernancePage;
