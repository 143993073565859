import { Col, Row } from 'react-bootstrap';
import Section from '../../layout/Section';
import Ideas from '../../components/Ideas';
import classes from './Ideas.module.css';

const IdeasPage = () => {
  const sweepersRequired = 5;
  const sweeperThresholdCopy = `${sweepersRequired}`;

  return (
    <Section fullWidth={false} className={classes.section}>
      <Col lg={10} className={classes.wrapper}>
        <Row className={classes.headerRow}>
          <span>Contribute</span>
          <h1>Submit Your Ideas Here</h1>
        </Row>
        <p className={classes.subheading}>
        Everything begins with an idea! Any Sweepers NFT holder can come here and submit ideas on what should be “swept” by the Treasury. This page is for discourse only; an “idea” presented is not a “proposal.”
        </p>
        <p className={classes.subheading}>
        Sweepers members with {sweeperThresholdCopy} or more NFTs in their wallet can take one of these “ideas” and submit a “proposal” to be voted upon under the “Governance” tab. 
        </p>
        <Ideas />
      </Col>
    </Section>
  );
};
export default IdeasPage;
