import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import classes from './SweeperInfoCard.module.css';

import _AddressIcon from '../../assets/icons/Address.svg';
import _BidsIcon from '../../assets/icons/Bids.svg';
import _DownloadIcon from '../../assets/icons/Download.svg';
import _OpenSeaIcon from '../../assets/icons/Opensea.svg';

import SweeperInfoRowBirthday from '../SweeperInfoRowBirthday';
import SweeperInfoRowHolder from '../SweeperInfoRowHolder';
import SweeperInfoRowButton from '../SweeperInfoRowButton';
import { useAppSelector } from '../../hooks';

import config from '../../config';
import { buildEtherscanAddressLink } from '../../utils/etherscan';
import { buildOpenseaAddressLink } from '../../utils/opensea';
import { useSweeperData } from '../StandaloneSweeper';
import { svg2png } from '../../utils/svg2png';

interface SweeperInfoCardProps {
  sweeperId: number;
  bidHistoryOnClickHandler: () => void;
}

const SweeperInfoCard: React.FC<SweeperInfoCardProps> = props => {
  const { sweeperId, bidHistoryOnClickHandler } = props;

  const sweeper = useSweeperData(sweeperId);

  const etherscanBaseURL = useMemo(
    () => buildEtherscanAddressLink(config.addresses.sweepersToken),
    [config.addresses.sweepersToken],
  );

  const etherscanButtonClickHandler = useCallback(
    () => window.open(`${etherscanBaseURL}/${sweeperId}`, '_blank'),
    [etherscanBaseURL, sweeperId],
  );

  const openseaBaseURL = useMemo(
    () => buildOpenseaAddressLink(config.addresses.sweepersToken),
    [config.addresses.sweepersToken],
  );

  const openseaButtonClickHandler = useCallback(
    () => window.open(`${openseaBaseURL}/${sweeperId}`, '_blank'),
    [openseaBaseURL, sweeperId],
  );

  const downloadPngClicked = useCallback(async () => {
    if (!sweeper) {
      return;
    }

    // console.log(sweeper);
    const png = await svg2png(sweeper.svg, 500, 500)
    if (!png) {
      return;
    }

    const downloadEl = document.createElement('a');
    downloadEl.href = png;
    downloadEl.download = `sweeper-${sweeperId}.png`;
    downloadEl.click();
  }, [sweeper, sweeperId]);

  const lastAuctionSweeperId = useAppSelector(state => state.onDisplayAuction.lastAuctionSweeperId);

  return (
    <>
{/*       <div className="w100 column jfs">
        <SweeperInfoRowBirthday sweeperId={sweeperId} />
      </div> */}
      <div className="w100 column jfs">
        <SweeperInfoRowHolder sweeperId={sweeperId} />
      </div>
      <div className="w100 column jfs">
        <SweeperInfoRowButton
          iconImgSource={_BidsIcon}
          btnText={lastAuctionSweeperId === sweeperId ? 'Bids' : 'Bid history'}
          onClickHandler={bidHistoryOnClickHandler}
        />
        <SweeperInfoRowButton
          iconImgSource={_AddressIcon}
          btnText={'Etherscan'}
          onClickHandler={etherscanButtonClickHandler}
        />
        <SweeperInfoRowButton
          iconImgSource={_OpenSeaIcon}
          btnText={'Opensea'}
          onClickHandler={openseaButtonClickHandler}
        />
        {sweeper && <SweeperInfoRowButton
          iconImgSource={_DownloadIcon}
          btnText="Download PNG"
          onClickHandler={downloadPngClicked}
        />}
      </div>
    </>
  );
};

export default SweeperInfoCard;
