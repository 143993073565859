import classes from './BidHistoryModal.module.css';
import ReactDOM from 'react-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { Auction } from '../../wrappers/sweepersAuction';
import { StandaloneSweeperRoundedCorners } from '../StandaloneSweeper';
import { useAuctionBids } from '../../wrappers/onDisplayAuction';
import { Bid } from '../../utils/types';
import basicSweeper from '../../assets/main-sweeper.png';
import BidHistoryModalRow from '../BidHistoryModalRow';
import { BigNumber } from 'ethers';

export const Backdrop: React.FC<{ onDismiss: () => void }> = props => {
  return <div className={classes.backdrop} onClick={props.onDismiss} />;
};

const BidHistoryModalOverlay: React.FC<{
  auction: Auction;
  onDismiss: () => void;
}> = props => {
  const { onDismiss, auction, } = props;
  const bids = useAuctionBids(auction?.sweeperId || BigNumber.from(0));
  const { endTime } = auction;
  const [ended, setEnded] = useState(new Date().getTime() > endTime.toNumber() * 1000);
  useEffect(() => {
    if (auction.settled) return;
    const setTheState = () => {
      const curTime = new Date().getTime()
      if (!ended && curTime - endTime.toNumber() * 1000 > 0) setEnded(true);
    }
    const interval = setInterval(setTheState, 1000);
    setTheState();
    return () => clearInterval(interval);
  }, [auction]);
  const winningBid = useMemo<string>(() => {
    if (auction.settled) return bids?.find(a => a.value.eq(auction.sniped ? (auction.snipedAmount || BigNumber.from(0)) : auction.amount))?.transactionHash || ''
    let bid;
    const bidsToCheck = bids || []
    if (bidsToCheck.length > 0) {
      for (let i = 0; i < bidsToCheck.length; i++) {
        if (bidsToCheck[i].value.gt(bid?.value || 0)) {
          bid = bidsToCheck[i];
        }
      }
    }
    return bid?.transactionHash || '';
  }, [auction, bids]);
  return (
    <>
      <div className={classes.closeBtnWrapper}>
        <button onClick={onDismiss} className={classes.closeBtn}>
          <XIcon className={classes.icon} />
        </button>
      </div>

      <div className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.header}>
            <div className={classes.sweeperWrapper}>
              <img className="small-square" src={basicSweeper}></img>
              {/*               <StandaloneSweeperRoundedCorners sweeperId={auction && auction.sweeperId} /> */}
            </div>

            <div className={classes.title}>
              <h2>Bids for</h2>
              <h1>Sweeper {auction?.sweeperId?.toString() || '0'}</h1>
            </div>
          </div>
          <div className={classes.bidWrapper}>
            {bids && bids.length > 0 ? (
              <ul>
                {bids?.map((bid: Bid, i: number) => {
                  const wonBid = bids.find(a => a.transactionHash === winningBid)
                  const lostBid = Boolean(wonBid && bid.value.gt(wonBid.value))
                  return <BidHistoryModalRow index={i} bid={bid} winningBid={winningBid} lostBid={lostBid} />;
                })}
              </ul>
            ) : (
              <div className={classes.nullStateText}>Bids will appear here</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const BidHistoryModal: React.FC<{
  auction: Auction;
  onDismiss: () => void;
}> = props => {
  const { onDismiss, auction } = props;
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onDismiss={onDismiss} />,
        document.getElementById('backdrop-root')!,
      )}
      {ReactDOM.createPortal(
        <BidHistoryModalOverlay onDismiss={onDismiss} auction={auction} />,
        document.getElementById('overlay-root')!,
      )}
    </>
  );
};

export default BidHistoryModal;
