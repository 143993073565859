import React from "react";
import { useAppContext } from "../../App.jsx";
import svgs from "./game-svgs";

const {copy: copyIcon} = svgs
export const addToClipboard = text => {
    if (!text) return 
    /* COPY */
    try {
        navigator?.clipboard?.writeText(text)
        if (typeof window.flash === 'function') window.flash('Copied to clipboard')
    } catch(e) {
        console.error(e)
        if (typeof window.flash === 'function') window.flash('Failed to copy to clipboard')
    }
}
export default function CopyIcon({text}) {
    const { flash } = useAppContext();
    return (
        <div onClick={() => {
            try {
                addToClipboard(text)
                flash('Copied to clipboard');
            } catch(e) {
                console.log(e)
                flash('Error copying to clipboard');
            }
        }} className="copy-icon pointer">
            {copyIcon}
        </div>
    );
}