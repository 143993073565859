import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { BigNumber } from 'ethers'
import { useAppContext } from '../../App'
import './garage.css'
import { multicall, toTopia, useStakingContract, STAKING, StakingABI, useNFTWallet, NFT, NFTABI, useGameApproval, SWEEPER, useApproval, SWEEPER_ABI, useMigrationContract } from '../../web3-hooks'
import Svgs from '../../svgs'
import DustBanner from '../../components/DustBanner'
import { Button } from 'react-bootstrap'

const { checkmark } = Svgs
export const toTimeLength = (msTimestamp, fullLength, caps, plural) => {
    let seconds = Math.floor(msTimestamp / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)
    return `${days ? `${days}${fullLength ? ` ${caps ? 'D' : 'd'}ay${plural && days !== 1 ? 's' : ''} ` : 'D'} ` : ''}${hours % 24 ? `${hours % 24}${fullLength ? ` ${caps ? 'H' : 'h'}our${plural && hours % 24 !== 1 ? 's' : ''} ` : 'H'} ` : ''}${minutes % 60 ? `${minutes % 60}${fullLength ? ` ${caps ? 'M' : 'm'}inute${minutes % 60 !== 1 && plural ? 's' : ''} ` : 'M'} ` : ''}${seconds % 60 && (!fullLength || seconds % 60) ? `${seconds % 60}${fullLength ? ` ${caps ? 'S' : 's'}econd${plural && seconds % 60 !== 1 ? 's' : ''}` : 'S'}` : ''}`.trim() || 'forever!'
}
function Token({
    claim = () => new Promise(res => res()),
    dailyEarningRate,
    dailyEarnableAmount,
    multiplier,
    unstake = () => new Promise(res => res()),
    earned,
    stakedAt,
    image,
    id,
    staked,
    selected,
    select,
    deselect,
    isListed
}) {
    const { redirect } = useAppContext()
    const handleSelection = useCallback(() => {
        if (selected) {
            deselect()
        } else {
            select()
        }
    }, [selected])
    const [renderCount, setRenderCount] = useState(0)
    useEffect(() => {
        // makes timelength countdown
        let rerenderer = setInterval(() => {
            return setRenderCount(a => a + 1)
        }, 1000)
        return () => clearInterval(rerenderer)
    })
    const stakedTime = useMemo(() => <span className="staked-time">{staked ? toTimeLength(new Date().getTime() - stakedAt) : 'Not Sweeping'}</span>, [stakedAt, staked, renderCount])
    const imageElem = useMemo(() => <img src={image} alt="token" />, [image])
    const multiplierElem = useMemo(() => <span className="align-text-right">{multiplier}x</span>, [multiplier])
    // console.log(multiplier.toNumber())
    const earningRateElem = useMemo(() => <span className="align-text-right">{parseFloat(toTopia(dailyEarningRate / 10000))}</span>, [dailyEarningRate])
    return (<div className="wallet-token">
        <a href={`https://sweepersclub.com/sweeper/${id}`} target="_blank">
            <div className="wallet-token-image">
                {imageElem}
            </div>
        </a>
        <div className="wallet-token-head">
            <h5 className="token-id">#{id}</h5>
            {stakedTime}
        </div>
        <div className="wallet-menu">
            <div className="b2 w100 jsb gap">
                <span>Multiplier:</span>
                {multiplierElem}
            </div>
            <div className="b2 w100 jsb gap">
                <span>Rate:</span>
                <span className="ellipsis">{earningRateElem}/day</span>
            </div>
            {staked && <div className="b2 w100 jsb gap">
                <span className="strong">Earned:</span>
                <span className="strong align-text-right ellipsis">{parseFloat(toTopia(earned.toString(), 2))}</span>
            </div>}
        </div>
    </div >)
}
function MigrateGarage({cancelled, cancel, approved, approving, transactionCount, transactionNumber, transactionStatus = 'Token Migration'}) {
    const [cancelling, setCancelling] = useState(false)
    if (cancelled) return null
    return (<div className="migrate-garage">
        <div className="mig-cont">
            <div className="mig-bod">
                <h3 className="strong">{transactionStatus}</h3>
                <span>Please complete the prompts in your web3 wallet.</span>
                <div className="loader"/>
                <span>{!approved ? `Approv${approving ? 'ing' : 'e'} Contract` : `Transaction ${transactionNumber}/${transactionCount}`}</span>
                <Button onClick={() => {
                    setCancelling(true)
                    cancel()
                }}>{cancelling ? 'Cancelling...' : 'Cancel'}</Button>
            </div>
        </div>
    </div>)
}
export default function Migrate() {
    const {
        refreshBalance = () => { console.log('refreshed balance') },
        redirect,
        balance,
        account = '',
        oldWallet: wallet,
        refreshOldWallet: refreshWallet,
        loadingOldWallet: loadingWallet
    } = useAppContext()
    const { contract, getMinimumStakeTime, getDailyEarningRate, unstake, claim } = useMigrationContract()
    const {contract: migrateContract} = useStakingContract()
    const [stakingTokens, setStakingTokens] = useState(false)
    const [unstakingTokens, setUnstakingTokens] = useState(false)
    const [gotDailyEarnableAmount, setGotDailyEarnableAmount] = useState(false)
    const [gettingEarnableAmount, setGettingEarnableAmount] = useState(false)
    const [minimumStakeTime, setMinimumStakeTime] = useState(0)
    const [dailyEarnableAmount, setDailyEarnableAmount] = useState(BigNumber.from(10).mul(BigNumber.from(10).pow(18)))
    const [gotEarnings, setGotEarnings] = useState(false)
    const [claimingTokens, setClaimingTokens] = useState(false)
    const [refreshEarningsCount, setRefreshEarningsCount] = useState(0)
    const refreshEarnings = useCallback(() => setRefreshEarningsCount(refreshEarningsCount + 1), [refreshEarningsCount])
    const [gettingEarnings, setGettingEarnings] = useState(false)
    const [tokens, setTokens] = useState(wallet)
    const [selected, setSelected] = useState([])
    const dustForm = toTopia(balance?.toString(), 3);
    const sweepersBalance = useMemo(() => wallet.length, [wallet])
    const [unstaking, setUnstaking] = useState(false)
    const [migrating, setMigrating] = useState(false)
    const [transactionNumber, setTransactionNumber] = useState(0)
    const [transactionCount, setTransactionCount] = useState(0)
    const [transactionStatus, setTransactionStatus] = useState('Token Migration')
    const {approve, hasApproved} = useApproval(NFT, NFTABI)
    const [approved, setApproved] = useState(false)
    const [approving, setApproving] = useState(false)
    const [checkingApproval, setCheckingApproval] = useState(false)
    const cancelMigration = useRef(false)
    useEffect(() => {
        if (account && !checkingApproval) {
            setCheckingApproval(true)
            hasApproved(SWEEPER).then((approved) => {
                setCheckingApproval(false)
                setApproved(approved)
            }).catch(e => {
                console.log(e)
                setCheckingApproval(false)
                setApproved(false)
            })
        } else if (!account) {
            setApproved(false)
        }
    }, [account])
    const migrateSweepers = useCallback(() => {
        if (migrating) return
        let transactionCount = Math.ceil(tokens.length/95)
        if (!transactionCount) return
        let transactionNumber = 1
        let transactionStatus = !approved ? `Awaiting Approval` : `Migrating Sweepers`
        setTransactionCount(transactionCount)
        setTransactionStatus(transactionStatus)
        setTransactionNumber(transactionNumber)
        setMigrating(true)
        const cancel = () => {
            if (cancelMigration.current) throw new Error('Migration Cancelled')
        }
        const migrate = async () => {
            if (!approved) {
                setApproving(true)
                await approve(SWEEPER).then(() => cancel()).finally(() => setApproving(false))
                setApproved(true)
                setTransactionStatus(`Migrating Sweepers`)
            }
            for (let i = 0; i < transactionCount; i++) {
                await migrateContract.migrateManyByOwner().then(() => cancel())
                transactionNumber++
                if (transactionNumber <= transactionCount) setTransactionNumber(transactionNumber)
            }
            setTransactionStatus(`Migrated Sweepers`)
        }
        migrate().then(() => {
            refreshWallet()
            refreshBalance()
            setTransactionCount(0)
            setTransactionNumber(0)
            setTransactionStatus('')
            redirect('/garage')
        }).catch(e => {
            console.log(e)
            alert(`Error migrating sweepers`)
        }).finally(() => setMigrating(false))
    }, [approved, approve, approving, tokens, migrating, redirect, refreshWallet, refreshBalance])
    const unstakeTokens = useCallback(() => {
        if (unstakingTokens) return
        setUnstakingTokens(true)
        unstake(tokens.filter(a => a.staked).map(u => u.id)).then(() => {
            refreshWallet()
            refreshEarnings()
            setSelected([])
        }).catch(e => {
            console.error(e)
        }).finally(() => setUnstakingTokens(false))
    }, [selected, tokens, refreshEarnings])
    const [listedTokens, setListedTokens] = useState([])
    useEffect(() => {
        if (contract && !gotDailyEarnableAmount && !gettingEarnableAmount) {
            setGettingEarnableAmount(true)
            getDailyEarningRate().then(amount => {
                setDailyEarnableAmount(amount);
                return getMinimumStakeTime()
            }).then(stakeTime => {
                setMinimumStakeTime((stakeTime || 0) * 1000)
                setGotDailyEarnableAmount(true)
            }).catch(console.error).finally(() => setGettingEarnableAmount(false))
        }
    }, [contract])
    useEffect(() => {
        if (contract && account && wallet.length > 0 && !loadingWallet && !gotEarnings && !gettingEarnings) {
            const mapToIsStaked = async (tokens) => {
                return await multicall(STAKING, StakingABI, 'isNFTStaked', tokens.map(token => [token.id])).then(results => results.map(([isStaked], i) => ({ ...tokens[i], staked: isStaked }))).catch(e => { console.error(e); return tokens.map(u => ({ ...u, staked: false })) })
            }
            const mapToStakedInfo = async (tokens) => {
                let listToGather = tokens.filter(a => a.staked)
                if (listToGather.length < 1) return tokens.map(u => ({ ...u, stakedAt: 0, lastClaim: 0 }))
                return await multicall(STAKING, StakingABI, 'StakedNFTInfo', listToGather.map(token => [token.id])).then(results => results.map(([id, stakedTimestamp, lastClaimTimestamp], i) => ({ ...listToGather[i], lastClaim: (lastClaimTimestamp?.toNumber() || 0) * 1000, stakedAt: (stakedTimestamp?.toNumber() || 0) * 1000 })).concat(tokens.filter(a => !a.staked).map(u => ({ ...u, lastClaim: 0, stakedAt: 0 })))).catch(e => { console.error(e); return tokens.map(u => ({ ...u, lastClaim: 0, stakedAt: 0 })) })
            }
            const mapToUnclaimedAmount = async (tokens) => {
                let listToGather = tokens.filter(a => a.staked)
                if (listToGather.length < 1) return tokens.map(u => ({ ...u, earned: BigNumber.from(0) }))
                return await multicall(STAKING, StakingABI, 'getUnclaimedDust', listToGather.map(token => [[token.id]])).then(results => results.map(([amount], i) => ({ ...listToGather[i], earned: amount || BigNumber.from(0) })).concat(tokens.filter(a => !a.staked).map(u => ({ ...u, earned: BigNumber.from(0) })))).catch(e => { console.error(e); return tokens.map(u => ({ ...u, earned: BigNumber.from(0) })) })
            }
            const mapToMultiplier = async (tokens) => {
                return await multicall(NFT, NFTABI, 'seeds', tokens.map(token => [token.id])).then(results => results.map(([multiplier], i) => ({ ...tokens[i], multiplier: (multiplier) || 0 }))).catch(e => { console.error(e); return tokens.map(u => ({ ...u, multiplier: 0 })) })
            }
            const mapToMultiplierRate = async (tokens) => {
                return await multicall(STAKING, StakingABI, 'multiplier', tokens.map(token => [token.multiplier])).then(results => { return results.map(([multiplierRate], i) => ({ ...tokens[i], multiplierRate: (multiplierRate / 10000), dailyEarningRate: (BigNumber.from(dailyEarnableAmount).mul(BigNumber.from(multiplierRate))) })) }).catch(e => { console.error(e); return tokens.map(u => ({ ...u, multiplierRate: 1, dailyEarningRate: dailyEarnableAmount })) })
            }
            setGettingEarnings(true)
            mapToIsStaked(wallet).then(mapToStakedInfo).then(mapToUnclaimedAmount).then(mapToMultiplier).then(mapToMultiplierRate).then(tokens => { setTokens(tokens); setGotEarnings(true) }).catch(e => { console.error(e); setTokens([]) }).finally(() => {
                setGettingEarnings(false)
            })
        } else if ((!account || !contract) && tokens?.length > 0) {
            setTokens([])
        }
    }, [contract, account, wallet, dailyEarnableAmount, gotEarnings, loadingWallet])
    useEffect(() => {
        if (gotEarnings) setGotEarnings(false)
    }, [refreshEarningsCount])
    useEffect(() => {
        let int = setInterval(() => setRefreshEarningsCount(a => a + 1), 15000)
        return () => { clearInterval(int); }
    }, [])
    const [cancelled, setCancelled] = useState(false)
    const totalEarnedAmount = useMemo(() => tokens.reduce((a, b) => a.add(b.earned), BigNumber.from(0)), [tokens])
    const anyStaked = useMemo(() => tokens.findIndex(a => a.staked) > -1, [tokens])
    return (
        <div id="garage">
            {migrating && <MigrateGarage cancelled={cancelMigration.current} cancel={(bool = true) => {cancelMigration.current = bool; setCancelled(true)}} approved={approved} approving={approving} transactionNumber={transactionNumber} transactionStatus={transactionStatus} transactionCount={transactionCount} />}
            <h3 className='rewarded'>Rewards</h3>
            <h1 className='headed'>Migration</h1>
            <h5>{`You have ${sweepersBalance} ${sweepersBalance === 1 ? 'Sweeper' : 'Sweepers'} and ${toTopia(balance.toString())} $DUST`}</h5>
            <p>The garage has migrated. Move your sweepers to the new garage by completing {Math.ceil(tokens.length/95)} transactions to move them to the new garage.</p>
            <div className="grid responsive-two-column w100 gap">
                <Button disabled={!anyStaked} onClick={unstakeTokens} className="w100">Unstake All</Button>
                <Button disabled={anyStaked || !tokens.length} onClick={migrateSweepers} className="w100">Begin Migrate</Button>
            </div>
            <div id="stake-tokens">
                <div className="staking-head b2 jsb w100">
                    <div className="b2 jfs">
                        <h3>My Sweepers <span className='fader-vader'>({wallet.length})</span></h3>
                    </div>
                    <div className="b2 jfe gap-large">
                        <h3>Total $DUST To Receive: {parseFloat(toTopia(totalEarnedAmount?.toString() || '0'))}</h3>
                    </div>
                </div>
                <div className="staked-wallet">
                    {tokens.length > 0 ? tokens.map(({ earned, stakedAt, staked, id, image, dailyEarningRate, multiplierRate }, i) => <Token id={id} claim={() => new Promise((res, rej) => {
                        claim([id]).then(() => {
                            refreshBalance()
                            refreshEarnings()
                            res()
                        }).catch(rej)
                    })} unstake={() => new Promise((res, rej) => {
                        unstake([id]).then(() => {
                            refreshBalance()
                            refreshEarnings()
                            res()
                        }).catch(rej)
                    })} isListed={Boolean(listedTokens.find(a => a == id))} stakedAt={stakedAt} multiplier={multiplierRate} dailyEarningRate={dailyEarningRate} dailyEarnableAmount={dailyEarnableAmount} earned={earned} image={image} staked={staked} selected={selected.includes(id)} select={() => setSelected(a => [...a, id])} deselect={() => setSelected(a => [...a].filter(j => j !== id))} />) : <span className="align-text-center w100">You don't own any Sweepers</span>}
                </div>
            </div>
        </div>
    )
}