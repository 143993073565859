import { useEtherBalance } from '@usedapp/core';
import useLidoBalance from './useLidoBalance';
import { useCoingeckoPrice } from '@usedapp/coingecko';
import config from '../config';
import { ethers } from 'ethers';

/**
 * Computes treasury balance (ETH + Lido)
 *
 * @returns Total balance of treasury (ETH + Lido) as EthersBN
 */
export const useTreasuryBalance = () => {
  // const ethBalance = useEtherBalance(config.addresses.SweepersTreasuryExecutor);
  const ethBalance = useEtherBalance('0x811074e281d86f862dC84e7df80Ed38aA22aa23D');
  const lidoBalanceAsETH = useLidoBalance();
  return ethBalance;
};

/**
 * Computes treasury usd value of treasury assets (ETH + Lido) at current ETH-USD exchange rate
 *
 * @returns USD value of treasury assets (ETH + Lido) at current exchange rate
 */
export const useTreasuryUSDValue = () => {
  const etherPrice = Number(useCoingeckoPrice('ethereum', 'usd'));
  const treasuryBalanceETH = Number(
    ethers.utils.formatEther(useTreasuryBalance()?.toString() || '0'),
  );
  return etherPrice * treasuryBalanceETH;
};
