import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useAppContext } from '../../App'
import { processProposalDescriptionText } from '../../utils/processProposalDescriptionText';
import { Proposal } from '../../wrappers/SweepersTreasury';
import remarkBreaks from 'remark-breaks';
import { buildEtherscanAddressLink, buildEtherscanTxLink } from '../../utils/etherscan';
import { utils } from 'ethers';
import classes from './ProposalContent.module.css';
import Svgs from '../../pages/Vault/game-svgs'
import './index.css'
import { getOpenSeaProjectInfo } from '../../web3-hooks';

interface ProposalContentProps {
  proposal?: Proposal;
}

interface ButtonProps {
  className: any;
  children: any;
  onClick: any;
}

const linkIfAddress = (content: string) => {
  if (utils.isAddress(content)) {
    return (
      <a href={buildEtherscanAddressLink(content)} target="_blank" rel="noreferrer">
        {content}
      </a>
    );
  }
  return <span>{content}</span>;
};

const capitalize = (string: any) => !string ? '' : string.split(' ').map((string: any) => string.charAt(0).toUpperCase() + string.slice(1)).join(' ');

const transactionLink = (content: string) => {
  return (
    <a href={buildEtherscanTxLink(content)} target="_blank" rel="noreferrer">
      {content.substring(0, 7)}
    </a>
  );
};

const linkTo = (link: any) => {
  const a = document.createElement('a')
  a.href = link
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  a.className = 'hidden'
  document.body.appendChild(a)
  a.click()
  a.remove()
};

const { openSea, etherscan: etherscanLogo } = Svgs;

function Button({ className, children, onClick }: ButtonProps) {
  return <button className={`exchange-button ${className || ''}`} onClick={onClick}>{children}</button>
};

const ProposalContent: React.FC<ProposalContentProps> = props => {
  const { proposal } = props;
  const [projectInfo, setProjectInfo] = React.useState<any>(null);
  const [projectInfoLoading, setProjectInfoLoading] = React.useState<boolean>(false);
  useEffect(() => {
    if (!projectInfo && !projectInfoLoading && proposal?.openseaSlug) {
      setProjectInfoLoading(true);
      getOpenSeaProjectInfo(proposal.openseaSlug).then((info: any) => {
        setProjectInfo(info.collection);
        console.log('projectInfo', info)
      }).catch(e => {
        setProjectInfo(null)
      }).finally(() => setProjectInfoLoading(false));
    }
  })
  console.log(projectInfo)
  return (
    <>
      {projectInfoLoading ? <div className="column w100"><h3>Loading Project Info...</h3></div> : projectInfo && <div className="column w100 jfs afs gap-large marg-y-large">
        <h5>{projectInfo.name}</h5>
        <img src={projectInfo.banner_image_url} alt="banner" className="w100" />
        <span>{projectInfo.description}</span>
        <div className="b2 w100 gap wrap">
          <span className="nft-stat"><span className="strong">Supply:</span> {projectInfo.stats?.count || 0}</span>
          <span className="nft-stat"><span className="strong">Owners:</span> {projectInfo.stats?.num_owners || 0}</span>
          <span className="nft-stat"><span className="strong">Seven Day Volume:</span> {projectInfo.stats?.seven_day_volume?.toFixed(2) ?? 0} ETH</span>
          <span className="nft-stat"><span className="strong">Total Volume:</span> {(projectInfo.stats?.total_volume?.toFixed(2) ?? 0)} ETH</span>
          {/* <span className="nft-stat"><span className="strong">One Day Change:</span> {projectInfo.stats?.one_day_change} ETH</span> */}
          <span className="nft-stat"><span className="strong">Floor Price:</span> {projectInfo.stats?.floor_price} ETH</span>
        </div>
      </div>}
      <Row>
        <Col className={classes.section}>
          <h5>Description</h5>
          {proposal?.description && (
            <ReactMarkdown
              className={classes.markdown}
              children={processProposalDescriptionText(proposal.description, proposal.title)}
              remarkPlugins={[remarkBreaks]}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col className={classes.section}>
          <h5>Project Info</h5>
          <div className="row jc ac">
            <Button className="sea-button" onClick={() => linkTo(`https://opensea.io/collection/${proposal?.openseaSlug}`)}>
              <div className="sea-icon">{openSea}</div>
              <span>View on OpenSea</span>
            </Button>
            <Button className="sea-button" onClick={() => linkTo(`https://etherscan.io/token/${proposal?.project}`)}>
              <div className="sea-icon">{etherscanLogo}</div>
              <span>View on Etherscan</span>
            </Button>
            {projectInfo?.external_url && <Button className="sea-button" onClick={() => linkTo(projectInfo.external_url)}>
              <span>Visit Project's Website</span>
            </Button>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={classes.section}>
          <h5>Proposed Sweep</h5>
          <ol>
            {proposal?.details?.map((d, i) => {
              return (
                <li key={i} className="m-0">
                  {/* {linkIfAddress(d.target)}.{d.functionSig} */}
                  {d.value}
                  <br />
                  {d.callData.split(',').map((content, i) => {
                    return (
                      <Fragment key={i}>
                        <span key={i}>
                          &emsp;
                          {linkIfAddress(content)}
                          {d.callData.split(',').length - 1 === i ? '' : ','}
                          &nbsp;
                          Sweep of {capitalize(proposal.openseaSlug)}
                        </span>
                        <br />
                      </Fragment>
                    );
                  })}

                </li>
              );
            })}
          </ol>
        </Col>
      </Row>
      <Row>
        <Col className={classes.section}>
          <h5>Proposer</h5>
          {proposal?.proposer && proposal?.transactionHash && (
            <>
              {linkIfAddress(proposal.proposer)} at {transactionLink(proposal.transactionHash)}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProposalContent;
