import { BigNumber } from '@ethersproject/bignumber';
import { useAppSelector } from '../hooks';
import { generateEmptyFounderAuction, isFounderSweeper, isSweepersTreasurySweeper } from '../utils/founderSweeper';
import { Bid, BidEvent } from '../utils/types';
import { Auction } from './sweepersAuction';

const deserializeAuction = (reduxSafeAuction?: Auction): Auction => {
  return {
    amount: reduxSafeAuction ? BigNumber.from(reduxSafeAuction.amount) : BigNumber.from(0),
    bidder: reduxSafeAuction?.bidder || '',
    startTime: reduxSafeAuction ? BigNumber.from(reduxSafeAuction.startTime) : BigNumber.from(0),
    endTime: reduxSafeAuction ? BigNumber.from(reduxSafeAuction.endTime) : BigNumber.from(0),
    sweeperId: reduxSafeAuction ? BigNumber.from(reduxSafeAuction.sweeperId) : BigNumber.from(0),
    settled: reduxSafeAuction ? Boolean(reduxSafeAuction.settled) : false,
    sniped: reduxSafeAuction ? Boolean(reduxSafeAuction.sniped) : false,
    snipedAmount: reduxSafeAuction ? BigNumber.from(reduxSafeAuction.snipedAmount || '0') : BigNumber.from(0),
    snipedBy: reduxSafeAuction?.snipedBy || '',
  };
};

const deserializeBid = (reduxSafeBid: BidEvent): Bid => {
  return {
    sweeperId: BigNumber.from(reduxSafeBid.sweeperId),
    sender: reduxSafeBid.sender,
    value: BigNumber.from(reduxSafeBid.value),
    extended: reduxSafeBid.extended,
    transactionHash: reduxSafeBid.transactionHash,
    timestamp: BigNumber.from(reduxSafeBid.timestamp),
  };
};
const deserializeBids = (reduxSafeBids: BidEvent[]): Bid[] => {
  return reduxSafeBids
    .map(bid => deserializeBid(bid))
    .sort((a: Bid, b: Bid) => {
      return b.timestamp.toNumber() - a.timestamp.toNumber();
    });
};

const useOnDisplayAuction = (initialAuctionId: number | undefined): Auction | undefined => {
  const lastAuctionSweeperId = useAppSelector(state => state.auction.activeAuction?.sweeperId);
  const onDisplayAuctionSweeperId = useAppSelector(
    state => state.onDisplayAuction.onDisplayAuctionSweeperId,
  );
  const currentAuction = useAppSelector(state => state.auction.activeAuction);
  const pastAuctions = useAppSelector(state => state.pastAuctions.pastAuctions);

  if (
    onDisplayAuctionSweeperId === undefined ||
    lastAuctionSweeperId === undefined ||
    currentAuction === undefined ||
    !pastAuctions
  )
    return undefined;

  // current auction
  if (BigNumber.from(onDisplayAuctionSweeperId).eq(lastAuctionSweeperId)) {
    return deserializeAuction(currentAuction);
  } else {
    // past auction
    const reduxSafeAuction: Auction | undefined = pastAuctions.find(auction => {
      const sweeperId = auction.activeAuction && BigNumber.from(auction.activeAuction.sweeperId);
      return initialAuctionId ? sweeperId?.toNumber() === initialAuctionId : sweeperId && sweeperId.toNumber() === onDisplayAuctionSweeperId;
    })?.activeAuction;
    return reduxSafeAuction ? deserializeAuction(reduxSafeAuction) : undefined;
  }
};
export const usePastAuctions = (): Auction[] => {
  const pastAuctions = useAppSelector(state => state.pastAuctions.pastAuctions);
  return pastAuctions.map(u => deserializeAuction(u.activeAuction));
};

export const useAuctionBids = (auctionSweeperId: BigNumber): Bid[] | undefined => {
  const lastAuctionSweeperId = useAppSelector(state => state.onDisplayAuction.lastAuctionSweeperId);
  const lastAuctionBids = useAppSelector(state => state.auction.bids);
  const pastAuctions = useAppSelector(state => state.pastAuctions.pastAuctions);

  // auction requested is active auction
  if (lastAuctionSweeperId === auctionSweeperId.toNumber()) {
    return deserializeBids(lastAuctionBids);
  } else {
    // find bids for past auction requested
    const bidEvents: BidEvent[] | undefined = pastAuctions.find(auction => {
      const sweeperId = auction.activeAuction && BigNumber.from(auction.activeAuction.sweeperId);
      return sweeperId && sweeperId.eq(auctionSweeperId);
    })?.bids;

    return bidEvents && deserializeBids(bidEvents);
  }
};

export default useOnDisplayAuction;
