import classes from './Banner.module.css';
import Section from '../../layout/Section';
import Image from 'react-bootstrap/Image';
import bannerImage from '../../assets/video-thumbnail.jpg';
import sample1 from '../../assets/sample-sweepers/1.png'
import sample2 from '../../assets/sample-sweepers/2.png'
import sample3 from '../../assets/sample-sweepers/3.png'
import sample4 from '../../assets/sample-sweepers/4.png'
import sample5 from '../../assets/sample-sweepers/5.png'
import sample6 from '../../assets/sample-sweepers/6.png'
import { usePastAuctions } from '../../wrappers/onDisplayAuction';
import { StandaloneSweeperWithSeed } from '../StandaloneSweeper';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { toTimeLength } from '../../pages/Garage';
import { useAppContext } from '../../App';
export const PastAuctions = (props: any) => {
  const { currentAuction } = props
  const pastAuctions = usePastAuctions()
  const auctionIndex = useMemo(() => pastAuctions?.findIndex(a => a?.sweeperId?.toNumber() === currentAuction) || 0, [pastAuctions, currentAuction])
  return (<div className="image-bar">
    {pastAuctions.filter((auction, index) => index > auctionIndex && index !== 0 && index !== auctionIndex && index < (auctionIndex + 13)).map((auction, index) => (<div key={index} className="image">
      <StandaloneSweeperWithSeed
        shouldShowBanner={false}
        sweeperId={auction?.sweeperId || BigNumber.from(0)}
        onLoadSeed={() => { return null }}
        shouldLinkToProfile={true}
      />
    </div>))}
  </div>)
}
const Banner = (props: any) => {
  const { currentAuction } = props
  const appContext: any = useAppContext()
  const {duration} = appContext
  return (
    <div className="column w100 jfs pad-large">
      <div id="banner-text-contain" className="b2 w100">
        <h1 id="banner-text-main">
          One Sweeper,
          <br />
          Every {toTimeLength(duration, true, false, true)}
        </h1>
        <PastAuctions currentAuction={currentAuction} />
      </div>
      <div className="column" style={{ padding: '2rem', paddingTop: '4rem', paddingBottom: '2rem' }}>
        <video controls style={{maxWidth: 'min(100%, 1200px)', background: 'black', borderRadius: '.3rem'}} poster={bannerImage} src={`/sweepers.mp4`} className="video" />
        {/* <Image  src={bannerImage} alt={'Banner Image'} fluid /> */}
      </div>
    </div>
  );
};

export default Banner;
