import React, { useState, useEffect, useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import NightSky from '../../assets/main-img.png'
import './index.css'
import './games.css'
import './vault.css'
import '../Exchange/exchange.css'
import { useAppContext } from '../../App'
import BigNumber from 'bignumber.js'
import Svgs from './game-svgs'
import { toTopia, useVaultContract, useGameApproval, VAULT, getOpenSeaProjectInfo, getTokenURI, multicall, VaultABI, VaultABI2, VAULT2, useRaffleContract, RAFFLE, RAFFLEABI, NEWRAFFLE } from '../../web3-hooks'
import DOMPurify from 'dompurify'
import { marked } from 'marked'
import useSweepersBalance from '../../hooks/useSweepersBalance';
import UnicornPool from '../../assets/vault-bg.png'
import HeroHead from './hero-head'
import CopyIcon from './copy-icon'
import usePagination from './paginator'
import ShortAddress from '../../components/ShortAddress'
import { ethers } from 'ethers'
import { Link } from 'react-router-dom'
import DustBanner from '../../components/DustBanner'
BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
    FORMAT: {
        prefix: '',
        decimalSeparator: '.',
        groupSeparator: '',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: '',
        fractionGroupSize: 0,
        suffix: ''

    }
})

export function Paralax({ id, children, className, image, style = {} }) {
    return (<div id={id} className={`paralax ${className || ''}`} style={style}>
        {children}
        <div className="paralax-image" style={{ backgroundImage: `url(${image})` }}></div>
    </div>)
}
const { openSea, shield, crown, auctionIcon, competitionIcon, checkMark, linkExternal: linkExternalIcon, greenCheck } = Svgs
const DECIMALS = 6
const capitalize = string => !string ? '' : string.split(' ').map(string => string.charAt(0).toUpperCase() + string.slice(1)).join(' ')
const PERPAGE = 10

const { grid: gridIcon, table: tableIcon, refresh: refreshIcon, carrot, eyeBall } = Svgs
const shortAddress = (address = '') => {
    if (address.length < 10) return address
    return `${address.slice(0, 6)}...${address.slice(-4)}`
}
function Container({ id, className, children, style = {} }) {
    return <div id={id} style={style} className={`exchange-container ${className || ''}`}>{children}</div>
}
function Button({ id, className, children, onClick, alt, style = {}, disabled = false }) {
    return <button id={id} style={style} disabled={disabled} className={`exchange-button ${alt === 2 ? 'alt-2' : alt ? 'alt' : ''} ${className || ''}`} onClick={onClick}>{children}</button>
}
window.toHTML = md => DOMPurify.sanitize(marked.parse(md))
const TimeSpan = React.memo(({ endTime: time, updateFrequency = 1000 }) => {
    const [remaining, setRemaining] = useState((time || new Date().getTime()) - new Date().getTime())
    useLayoutEffect(() => {
        setRemaining((time || new Date().getTime()) - new Date().getTime())
        const interval = setInterval(() => {
            setRemaining(t => t - updateFrequency)
        }, updateFrequency)
        return () => clearInterval(interval)
    }, [time])
    let days = useMemo(() => Math.floor(remaining / (1000 * 60 * 60 * 24)), [remaining])
    let hours = useMemo(() => Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), [remaining])
    let minutes = useMemo(() => Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60)), [remaining])
    let seconds = useMemo(() => Math.floor((remaining % (1000 * 60)) / 1000), [remaining])
    return <>
        {days > 0 || minutes > 0 || hours > 0 || seconds > 0 ? <>
            {days > 0 && `${days}D`}{hours > 0 && `${days ? '-' : ''}${hours}H`}{minutes > 0 && `${hours || days ? '-' : ''}${minutes}M`}{seconds > 0 && `${minutes || hours || days ? '-' : ''}${seconds}S`}
        </> : <>Ended</>}
    </>
})
const HurryUp = React.memo(({ endTime, timeBuffer, timeBufferThreshold }) => {
    const [remaining, setRemaining] = useState((endTime || new Date().getTime()) - new Date().getTime())
    useLayoutEffect(() => {
        setRemaining((endTime || new Date().getTime()) - new Date().getTime())
        const interval = setInterval(() => {
            setRemaining(t => t - 1000)
        }, 1000)
        return () => clearInterval(interval)
    }, [endTime])
    if (remaining < 0) return null
    return <span className='hurry-up'>
        {(remaining && remaining < timeBufferThreshold) ? `Auction ends soon, bidding now will increase the time by: ${Math.floor(timeBuffer / 1000)}s` : (remaining > 0 && false) ? `Hurry Up! Ends in ${toTimeLength(remaining)}` : ''}
    </span>
})
const toDateValue = date => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}
const toTimeLength = msTimestamp => {
    let seconds = Math.floor(msTimestamp / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)
    return `${days > 0 ? `${days}D ` : ''}${hours % 24 > 0 ? `${hours % 24}H ` : ''}${minutes % 60 > 0 ? `${minutes % 60}M ` : ''}${seconds % 60 > 0 ? `${seconds % 60}S` : ''}`.trim()
}
const linkTo = link => {
    let a = document.createElement('a')
    a.href = link
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    a.className = 'hidden'
    document.body.appendChild(a)
    a.click()
    a.remove()
}
const sortKey = 'VAULT_SORT_MODE'
const displayKey = 'VAULT_DISPLAY_MODE'
function DisplayedListing({ listing, display = 'grid', floorInETH }) {
    const { redirect, ethPrice, width } = useAppContext()
    if (!listing) return null
    const { tokenId, image, startTime, endTime, bidCount, currentBid, startingPrice, status, blind, id, current, auction, entryCount, entryCap, entryLimit, entryCost, entryPriceETH, entryPriceDust, projectInfo } = listing
    const floorPrice = useMemo(() => {
        if (projectInfo) return projectInfo.floorPrice
        return 0
    }, [projectInfo])
    const isCurrentComp = useMemo(() => (listing.current && !listing.auction), [listing])
    const hasStarted = useMemo(() => startTime < new Date().getTime(), [startTime])
    const hasEnded = useMemo(() => listing.status === 'active' && (!isCurrentComp || listing.competitionType === 'time') ? endTime < new Date().getTime() : listing.competitionType === 'entry' ? entryCount >= entryCap : listing.ethCollected?.isGreaterThanOrEqualTo(listing.maxEth) ?? false, [endTime, entryCount, entryCap, listing])
    const filledPercentage = useMemo(() => !isCurrentComp ? '0' : hasEnded ? '100' : listing.competitionType === 'entry' ? (entryCount / entryCap * 100).toFixed(2) : listing.ethCollected.dividedBy(listing.maxEth).multipliedBy(100).toFixed(2), [isCurrentComp, hasEnded, listing, entryCount, entryCap])
    const maxChars = useMemo(() => width < 560 ? 10 : 16, [width])
    let InnerContent = () => <>
        {!hasEnded && status === 'active' && (!isCurrentComp || listing.competitionType === 'time') && <span className={`listing-timer ${endTime - new Date() < 60000 * 60 ? 'ends-soon' : ''}`}>{hasStarted ? 'Ends' : 'Starts'}: <TimeSpan endTime={!hasStarted ? startTime : endTime} /></span>}
        {isCurrentComp && !hasEnded && listing.competitionType === 'entry' && <div className="listing-percent-container"><div className='listing-percent' style={{ width: `${filledPercentage}%` }} /><div className='b2 jsb w100'><span>{entryCount}</span><span>{entryCap}</span></div></div>}
        {isCurrentComp && !hasEnded && listing.competitionType === 'eth' && <div className="listing-percent-container"><div className='listing-percent' style={{ width: `${filledPercentage}%` }} /><div className="b2 w100 jsb"><span>{parseFloat(filledPercentage)}%</span></div></div>}
        <div className="listing-item-content">
            <h5 className="listing-item-title b2 jfs align-text-left wrap afe marg-y w100 gap-small">{projectInfo?.name ? (() => {
                if (projectInfo.name.length > maxChars) return projectInfo.name.slice(0, maxChars) + '...'
                return projectInfo.name
            })() : 'Undefined'}
                <div style={{ maxWidth: '.9rem', width: '.9rem' }}>{shield}</div>
            </h5>
            <span className="w100" style={{ fontSize: '.9rem', fontWeight: '500' }}>{blind ? 'Mystery Auction' : `#${shortAddress(tokenId)}`}</span>
            {auction ?
                <>
                    <div className="listing-details">
                        <div className="heading">Floor Price:</div>
                        <span className="display-listing-status subheading price">{floorInETH ? `${floorPrice} ETH` : `$${((floorPrice || 0) * (ethPrice || 0)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span>
                    </div>
                    <div className="listing-details">
                        <div className="heading">{status !== 'active' ? 'Winning' : currentBid.isEqualTo(0) ? 'Starting' : 'Current'} Bid:</div>
                        <span className="display-listing-status subheading">{parseFloat(toTopia(currentBid.isEqualTo(0) ? startingPrice : currentBid))} $DUST</span>
                    </div>
                    <div className="listing-details">
                        <span className="heading">Bids:</span>
                        <span className="subheading">{bidCount}</span>
                    </div>
                    {/*                     <div className="listing-details">
                        <div className="heading">Status:</div>
                        <span className={`display-listing-status subheading ${status === 'active' ? 'green' : 'red'}`}>{capitalize(status)}</span>
                    </div> */}
                </>
                : <>
                    <div className="listing-details">
                        <div className="heading">Floor Price:</div>
                        <span className="display-listing-status subheading price">{floorInETH ? `${floorPrice} ETH` : `$${((floorPrice || 0) * (ethPrice || 0)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span>
                    </div>
                    <div className="listing-details">
                        <span className="heading">Cost:</span>
                        <span className="subheading small-font">{!listing.onlyEth && `${parseFloat(toTopia(entryPriceDust.plus(entryCost || '0'), 4)).toLocaleString()} $DUST`}{!listing.onlyDust && !listing.onlyEth && ' | '}{!listing.onlyDust && `${parseFloat(toTopia(entryPriceETH.plus(entryCost || '0'), 4)).toLocaleString()} ETH`}</span>
                    </div>
                    <div className="listing-details">
                        <span className="heading">Entries:</span>
                        <span className="subheading">{entryCount}</span>
                    </div>
                    {/*                     <div className="listing-details">
                        <div className="heading">Status:</div>
                        <span className={`display-listing-status subheading ${status === 'active' ? 'green' : 'red'}`}>{capitalize(status)}</span>
                    </div> */}
                </>}
        </div>
    </>
    const ListingImage = useMemo(() => <div className="listing-image">
        <div className="auction-denoter">
            <div style={{ width: '1.2rem', maxWidth: '1.2rem' }}>{auction ? auctionIcon : competitionIcon}</div>
        </div>
        {image ? <img onClick={() => redirect(`/vault?auction=${id}&current=${current}&competition=${!auction}`)} className="pointer nft-image" src={image} alt={`token ${tokenId}`}></img> : <span>No Image</span>}
    </div>, [image, tokenId, auction, id, current])
    if (display === 'table') return (<Container className="listing">
        <ListingImage />
        <div className="listing-info column w100">
            <InnerContent />
        </div>
        <Button alt className="borderless-button w100" style={{ maxWidth: '90%' }} onClick={() => redirect(`/vault?auction=${id}&current=${current}&competition=${!auction}`)}>View</Button>
    </Container>)
    return (<div className="listing">
        {ListingImage}
        <div className="listing-info column w100"><InnerContent /></div>
        <Button alt={2} className="borderless-button w100" style={{ maxWidth: '90%', alignSelf: 'center', justifySelf: 'flex-end' }} onClick={() => redirect(`/vault?auction=${id}&current=${current}&competition=${!auction}`)}>View</Button>
    </div>)
}
function ListingGroup({ listings, projectInfo, display }) {
    if (listings?.length < 1) return <span>No auctions for {projectInfo?.name || 'undefined'}</span>
    let { auctions, competitions } = listings.reduce((acc, listing) => {
        if (listing.auction) acc.auctions.push(listing)
        else acc.competitions.push(listing)
        return acc
    }, { auctions: [], competitions: [] })
    return <div className='listing-group'>
        <div className='listing-group-header column'>
            <span>{projectInfo?.name || 'undefined'}</span>
            <span>({((auctions.length ? `${auctions.length} Auction${auctions.length === 1 ? '' : 's'}` : '') + (auctions.length && competitions.length ? ' & ' : ' ') + (competitions.length ? `${competitions.length} Competition${competitions.length === 1 ? '' : 's'}` : '')).trim()})</span>
        </div>
        <div className={`listing-group-listings ${display === 'table' ? 'table-mode' : ''}`}>
            {listings.map((listing, i) => <DisplayedListing display={display} key={i} listing={listing} />)}
        </div>
    </div>
}
function EntryOption({ max, numberOfEntries, entryBonus, amount, approved, entering, enter, enteringWith, entryId }) {
    const { balance, ethBalance } = useAppContext()
    return <div className={`entry-option ${max ? 'max' : ''}`}>
        <div className="b2 afe gap" style={{ maxWidth: '80%', 'width': 'fit-content' }}>
            <div className="b2 gap" style={{ flexGrow: 0, flexShrink: 2 }}>
                {entryBonus > 0 && <h5 className="faded-out" style={{ textDecoration: 'line-through', transform: 'rotate(-10deg)' }}>{numberOfEntries - entryBonus}</h5>}
                <h2>{numberOfEntries}</h2>
            </div>
            <h5 className="faded-out">Entr{numberOfEntries === 1 ? 'y' : 'ies'}</h5>
        </div>
        <h5 className={!entryBonus ? "faded-out" : "red-text"}>{!entryBonus ? 'NO BONUS' : `Includes ${entryBonus} Free Entries`}</h5>
        <Button onClick={() => enter(entryId)} disabled={amount?.isGreaterThan((enteringWith === '$DUST' ? balance : ethBalance).toString()) || entering || !approved} className="competition-entry-button">{entering ? 'Entering...' : <>{parseFloat(toTopia(amount, 3))} {enteringWith}</>}</Button>
    </div>
}
function CompetitionActivity({ address, txHash, time, entries }) {
    const [currentTime, setCurrentTime] = useState(Date.now())
    const timeValue = useMemo(() => toTimeLength(currentTime - time), [time, currentTime])
    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(Date.now()), 1000)
        return () => clearInterval(interval)
    }, [])
    return <div className="competition-entry gap jsb w100">
        <div className="column w100 afs">
            <label>ADDRESS</label>
            <h5><ShortAddress address={address} /></h5>
        </div>
        <div className="column w100 afs">
            <label>ENTRIES</label>
            <h5>{entries}</h5>
        </div>
        <div className="column w100 afe">
            <a href={`https://etherscan.io/tx/${txHash || ''}`} className="b2 jfe gap" target="_blank" rel="noreferrer">{timeValue} <div style={{ width: '1.2rem', maxWidth: '1.2rem' }}>{linkExternalIcon}</div></a>
        </div>
    </div>
}
function Auction({ showListing, similarListings, vaultInfo, canBid, onNewOffer, onSettled, floorInETH }) {
    const { contract: newContract, createBid: newCreateBid, getBidsByAuctionId: newGetBidsByAuctionId, settleAuction: newSettleAuction, getBidInfoByIndex: newGetBidInfoByIndex, addEvent: newAddEvent } = useVaultContract()
    const { contract: oldContract, createBid: oldCreateBid, getBidsByAuctionId: oldGetBidsByAuctionId, settleAuction: oldSettleAuction, getBidInfoByIndex: oldGetBidInfoByIndex, addEvent: oldAddEvent } = useVaultContract(true)
    const isOld = useMemo(() => !showListing.current, [showListing])
    const { contract, createBid, getBidsByAuctionId, settleAuction, getBidInfoByIndex, addEvent } = useMemo(() => ({ contract: isOld ? oldContract : newContract, createBid: isOld ? oldCreateBid : newCreateBid, getBidsByAuctionId: isOld ? oldGetBidsByAuctionId : newGetBidsByAuctionId, getBidInfoByIndex: isOld ? oldGetBidInfoByIndex : newGetBidInfoByIndex, addEvent: isOld ? oldAddEvent : newAddEvent, settleAuction: isOld ? oldSettleAuction : newSettleAuction }), [isOld, oldContract, newContract, oldCreateBid, newCreateBid, oldGetBidsByAuctionId, newGetBidsByAuctionId, oldGetBidInfoByIndex, newGetBidInfoByIndex, oldAddEvent, newAddEvent, oldSettleAuction, newSettleAuction])
    const { flash, balance, redirect, width, ethPrice } = useAppContext()
    const { contractAddress, abi } = useMemo(() => ({ contractAddress: isOld ? VAULT : VAULT2, abi: isOld ? VaultABI : VaultABI2 }), [isOld])
    const { approved, approveTopia, approving, checkingApproval, refresh: refreshApproval } = useGameApproval(isOld ? VAULT : VAULT2)
    const [offers, setOffers] = useState([])
    const [showMore, setShowMore] = useState(false)
    const [gotOffers, setGotOffers] = useState(false)
    const [refreshOffersCount, setRefreshOffersCount] = useState(0)
    const [bidAmount, setBidAmount] = useState(0)
    const [loadingOffers, setLoadingOffers] = useState(false)
    const [creatingOffer, setCreatingOffer] = useState(false)
    const [settlingAuction, setSettlingAuction] = useState(false)
    const refreshOffers = useCallback(() => setRefreshOffersCount(a => a + 1), [setRefreshOffersCount])
    useMemo(() => {
        if (!contract) return
        const manageBidPlaced = () => addEvent({
            name: 'BidPlaced',
            callback: async ([bidId, auction, address, amount]) => {
                if (auction === showListing.id && !offers.find(o => o.id === bidId)) {
                    console.log('BidPlaced', bidId, auction, address, amount)
                    let offer = await getBidInfoByIndex(bidId).catch(e => console.error(e))
                    if (offer) {
                        /* TODO, update the last bids status, Does the listing update itself??? */
                        let newOffer = {
                            id: bidId,
                            bidder: offer._bidder,
                            amount: new BigNumber(offer._bidAmount.toString()),
                            status: offer._bidStatus
                        }
                        if (typeof onNewOffer === 'function') onNewOffer(newOffer)
                        setOffers(a => [newOffer, ...a].map(u => {
                            if (u.id === bidId) return newOffer
                            return u
                        }).reduce((a, b) => {
                            if (!a.find(u => u.id === b.id)) a.push(b)
                            return a
                        }, []))
                    }
                }
                manageBidPlaced()
            }
        })
        manageBidPlaced()
    }, [contract])
    useEffect(() => {
        if (contract && showListing && !gotOffers && !loadingOffers) {
            console.log('loading offers')
            setLoadingOffers(true)
            getBidsByAuctionId(showListing.id).then(async bidIds => {
                if (!bidIds?.length) {
                    setOffers([])
                    setGotOffers(true)
                }
                let bids = await multicall(contractAddress, abi, 'getBidInfoByIndex', bidIds.map(id => [id])).then(r => {
                    return r.map((bid, i) => {
                        return {
                            id: bidIds[i],
                            bidder: bid._bidder,
                            amount: new BigNumber(bid._bidAmount.toString()),
                            status: bid._bidStatus
                        }
                    })
                }).catch(e => { console.error(e); return [] })
                setOffers(bids.reverse())
                setGotOffers(true)
            }).catch(e => {
                console.error(e)
            }).finally(() => setLoadingOffers(false))
        } else if (!contract && offers.length) {
            setOffers([])
        }
    }, [gotOffers, showListing, contract, refreshApproval])
    useEffect(() => {
        if (gotOffers) { console.log('refresh offers'); setGotOffers(false) }
    }, [refreshOffersCount, showListing])
    const projectInfo = useMemo(() => showListing?.projectInfo || {}, [showListing])
    const currentBid = useMemo(() => showListing?.activeBidId ? showListing.currentBid : showListing?.startingPrice || new BigNumber(0), [showListing])
    const nextBidGreaterThan = useMemo(() => {
        let next = currentBid.plus(currentBid.multipliedBy(vaultInfo.minBidIncrementPercentage / 10000))
        /* 
        let div = next.dividedBy(10 ** 18)
                if (div.isGreaterThan(bidAmount)) {
                    setBidAmount(div.toString())
                } */
        return next
    }, [currentBid, vaultInfo])
    const hasNotStarted = useMemo(() => new Date().getTime() - showListing.startTime < 0, [showListing])
    const SettleAuctionButton = useMemo(() => (<Button onClick={() => {
        if (settlingAuction) return
        setSettlingAuction(true)
        settleAuction(showListing.id).then(([AuctionId, NFTProjectAddress, tokenID, buyer, finalAmount]) => {
            if (typeof onSettled === 'function') onSettled(AuctionId, NFTProjectAddress, tokenID, buyer, finalAmount)
        }).catch(e => {
            console.error(e)
            flash('Failed to settle auction')
        }).finally(() => setSettlingAuction(false))
    }}>{settlingAuction ? 'Settling...' : showListing.currentBid.isEqualTo(0) ? 'Fail Auction' : 'Settle Auction'}</Button>), [showListing, settlingAuction, settleAuction, onSettled, setSettlingAuction, flash])
    const CreateOfferButton = useMemo(() => (<Button alt={2} className="borderless-button" disabled={creatingOffer || checkingApproval || approving} onClick={approved && !approved?.topia ? approveTopia : () => {
        if (creatingOffer) return
        setCreatingOffer(true)
        const bigBidAmount = new BigNumber(bidAmount).times(10 ** 18)
        createBid(showListing.id, bigBidAmount.toString(), vaultInfo.devFee?.toString()).catch(e => {
            console.error(e)
            flash('Failed to create bid')
        }).finally(() => setCreatingOffer(false))
    }}>{checkingApproval ? 'Checking Approval...' : approving ? 'Approving...' : !approved?.topia ? 'Approve' : creatingOffer ? "Placing..." : 'Place Bid'}</Button>
    ), [approving, approved, checkingApproval, refreshApproval, showListing, createBid, getBidInfoByIndex, onNewOffer, offers, setOffers, creatingOffer, setCreatingOffer, flash])
    const listingInfo = useMemo(() => <>
        <Container style={{ marginTop: '1rem' }} className="column afs">
            <h3>{showListing.projectInfo?.name || 'NFT'} Listing Details</h3>
            {showListing.projectInfo?.bannerImage && <img className="listing-image" src={showListing.projectInfo?.bannerImage} />}
            <div className="listing-details">
                <span className="heading">Contract Address</span>
                <span className="subheading">{<ShortAddress address={showListing.nftContract} />}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Token ID</span>
                <span className="subheading">{showListing.blind ? '?' : shortAddress(showListing.tokenId)}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Number Minted</span>
                <span className="subheading">{showListing.projectInfo?.count}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Total Supply</span>
                <span className="subheading">{showListing.projectInfo?.totalSupply || 0}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Collection Floor Price</span>
                <span className="subheading">{showListing.projectInfo?.floorPrice} ETH</span>
            </div>
            <div className="listing-details">
                <span className="heading">Start Time</span>
                <span className="subheading">{new Date(showListing.startTime).toLocaleString()}</span>
            </div>
            <div className="listing-details">
                <span className="heading">End Time</span>
                <span className="subheading">{new Date(showListing.endTime).toLocaleString()}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Starting Bid</span>
                <span className="subheading">{parseFloat(toTopia(showListing.startingPrice))} $DUST</span>
            </div>
        </Container>
        <div className="column w100" style={{ marginTop: '.4rem' }}>
            {!showListing.blind ? <>
                <Button className="sea-button" onClick={() => linkTo(`https://opensea.io/assets/${showListing.nftContract}/${showListing.tokenId}`)}>
                    <div className="sea-icon">{openSea}</div>
                    <span>View on OpenSea</span>
                </Button>
                <Button className="sea-button" onClick={() => linkTo(`${projectInfo?.website}`)}>
                    <span>Project Website</span>
                </Button>
            </> :
                <>
                    <Button className="sea-button" onClick={() => linkTo(`https://opensea.io/collection/${showListing.slug}`)}>
                        <div className="sea-icon">{openSea}</div>
                        <span>View on OpenSea</span>
                    </Button>
                    <Button className="sea-button" onClick={() => linkTo(`${projectInfo?.website}`)}>
                        <span>Project Website</span>
                    </Button>
                </>}
        </div>
    </>, [showListing])
    return (<div className="column w100 afs jfs">
        <Button alt className="borderless-button" onClick={() => redirect('/vault')}>Return to All Listings</Button>
        <div className="listing-main">
            <div className="left-side column jfs">
                <img className="listing-image" src={showListing.image} />
                {width > 1080 && listingInfo}
            </div>
            <div className="right-side column w100">
                <div className="column w100 listing-inforce">
                    <h1 style={{ fontSize: '4rem' }} className='w100 b2 wrap jfs gap-large'>{showListing.blind ? `Mystery ${projectInfo?.name || 'Token'}` : projectInfo?.name || 'Token'} {!showListing.blind && <span style={{ fontSize: '1.8rem' }} className="faded-out">#{showListing.tokenId}</span>} <div style={{ maxWidth: '1.1rem', width: '1.1rem', transform: 'translateY(-.8rem)' }}>{shield}</div></h1>
                    <div className="w100 b2 wrap afs jfs gap-very-large marg-y-large" style={{ borderBottom: '.1rem solid var(--gray)', marginBottom: '1rem', paddingBottom: 'var(--large)' }}>
                        <div className="column afs pad">
                            <label className=''>Floor Price</label>
                            <h3 style={{ fontStyle: 'italic' }}>{projectInfo?.floorPrice ? `${projectInfo.floorPrice} ETH` : 'N/A'}</h3>
                            <span className="">${((projectInfo?.floorPrice || 0) * ethPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </div>
                        <div className="list-head-column column jfs afs" style={{ height: '100%' }}>
                            <label className="">{hasNotStarted ? 'Starts' : 'Ends'} In</label>
                            <span className="time-span"><TimeSpan endTime={hasNotStarted ? showListing.startTime : showListing.endTime} /></span>
                        </div>
                    </div>
                    {showListing.status === 'settled' ? <div className="list-head-column column jfs afs w100" style={{ height: '100%' }}>
                        <label>Winning Bid</label>
                        <h3>{parseFloat(toTopia(showListing.currentBid))} $DUST</h3>
                    </div> : hasNotStarted ? <div className="column jfs afs w100" >
                        <h2>This auction has not yet started.</h2>
                        <label>Starting Bid</label>
                        <h3>{parseFloat(toTopia(showListing.startingPrice))} $DUST</h3>
                    </div> : (showListing.status === 'active' && new Date().getTime() < showListing.endTime) ? <>
                        <div className="listing-form">
                            {(showListing.status === 'active' && !canBid) ? <div className="column w100">
                                <h4>You must own a Metatopian to participate in Auctions.</h4>
                            </div> : showListing.status === 'active' && <div className="column w100 afs">
                                <label className="" style={{ fontWeight: '400' }}>{showListing.currentBid.isGreaterThan(0) ? 'Current' : 'Starting'} Bid</label>
                                <h3>{showListing.currentBid.isGreaterThan(0) ? parseFloat(toTopia(showListing.currentBid)) : parseFloat(toTopia(showListing.startingPrice))} $DUST</h3>
                                <HurryUp endTime={showListing.endTime} timeBuffer={vaultInfo.timeBuffer} timeBufferThreshold={vaultInfo.timeBufferThreshold} />
                                <div className="bid-input w100">
                                    <div className="input-wrapper w100">
                                        <span className="append" style={{ fontSize: '1.2rem', width: '6rem', maxWidth: '6rem', paddingRight: '.5rem' }}>$DUST</span>
                                        <input style={{ paddingRight: '6rem', textAlign: 'left' }} placeholder="Bid Amount" type="number" value={bidAmount} onChange={e => setBidAmount(parseFloat(e.target.value))} />
                                    </div>
                                    {CreateOfferButton}
                                </div>
                                <div className="b2 w100 jfs gap wrap">
                                <span className="b2 gap jfs afs" style={{ width: 'auto', marginBottom: '.5rem' }}>Next bid must be at least: <span style={{ fontWeight: '800', fontStyle: 'italic' }}>{parseFloat(toTopia(nextBidGreaterThan)).toLocaleString()} $DUST</span></span>
                                {(balance.lte(0) || balance?.lt(nextBidGreaterThan.toString())) && <Button onClick={() => redirect('/exchange')}>Get More $DUST</Button>}
                                </div>
                            </div>}
                        </div>
                    </> : showListing.status !== 'settled' ? showListing.blind && showListing.currentBid?.isGreaterThan(0) ? <h4>Waiting on Token ID to be updated</h4> : <div>{SettleAuctionButton}</div> : <div className="column w100 afs jfs">
                        <h3>Auction Settled was for {parseFloat(toTopia(showListing.currentBid))} $DUST</h3>
                    </div>}
                </div>
                <div className="show-offers">
                    <div className="b2 w100 jsb afe marg-y">
                        <label className=''>All Bids:</label>
                        <div className="refresh-icon" onClick={() => refreshOffers()}>{refreshIcon}</div>
                    </div>
                    <div className="listing-offers">
                        {offers?.length ? offers.filter((a, i) => showMore || i < 3).map(({ id, bidder, amount, status }) => (<Container key={id} className="user-bid">
                            <h5 className="bidder b2 gap afe">{<ShortAddress address={bidder} />} <CopyIcon text={bidder} /></h5>
                            <h5 className="amount" style={{ fontWeight: '400' }}>{parseFloat(toTopia(amount)).toLocaleString(undefined)} $DUST</h5>
                            <h5 className="status" style={{ fontWeight: '400' }}>{status === 'active' ? <div style={{ maxWidth: '2rem', width: '2rem' }}>{crown}</div> : capitalize(status)}</h5>
                        </Container>)) : <div className="column w100"><span>No bids yet.</span></div>}
                        {offers?.length > 3 && !showMore && <div className="column w100"><Button onClick={() => setShowMore(true)}>Show All Bids</Button></div>}
                    </div>
                </div>
            </div>
        </div>
        {width <= 1080 && listingInfo}
        <div style={{ margin: '3rem 0', height: '.1rem', width: '100%', backgroundColor: 'var(--gray)' }}></div>
        {similarListings.length > 0 && <div className="similar-listings">
            <h3>Similar Listings</h3>
            <div className="b2 gap-large wrap w100">
                {similarListings.map((listing, i) => <DisplayedListing floorInETH={floorInETH} key={i} listing={listing} />)}
            </div>
        </div>}
        <DustBanner/>
    </div>)
}
function Competition({ setHasBonused, showListing, similarListings, autoSettleTimer, floorInETH, code, ownCode =null, referrer, referralEntries, isReferrer, hasClaimedBonus, refreshReferral }) {
    const { width, flash, account, balance, ethBalance, refreshBalance, refreshEthBalance, ethPrice, pathname, queries, redirect } = useAppContext()
    const [useCode, setUseCode] = useState(code)
    const [redeemCount, setRedeemCount] = useState(0)
    const [referred, setReferred] = useState('')
    const [loadingReferrerInfo, setLoadingReferrerInfo] = useState(false)
    const { contract, checkRefundAmounts, getDevFee, getRaffleStatus, buyEntryETH, buyEntryDust, getUserEntryCount, getRaffleWinner, getReferrer, claimRefund } = useRaffleContract(showListing.current == true)
    const { approved, approveTopia, approving, checkingApproval } = useGameApproval(showListing.current == true ? NEWRAFFLE : RAFFLE)
    const [buyingWithDust, setBuyingWithDust] = useState(false)
    const [buyingWithETH, setBuyingWithETH] = useState(false)
    const [numberEntries, setNumberEntries] = useState(1)
    const [devFee, setDevFee] = useState(new BigNumber(0))
    const [claimingRefund, setClaimingRefund] = useState(false)
    const [refundAmountDust, setRefundAmountDust] = useState(new BigNumber(0))
    const [refundAmountETH, setRefundAmountETH] = useState(new BigNumber(0))
    const [checkingRefund, setCheckingRefund] = useState(false)
    const projectInfo = useMemo(() => showListing?.projectInfo || {}, [showListing])
    const hasNotStarted = useMemo(() => new Date().getTime() - showListing.startTime < 0, [showListing])
    const [userEntries, setUserEntries] = useState(0)
    const [winner, setWinner] = useState(null)
    const [loadingInfo, setLoadingInfo] = useState(false)
    const [entering, setEntering] = useState(false)
    const [enteringWith, setEnteringWith] = useState('$DUST')
    const entryOptions = useMemo(() => {
        if (enteringWith === '$DUST') {
            return showListing.dustPrices?.map(u => ({ ...u, amount: new BigNumber(u.price), entryBonus: Math.max(0, u.numberOfEntries - parseFloat(new BigNumber(u.price).dividedBy(showListing.entryPriceDust).toString())) }))
        } else {
            return showListing.ethPrices?.map(u => ({ ...u, amount: new BigNumber(u.price), entryBonus: Math.max(0, u.numberOfEntries - parseFloat(new BigNumber(u.price).dividedBy(showListing.entryPriceETH).toString())) }))
        }
    }, [showListing, enteringWith])
    const [isValidCode, setIsValidCode] = useState(false)
    const [refreshInfoCount, setRefreshInfoCount] = useState(0)
    const [raffleStatus, setRaffleStatus] = useState(new Date() < showListing.startTime ? 0 : showListing.endTime > new Date().getTime() ? 2 : 1)
    const [winnerTime, setWinnerTime] = useState(0)
    const isCurrentComp = useMemo(() => showListing.current, [showListing])
    const hasEnded = useMemo(() => !showListing ? true : (!isCurrentComp) ? (showListing.endTime < new Date().getTime() || showListing.entryCap > showListing.entryCount) : showListing.competitionType === 'time' ? showListing.endTime < new Date().getTime() : showListing.competitionType === 'entry' ? showListing.entryCount >= showListing.entryCap : showListing.ethCollected?.isGreaterThanOrEqualTo(showListing.maxEth) ?? false, [showListing])
    const filledPercentage = useMemo(() => !isCurrentComp ? '0' : hasEnded ? '100' : showListing.competitionType === 'entry' ? (showListing.entryCount / showListing.entryCap * 100).toFixed(2) : showListing.ethCollected.dividedBy(showListing.maxEth).multipliedBy(100).toFixed(2), [isCurrentComp, hasEnded, showListing])
    useEffect(() => {
        if (contract && useCode && !loadingReferrerInfo) {
            setLoadingReferrerInfo(true)
            getReferrer(useCode).then((result) => {
                if (!result) {
                    setReferred('')
                    setIsValidCode(false)
                } else {
                    setReferred(result.referrer)
                    setIsValidCode(true)
                }
            }).catch(e => {
                console.error(e);
                setReferred('')
                setIsValidCode(false)
            }).finally(() => setLoadingReferrerInfo(false))
        } else if (!useCode) {
            setReferred('')
            setIsValidCode(false)
        }
    }, [account, contract, useCode])
    useEffect(() => {
        if (isValidCode && useCode !== ownCode && isReferrer && !hasClaimedBonus && referred) {
            setRedeemCount(1)
        } else if (!isReferrer && isValidCode && !hasClaimedBonus && referred) {
            setRedeemCount(1)
        } else {
            setRedeemCount(0)
        }
    }, [isValidCode, hasClaimedBonus, useCode, ownCode, isReferrer, referred])
    useEffect(() => {
        if (contract && !loadingInfo) {
            setLoadingInfo(true)
            getUserEntryCount(showListing.id).then(numberEntries => {
                setUserEntries(numberEntries)
                return getRaffleStatus(showListing.id)
            }).then(status => {
                setRaffleStatus(status)
                if (status === 3) {
                    getRaffleWinner(showListing.id).then(winner => {
                        setWinner(winner)
                    }).catch(e => {
                        console.log(e)
                        setWinner(`0x${Array(40).fill('0').join('')}`)
                    })
                } else if (status !== 3) {
                    setWinner(null)
                }
                getDevFee().then(fee => setDevFee(new BigNumber(fee.toString()))).catch(e => {
                    console.log(e)
                    setDevFee(new BigNumber(0))
                })
            }).catch(console.error).finally(() => setLoadingInfo(false))
        }
        if (showListing.onlyEth && enteringWith !== 'ETH') {
            setEnteringWith('ETH')
        } else if (showListing.onlyDust && enteringWith !== '$DUST') {
            setEnteringWith('$DUST')
        }
    }, [refreshInfoCount, contract, showListing])
    const checkRaffle = useCallback(() => {
        if (raffleStatus === 6 && !winnerTime) setWinnerTime(new Date().getTime() + autoSettleTimer)
        if (![3, 4].includes(raffleStatus)) { setRefreshInfoCount(c => c + 1) }
    }, [raffleStatus, winnerTime, autoSettleTimer, setWinnerTime, setRefreshInfoCount])
    const enter = useCallback(async (id) => {
        console.log('entering with', enteringWith, id, showListing.id, useCode, referred, redeemCount)
        if ((ownCode && useCode === ownCode)) return flash('You cannot use your own code')
        if (entering) return
        setEntering(true)
        try {
            twq('event', 'tw-od1d9-od1dg', {
            });      
        } catch {}
        let prom = enteringWith === '$DUST' ? buyEntryDust(showListing.id, id, devFee.toString(), referred ? useCode : '', Math.min(65, Math.max(redeemCount - (hasClaimedBonus ? 0 : 1), 0), referralEntries)) : buyEntryETH(showListing.id, id, entryOptions.find(u => u.id === id).price, referred ? useCode : '', Math.min(65, Math.max(redeemCount - ((hasClaimedBonus || (ownCode && useCode === ownCode) || !referred) ? 0 : 1), 0)), referralEntries)
        prom.then(() => {
            if (typeof window.analyze === 'function') window.analyze(22, {competition: showListing._id})
            console.log('entered')
            refreshEthBalance()
            refreshBalance()
            setRefreshInfoCount(c => c + 1)
            if (useCode) {
                localStorage.setItem('referralCode', useCode)
                setHasBonused(true)
                redirect(`${pathname}?${Object.entries(queries).filter(([k]) => k !== 'code').map(([k, v]) => `${k}=${v}`).join('&')}${Object.keys(queries).filter(a => a !== 'code').length ? '&' : ''}code=${code}`)
            }
            if (showListing.current) refreshReferral()
        }).catch(e => {
            console.log(e)
            flash('Error entering raffle')
        }).finally(() => setEntering(false))
    }, [useCode, ownCode, entering, enteringWith, buyEntryDust, buyEntryETH, showListing, devFee, referred, redeemCount, referrer])
    useEffect(() => {
        const int = setInterval(checkRaffle, 10000)
        return () => clearInterval(int)
    }, [raffleStatus])
    const bonuses = useMemo(() => {
        if (ownCode === useCode) return 0
        if (!useCode || !isValidCode || !referred || (!isReferrer && hasClaimedBonus)) return 0
        if (isReferrer) return redeemCount
        return 1
    }, [referred, isReferrer, hasClaimedBonus, redeemCount, referralEntries, ownCode, useCode, isValidCode])
    const claim = useCallback(() => {
        if (claimingRefund || raffleStatus !== 4) return
        if (refundAmountDust.isEqualTo(0) && refundAmountETH.isEqualTo(0)) return
        setClaimingRefund(true)
        claimRefund(showListing.id).then(() => {
            setRefundAmountDust(new BigNumber(0))
            setRefundAmountETH(new BigNumber(0))
            refreshBalance()
            refreshEthBalance()
        }).catch(e => {
            console.log(e)
            flash('Error claiming refund')
        }).finally(() => {
            setClaimingRefund(false)
        })
    }, [refundAmountDust, refundAmountETH, raffleStatus])
    useEffect(() => {
        if (!account || !contract || !showListing || !showListing.current || raffleStatus !== 4) {
            setRefundAmountDust(new BigNumber(0))
            setRefundAmountETH(new BigNumber(0))
        } else if (!checkingRefund) {
            setCheckingRefund(true)
            checkRefundAmounts(showListing.id).then(({ dust, eth }) => {
                setRefundAmountDust(dust)
                setRefundAmountETH(eth)
            }).catch(e => {
                console.log(e)
                flash('Error checking refund')
            }).finally(() => setCheckingRefund(false))
        }
    }, [contract, account, raffleStatus, showListing])
    const listingInfo = useMemo(() => <>
        <Container style={{ marginTop: '1rem' }} className="column afs">
            <h3>{showListing.projectInfo?.name || 'NFT'} Listing Details</h3>
            {showListing.projectInfo?.bannerImage && <img className="listing-image" src={showListing.projectInfo?.bannerImage} />}
            <div className="listing-details">
                <span className="heading">Contract Address</span>
                <span className="subheading">{<ShortAddress address={showListing.nftContract} />}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Token ID</span>
                <span className="subheading">{showListing.blind ? '?' : shortAddress(showListing.tokenId)}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Number Minted</span>
                <span className="subheading">{showListing.projectInfo?.count}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Total Supply</span>
                <span className="subheading">{showListing.projectInfo?.totalSupply || 0}</span>
            </div>
            <div className="listing-details">
                <span className="heading">Collection Floor Price</span>
                <span className="subheading">{showListing.projectInfo?.floorPrice} ETH</span>
            </div>
            <div className="listing-details">
                <span className="heading">Start Time</span>
                <span className="subheading">{new Date(showListing.startTime).toLocaleString()}</span>
            </div>
            {(!isCurrentComp || showListing.competitionType === 'time') && <div className="listing-details">
                <span className="heading">End Time</span>
                <span className="subheading">{new Date(showListing.endTime).toLocaleString()}</span>
            </div>}
        </Container>
        <div className="column w100 " style={{ marginTop: '.5rem' }}>
            {!showListing.blind ? <>
                <Button className="sea-button" onClick={() => linkTo(`https://opensea.io/assets/${showListing.nftContract}/${showListing.tokenId}`)}>
                    <div className="sea-icon">{openSea}</div>
                    <span>View on OpenSea</span>
                </Button>
                <Button className="sea-button" onClick={() => linkTo(`${projectInfo?.website}`)}>
                    <span>Project Website</span>
                </Button>
            </> :
                <>
                    <Button className="sea-button" onClick={() => linkTo(`https://opensea.io/collection/${showListing.slug}`)}>
                        <div className="sea-icon">{openSea}</div>
                        <span>View on OpenSea</span>
                    </Button>
                    <Button className="sea-button" onClick={() => linkTo(`${projectInfo?.website}`)}>
                        <span>Project Website</span>
                    </Button>
                </>}
        </div>
    </>, [showListing, projectInfo, isCurrentComp])
    return (<div className="column w100 jfs afs">
        <Button alt className="borderless-button" onClick={() => redirect('/vault')}>Return to All Listings</Button>
        <div className="listing-main">
            <div className="left-side column w100 jfs">
                <img className="listing-image w100" src={showListing.image} />
                {width > 1080 && listingInfo}
            </div>
            <div className="right-side column w100 gap">
                <h1 style={{ fontSize: '4rem' }} className='w100 b2 wrap jfs gap-large'>{showListing.blind ? `Mystery ${projectInfo?.name || 'Token'}` : projectInfo?.name || 'Token'} {!showListing.blind && <span style={{ fontSize: '1.8rem' }} className="faded-out">#{showListing.tokenId}</span>} <div style={{ maxWidth: '1.1rem', width: '1.1rem', transform: 'translateY(-.8rem)' }}>{shield}</div></h1>
                <div className="w100 b2 wrap afs jfs gap-very-large marg-y-large" style={{ borderBottom: '.1rem solid var(--gray)', marginBottom: '1rem', paddingBottom: 'var(--large)' }}>
                    <div className="column afs pad">
                        <label className=''>Floor Price</label>
                        <h3 style={{ fontStyle: 'italic' }}>{projectInfo?.floorPrice ? `${projectInfo.floorPrice} ETH` : 'N/A'}</h3>
                        <span className="">${((projectInfo?.floorPrice || 0) * ethPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="column jfs afs">
                        {hasNotStarted ? <>
                            <label className="">Starts In</label>
                            <span className="time-span"><TimeSpan endTime={showListing.startTime} /></span>
                        </> : <>
                            <label className="">{(!isCurrentComp || showListing.competitionType === 'time') ? 'Ends In' : 'Total Entries Sold'}</label>
                            {(!isCurrentComp || showListing.competitionType === 'time') ? <span className="time-span"><TimeSpan endTime={showListing.endTime} /></span> : (isCurrentComp && showListing.competitionType === 'entry') ? <div className='listing-details-percent-container static time-span'>
                                <div className="b2 w100 jsb">
                                    <span>{showListing.entryCount}</span>
                                    <span>{showListing.entryCap}</span>
                                </div>
                                <div className="percent-backed">
                                    <div className='listing-percent' style={{ width: `${filledPercentage}%` }} />
                                </div>
                            </div> : (isCurrentComp && showListing.competitionType === 'eth') ? <div className='listing-details-percent-container static time-span'>
                                <div className="b2 jsb w100">
                                    <span>{parseFloat(filledPercentage)}%</span>
                                    <span>Completiton</span>
                                </div>
                                <div className="percent-backed">
                                    <div className='listing-percent' style={{ width: `${filledPercentage}%` }} />
                                </div>
                            </div> : <span className="time-span"><TimeSpan endTime={showListing.endTime} /></span>}
                        </>}
                    </div>
                    <div className="list-head-column column jfs afs">
                        <label>Referral Code</label>
                        <div className="input-wrapper">
                            {isValidCode && <div className="append column" style={{ maxWidth: '1.5rem', width: '1.5rem', paddingRight: '.5rem' }}>{(referred) ? greenCheck : null}</div>}
                            <input placeholder="Enter Code" className='referral-input' value={useCode} onChange={e => setUseCode(e.target.value.toUpperCase())} />
                        </div>
                    </div>
                </div>
                {!showListing.current && <div className="column w100">
                    <label>Entry Cost:</label>
                    <h3>{toTopia(showListing.entryCost.plus(showListing.entryPriceDust), 2)} $DUST | {parseFloat(toTopia(showListing.entryCost.plus(showListing.entryPriceETH), 4)).toLocaleString()} ETH</h3>
                    <span>{showListing.entryCount}{showListing.entryCap < 50000 && ` out of ${showListing.entryCap}`} total entries</span>
                </div>}
                {winner ? <>
                    <h3>Competition won by <ShortAddress address={(winner)} /></h3>
                </> : <>
                    <div className="column w100 gap">
                        {raffleStatus === 1 && !hasEnded && userEntries < showListing.entryLimit ? (showListing.current ? <>
                        <div className="column w100">
                            <h3 style={{ marginBottom: '0' }}>ENTER COMPETITION</h3>
                            {account ? <>
                                <h4 style={{ marginTop: '0' }} className="b2 gap-small wrap">You've used <div className="strong">{userEntries}</div> Entr{userEntries === 1 ? 'y' : 'ies'}{showListing.entryLimit < 50000 && ` out of ${showListing.entryLimit} entr${showListing.entryLimit === 1 ? 'y' : 'ies'}`}</h4>
                            </> : <span className='strong'>You must connect your wallet to participate</span>}
                            <span>Your {enteringWith.toUpperCase()} Balance: {parseFloat(toTopia((enteringWith === 'ETH' ? ethBalance : balance).toString())).toLocaleString()} {enteringWith}</span>
                        </div>
                       
                            {!(showListing.onlyEth || showListing.onlyDust) && <div className="entry-selector">
                                <div className="gray-bar"></div>
                                <div className="tog">
                                    <div onClick={() => setEnteringWith('$DUST')} className={`togger ${enteringWith === '$DUST' ? 'active' : ''}`}>$DUST</div>
                                    <div onClick={() => setEnteringWith('ETH')} className={`togger ${enteringWith === 'ETH' ? 'active' : ''}`}>ETH</div>
                                </div>
                                <div className="gray-bar"></div>
                            </div>}
                            {!approved.topia && enteringWith === '$DUST' && <>
                                <span style={{ maxWidth: '80%' }} className="align-text-center">In order to purchase an entry using $DUST you must approve the smart contract. This is a one time approval per listing.</span>
                                <Button disabled={approving} className="gap" onClick={() => {
                                    if (approving) return
                                    approveTopia().catch(e => {
                                        console.log(e)
                                        flash('Error approving $DUST', 'error')
                                    })
                                }}>{approving ? 'Approving...' : <>Approve Contract <div>{checkMark}</div></>}</Button>
                            </>}
                            <div className="entry-options">
                                {entryOptions.map((u, i) => <EntryOption entryId={u.id} enteringWith={enteringWith} numberOfEntries={u.numberOfEntries + (isReferrer ? redeemCount : bonuses)} entryBonus={u.entryBonus + (isReferrer ? redeemCount : bonuses)} amount={u.amount} approved={!(userEntries + u.amount < showListing?.entryLimit && (showListing.competitionType !== 'entry' || showListing?.entryCount + u.amount < showListing?.entryCap)) && (enteringWith !== '$DUST' || approved.topia)} entering={entering} enter={enter} max={i === entryOptions.length - 1} />)}
                            </div>
                            {<div className="redeemCount-cont column w100 gap">
                                <h5 className="w100 align-text-center" style={{ padding: '.2rem', backgroundColor: '#00000010' }}>FREE REFERRAL BONUS ENTRIES</h5>
                                <div className='column pad w100'>
                                    <h5 className="w100">{(!isReferrer) ? bonuses : referralEntries + ((!hasClaimedBonus && referred && useCode !== ownCode) ? 1 : 0)} Available</h5>
                                    <input className="redeemCount" type="number" disabled={!isReferrer} value={((!isReferrer && hasClaimedBonus) || (!isReferrer && !referred) || (ownCode && useCode === ownCode)) ? 0 : Math.min(bonuses+referralEntries, (redeemCount || 0))} onChange={e => setRedeemCount(Math.min(Math.max(parseInt(e.target.value || 0), ((!hasClaimedBonus && referred && useCode !== ownCode) ? 1 : 0)), referralEntries + (((!hasClaimedBonus && referred && useCode !== ownCode) ? 1 : 0))))} />
                                    <span className="strong marg-y">How do I use my referral entries? <span style={{ fontWeight: 400 }}>Your referral entries will be added to your above purchase once you have inputed the amount you wish </span></span>
                                    <span className="strong marg-y">How do I get free entries? <span style={{ fontWeight: 400 }}>In order to get free entries you must participate in the referral program. <Link to="/referral-program">Click here</Link> to learn more and start earning free entries.</span></span>
                                </div>
                            </div>}
                        </> : <>
                        <div className="column w100">
                            <h3 style={{ marginBottom: '0' }}>ENTER COMPETITION</h3>
                            {account ? <>
                                <h4 style={{ marginTop: '0' }} className="b2 gap-small wrap">You've used <div className="strong">{userEntries}</div> Entr{userEntries === 1 ? 'y' : 'ies'}{showListing.entryLimit < 50000 && ` out of ${showListing.entryLimit} entr${showListing.entryLimit === 1 ? 'y' : 'ies'}`}</h4>
                            </> : <span className='strong'>You must connect your wallet to participate</span>}
                            <span>Your {enteringWith.toUpperCase()} Balance: {parseFloat(toTopia((enteringWith === 'ETH' ? ethBalance : balance).toString())).toLocaleString()} {enteringWith}</span>
                        </div>
                            <div className={`entry-input ${!account ? 'faded-out' : ''}`}>
                                <button className="in-button" onClick={() => {
                                    setNumberEntries(a => a <= 1 ? 1 : a - 1)
                                }}>-</button>
                                <input type="number" value={numberEntries} onChange={e => setNumberEntries(e.target.value)} />
                                <button className="in-button" onClick={() => {
                                    let limit = showListing.entryLimit - userEntries
                                    setNumberEntries(a => a >= limit ? limit : a + 1)
                                }}>+</button>
                            </div>
                            <div className="grid responsive-two-column gap w100 wrap entry-options" style={{ marginTop: '.5rem' }}>
                                <div className="column gap jfs entry-option" style={{ boxShadow: 'none' }}>
                                    <span>Your $DUST Balance: {parseFloat(toTopia(balance.toString())).toLocaleString()}</span>
                                    <button disabled={!account || checkingApproval || approving || buyingWithDust || numberEntries + userEntries > showListing.entryLimit || numberEntries < 1} onClick={() => {
                                        if (buyingWithDust || checkingApproval || approving) return
                                        if (!approved.topia) return approveTopia().catch(e => {
                                            console.log(e)
                                            flash('Error approving $DUST', 'error')
                                        })
                                        if (numberEntries + userEntries > showListing.entryLimit) return flash('You cannot enter more than the entry limit')
                                        if (numberEntries < 1) return flash('You must enter at least 1 time')
                                        const entryCost = devFee.plus(showListing.entryCost.times(numberEntries))
                                        if (entryCost.isGreaterThan(balance.toString())) return flash('You do not have enough $DUST to enter this many times')
                                        setBuyingWithDust(true)
                                        buyEntryDust(showListing.id, numberEntries, entryCost).then(() => {
                                            setNumberEntries(1)
                                            setUserEntries(a => a + numberEntries)
                                            refreshBalance()
                                        }).catch(e => {
                                            console.error(e)
                                            flash('Error buying entry, please ensure that you have enough ETH for the gas fee')
                                        }).finally(() => setBuyingWithDust(false))
                                    }}>{checkingApproval ? 'Checking Approval...' : approving ? 'Approving...' : !approved.topia ? 'Approve' : buyingWithDust ? `Purchasing Entr${numberEntries > 1 ? 'ies' : 'y'}...` : 'Enter using $DUST'}</button>
                                    {!approved.topia && <span className="small align-text-center">You must approve $DUST before you can use it to enter competitions.</span>}
                                </div>
                                <div className="column gap jfs entry-option" style={{ boxShadow: 'none' }}>
                                    <span>Your ETH Balance: {parseFloat(toTopia(ethBalance.toString())).toLocaleString()}</span>
                                    <button disabled={!account || buyingWithETH || numberEntries + userEntries > showListing.entryLimit || numberEntries < 1} onClick={() => {
                                        if (numberEntries + userEntries > showListing.entryLimit) return flash('You cannot enter more than the entry limit')
                                        if (numberEntries < 1) return flash('You must enter at least 1 time')
                                        const entryCost = showListing.entryCost.times(numberEntries).plus(showListing.entryPriceETH.times(numberEntries))
                                        if (entryCost.isGreaterThan(ethBalance.toString())) return flash('You do not have enough ETH to enter')
                                        setBuyingWithETH(true)
                                        buyEntryETH(showListing.id, numberEntries, entryCost).then(() => {
                                            setNumberEntries(1)
                                            setUserEntries(a => a + numberEntries)
                                            refreshEthBalance()
                                        }).catch(e => {
                                            console.log(e)
                                            flash('Error entering competition, please ensure you have enough ETH for gas')
                                        }).finally(() => setBuyingWithETH(false))
                                    }}>{buyingWithETH ? `Purchasing Entr${numberEntries > 1 ? 'ies' : 'y'}...` : 'Enter using ETH'}</button>
                                </div>
                            </div> </>) : raffleStatus === 1 && !hasEnded ? <h3>Entry Limit Reached</h3> : [2, 6].includes(raffleStatus) ? <h3>Picking a winner: {raffleStatus === 2 ? 'Waiting...' : <TimeSpan endTime={winnerTime} />}</h3> : raffleStatus === 4 ? <><h3>Competition Failed</h3>{(refundAmountETH?.isGreaterThan(0) || refundAmountDust?.isGreaterThan(0)) && <><span>You Spent {toTopia(refundAmountETH)} ETH and {toTopia(refundAmountDust)} $DUST</span><button className="exchange-button" disabled={claimingRefund} onClick={claim}>{claimingRefund ? 'Claiming' : 'Claim Refund'}</button></>}</> : raffleStatus === 0 ? <h3>Competition has not started</h3> : raffleStatus === 5 ? <h3>Waiting for competition reveal</h3> : <h3>Waiting for results</h3>}
                    </div>
                </>}
                {contract && <span style={{ margin: '.5rem 0' }} className="b2 w100 gap pointer wrap" onClick={() => {
                    let a = document.createElement('a')
                    a.href = `https://etherscan.io/address/${contract.address}`
                    a.target = "_blank"
                    document.querySelector('body').append(a)
                    a.click()
                    a.remove()
                }}><span className="strong">Contract:</span><ShortAddress address={contract.address} /><div style={{ width: '1.1rem', maxWidth: '1.1rem' }}>{linkExternalIcon}</div></span>}
                {showListing?.current && <div className="competition-activity">
                    <div className="column w100" style={{ borderBottom: '.1rem solid gray' }}><h3>Activity</h3></div>
                    <div className="activity-cont">
                        {showListing.entries.map((a, i) => <CompetitionActivity key={a.txHash} {...a} />)}
                    </div>
                </div>}
            </div>
        </div>
        {width <= 1080 && listingInfo}
        {similarListings.length > 0 && <div className="similar-listings">
            <h3>Similar Listings</h3>
            <div className="b2 gap-large wrap w100">
                {similarListings.map((listing, i) => <DisplayedListing key={i} listing={listing} floorInETH={floorInETH} />)}
            </div>
        </div>}
        <DustBanner/>
    </div>)
}
export default function Vault() {
    const loadedPage = useMemo(() => {
        try {
        twq('event', 'tw-od1d9-od1da', {})
        } catch {}
    }, [])
    const { account, queries, pathname, redirect, wallet, staked, balance, refreshBalance, refreshEthPrice } = useAppContext()
    const { contract, addEvent, getDevFee: getVaultDevFee, getTimeBuffer, getMinBidIncrementPercentage, getTimeBufferThreshold, getMustHold } = useVaultContract()
    const { contract: raffleContract, getDevFee, getAutoSettleTime } = useRaffleContract(false)
    const { contract: newCompContract, getReferrer, checkReferrer } = useRaffleContract(true)
    const [mustHold, setMustHold] = useState(false)
    const isHolder = useMemo(() => (staked?.length > 0 || wallet?.length > 0), [staked, wallet])
    const [devFee, setDevFee] = useState(new BigNumber(0))
    const [ownCode, setOwnCode] = useState('')
    const [timeBufferThreshold, setGetTimeBufferThreshold] = useState(0)
    const [minBidIncrementPercentage, setMinBidIncrementPercentage] = useState(0)
    const [timeBuffer, setTimeBuffer] = useState(0)
    const [display, setDisplay] = useState('grid'/* localStorage.getItem(displayKey) ?? 'table' */)
    const [sortMode, setSortMode] = useState(localStorage.getItem(sortKey) ?? 'ending-soonest')
    const [collectionSort, setCollectionSort] = useState('')
    const [filters, setFilters] = useState([])
    const [code, setCode] = useState(localStorage.getItem('referralCode') ?? '')
    const [referrer, setReferrer] = useState('')
    const [referralCount, setReferralCount] = useState(0)
    const [referralEntries, setReferralEntries] = useState(0)
    const [isReferrer, setIsReferrer] = useState(false)
    const [hasClaimedBonus, setHasClaimedBonus] = useState(false)
    const [bonusExpires, setBonusExpires] = useState(0)
    const [loadingReferral, setLoadingReferral] = useState(false)
    const openSeaInfo = useRef({})
    const tokenInfo = useRef({})
    const [showActive, setShowActive] = useState(true)
    const [showType, setShowType] = useState('all')
    const [listings, setListings] = useState([])
    const [loadingListings, setLoadingListings] = useState(false)
    const [gotListings, setGotListings] = useState(false)
    const [gettingVaultInfo, setGettingVaultInfo] = useState(false)
    const [gotValutInfo, setGotVaultInfo] = useState(false)
    const [listingRefreshes, setListingRefreshes] = useState(0)
    const [vaultDevFee, setVaultDevFee] = useState(new BigNumber(0))
    const [vaultInfoRefreshes, setVaultInfoRefreshes] = useState(0)
    const [autoSettleTimer, setAutoSettleTimer] = useState(0)
    const refreshListings = useCallback(() => setListingRefreshes(a => a + 1), [setListingRefreshes])
    const refreshVaultInfo = useCallback(() => setVaultInfoRefreshes(a => a + 1), [setVaultInfoRefreshes])
    const [referralRefreshes, setReferralRefreshes] = useState(0)
    const refreshReferral = useCallback(() => setReferralRefreshes(a => a + 1), [setReferralRefreshes])
    const dustForm = toTopia(balance.toString(), 3);
    const { balance: sweepersBalance } = useSweepersBalance(account)
    const featuredListings = useMemo(() => listings.filter(r => r.status === 'active' && !r.auction && r.current && r.onlyEth && r.competitionType !== 'time').filter((a, i) => i < 3), [listings])
    useEffect(() => {
        if (queries.code && queries.code !== code) {
            setCode(queries.code)
            localStorage.setItem('referralCode', queries.code)
        } else if (!queries.code && code) {
            redirect(`${pathname}?${Object.entries(queries).map(([k, v]) => `${k}=${v}`).join('&')}${Object.keys(queries).length ? '&' : ''}code=${code}`)
        }
    }, [queries])
    useEffect(() => {
        if (!account || !newCompContract) {
            setReferrer('')
            setReferralEntries(0)
            setIsReferrer(false)
        } else if (newCompContract && account && !loadingReferral) {
            setLoadingReferral(true)
            newCompContract.hasBonused(account).then(hasClaimed => setHasClaimedBonus(hasClaimed)).catch(e =>{
                console.log(e)
                setHasClaimedBonus(false)
            })
            checkReferrer(account).then((result) => {
                let fin = (isReferrer, earnedEntries) => {
                    getReferrer(code).then(({ referrer, freeEntries, hasClaimed, expires }) => {
                        setReferrer(referrer)
                        setIsReferrer(isReferrer)
                        if (!isReferrer) setReferralEntries(freeEntries)
                        else setReferralEntries(freeEntries + earnedEntries)
                        setHasClaimedBonus(hasClaimed)
                        setBonusExpires(expires)
                    }).catch(e => {
                        console.error(e);
                        setCode('')
                        localStorage.removeItem('referralCode')
                        setReferrer('')
                        setReferralEntries(0)
                        setIsReferrer(false)
                    }).finally(() => setLoadingReferral(false))
                }
                if (!result) {
                    fin()
                } else {
                    const { referrer, referralEntries, referrals, isReferrer, code: userCode } = result
                    if (isReferrer) {
                        setReferrer(referrer)
                        setReferralEntries(referralEntries)
                        setIsReferrer(isReferrer)
                        setReferralCount(referrals)
                        setOwnCode(userCode)
                        if (code && code !== userCode) fin(isReferrer, referralEntries)
                    } else {
                        fin(false, 0)
                    }
                }
            }).catch(e => {
                console.error(e)
                setReferrer('')
                setReferralEntries(0)
                setIsReferrer(false)
                setOwnCode('')
            }).finally(() => setLoadingReferral(false))
        }
    }, [code, newCompContract, account, referralRefreshes])
    const vaultInfo = useMemo(() => ({
        mustHold,
        timeBufferThreshold,
        minBidIncrementPercentage,
        timeBuffer,
        devFee: vaultDevFee
    }), [listings, mustHold, timeBufferThreshold, minBidIncrementPercentage, timeBuffer, devFee])
    const makeAuction = useCallback(async (auctionId, auction) => {
        if (auction) {
            let fixed = tokenInfo?.image?.replace('ipfs://', 'https://ipfs.io/ipfs/')
            let image = !fixed ? '' : /data\:/.test(fixed) ? fixed : `${process.env.REACT_APP_DATA_PROXY}/image?uri=${fixed}`
            let newAuction = {
                id: auctionId,
                image,
                bidder: auction.bidder,
                nftContract: auction.contractAddress,
                currentBid: new BigNumber(auction.currentBid.toString()),
                previousBid: new BigNumber(auction.previousBid.toString()),
                startingPrice: new BigNumber(auction.startingPrice.toString()),
                activeBidId: auction.activeBidId,
                bidCount: auction.numberBids,
                status: auction.failed ? 'failed' : auction.settled ? 'settled' : new Date() - auction.endTime * 1000 > 0 ? 'ended' : 'active',
                image: /data\:/.test(auction.image) ? auction.image : `${process.env.REACT_APP_DATA_PROXY}/image?uri=${auction.image.replace('ipfs://', 'https://ipfs.io/ipfs/')}`,
                current: auction.current,
                tokenId: auction.tokenId.toString(),
                isERC1155: auction.is1155,
                startTime: auction.startTime * 1000,
                endTime: auction.endTime * 1000,
                slug: auction.openseaSlug,
                blind: auction.blind,
                projectInfo: auction.projectInfo,
                auction: true
            }
            return newAuction
        } else {
            return null
        }
    }, [tokenInfo])
    useMemo(() => {
        if (!contract) return
        const manageAuctionCreated = () => addEvent({
            event: 'AuctionCreated',
            callback: async ([auctionId, startTime, endTime, nftContract, tokenId, blind]) => {
                if (!listings.find(a => a.id === auctionId)) {
                    console.log('AuctionCreated', auctionId, startTime, endTime, nftContract, tokenId, blind)
                    contract.auctionId(auctionId).then(async (auction) => {
                        let newAuction = await makeAuction(auctionId, auction).catch(e => console.error(e))
                        if (newAuction) setListings(a => [...a, newAuction])
                    }).catch(e => {
                        console.error(e)
                    })
                }
                manageAuctionCreated()
            }
        })
        const manageAuctionCancelled = () => addEvent({
            event: 'AuctionCanceled',
            callback: async ([auctionId]) => {
                console.log('AuctionCancelled', auctionId)
                setListings(a => {
                    let index = a.findIndex(a => a.id === auctionId)
                    if (index >= 0) {
                        let newListings = [...a]
                        newListings[index].status = 'failed'
                        return newListings
                    }
                    return a
                })
                manageAuctionCancelled()
            }
        })
        const manageAuctionSettled = () => addEvent({
            event: 'AuctionSettled',
            callback: ([auctionId, nftContract, tokenId, winner, amount]) => {
                console.log('AuctionSettled', auctionId, nftContract, tokenId, winner, amount)
                setListings(a => a.map(u => {
                    if (u.auctionId === auctionId) {
                        return { ...u, status: 'settled' }
                    }
                    return u
                }))
                manageAuctionSettled()
            }
        })
        const manageAuctionFailed = () => addEvent({
            event: 'AuctionFailed',
            callback: ([auctionId, nftContract, tokenId]) => {
                console.log('AuctionFailed', auctionId, nftContract, tokenId)
                setListings(a => a.map(u => {
                    if (u.auctionId === auctionId) {
                        return { ...u, status: 'failed' }
                    }
                    return u
                }))
                manageAuctionFailed()
            }
        })
        const manageAuctionExtended = () => addEvent({
            event: 'AuctionExtended',
            callback: ([auctionId, endTime]) => {
                console.log('AuctionExtended', auctionId, endTime)
                setListings(a => a.map(u => {
                    if (u.auctionId === auctionId) {
                        return { ...u, endTime: endTime * 1000 }
                    }
                    return u
                }))
                manageAuctionExtended()
            }
        })
        const manageTimeBufferUpdated = () => addEvent({
            event: 'AuctionTimeBufferUpdated',
            callback: ([timeBuffer]) => {
                console.log('AuctionTimeBufferUpdated', timeBuffer)
                setTimeBuffer(timeBuffer * 1000)
                manageTimeBufferUpdated()
            }
        })
        const manageMinBidIncrementPercentageUpdated = () => addEvent({
            event: 'MinBidIncrementPercentageUpdated',
            callback: ([minBidIncrementPercentage]) => {
                console.log('MinBidIncrementPercentageUpdated', minBidIncrementPercentage)
                setMinBidIncrementPercentage(minBidIncrementPercentage)
                manageMinBidIncrementPercentageUpdated()
            }
        })
        manageAuctionCreated()
        manageAuctionCancelled()
        manageAuctionSettled()
        manageAuctionFailed()
        manageAuctionExtended()
        manageTimeBufferUpdated()
        manageMinBidIncrementPercentageUpdated()
    }, [contract])
    useEffect(() => {
        if (contract && raffleContract && !gettingVaultInfo && !gotValutInfo) {
            setGettingVaultInfo(true)
            let gatherInfo = async () => {
                await fetch(`${process.env.REACT_APP_DATA_PROXY}/project-info`).then(r => r.json()).then(result => {
                    if (result.error) return console.error(result.message)
                    openSeaInfo.current = result.projectInfo
                }).catch(e => console.error(e))
                await getMustHold().then(bool => setMustHold(bool)).catch(e => { setMustHold(false); console.error(e) })
                await getVaultDevFee().then(fee => setVaultDevFee(new BigNumber(fee.toString()))).catch(e => console.error(e))
                await getDevFee().then(val => setDevFee(new BigNumber(val.toString()))).catch(e => { setDevFee(new BigNumber(0)); console.error(e) })
                await getAutoSettleTime().then(val => setAutoSettleTimer(val * 1000)).catch(e => { setAutoSettleTimer(0); console.error(e) })
                await getTimeBufferThreshold().then(val => setGetTimeBufferThreshold(val * 1000)).catch(e => { setGetTimeBufferThreshold(0); console.error(e) })
                await getMinBidIncrementPercentage().then(val => setMinBidIncrementPercentage(val)).catch(e => { setMinBidIncrementPercentage(0); console.error(e) })
                await getTimeBuffer().then(val => setTimeBuffer(val * 1000)).catch(e => { setTimeBuffer(0); console.error(e) })
            }
            gatherInfo().then(() => setGotVaultInfo(true)).catch(e => console.log(e)).finally(() => setGettingVaultInfo(false))
        }
    }, [contract, raffleContract])
    useEffect(() => {
        if (gotValutInfo) setGotVaultInfo(false)
    }, [vaultInfoRefreshes])
    useEffect(() => {
        if (contract && raffleContract && !gotListings && !loadingListings) {
            console.log('Loading listings')
            setLoadingListings(true)
            fetch(`${process.env.REACT_APP_DATA_PROXY}/auctions`).then(r => r.json()).then(r => {
                if (r.error) return console.error(r.message)
                refreshEthPrice()
                refreshBalance()
                setListings(r.auctions.map(u => {
                    if (u.id == 5 && !u.auction && u.current) return ({
                        ...u,
                        entryPriceETH: new BigNumber(u.entryPriceETH || 0),
                        entryPriceDust: new BigNumber(u.entryPriceDust || 0),
                        entryCost: new BigNumber(u.entryCost || 0),
                        ethCollected: new BigNumber(u.ethCollected || 0),
                        maxEth: new BigNumber(u.maxEth || 0),
                        status: 'failed'
                    })
                    return u.auction ? ({
                        ...u,
                        currentBid: new BigNumber(u.currentBid || 0),
                        previousBid: new BigNumber(u.previousBid || 0),
                        startingPrice: new BigNumber(u.startingPrice || 0)
                    }) : ({
                        ...u,
                        entryPriceETH: new BigNumber(u.entryPriceETH || 0),
                        entryPriceDust: new BigNumber(u.entryPriceDust || 0),
                        entryCost: new BigNumber(u.entryCost || 0),
                        ethCollected: new BigNumber(u.ethCollected || 0),
                        maxEth: new BigNumber(u.maxEth || 0),
                    })
                }))
                setGotListings(true)
            }).catch(e => {
                console.error(e)
            }).finally(() => setLoadingListings(false))
        } else if ((!contract || !raffleContract) && listings.length) {
            setListings([])
        }
    }, [contract, raffleContract, gotListings])
    useEffect(() => {
        if (gotListings) setGotListings(false)
    }, [listingRefreshes])
    const showListing = useMemo(() => {
        return queries.competition === 'true' ? listings?.find(l => l.auction === false && l.id == queries.auction && (queries.current === 'undefined' ? !l.current : l.current?.toString() === queries.current)) : listings?.find(l => l.id == queries.auction && l.auction && (queries.current === 'undefined' ? !l.current : l.current?.toString() === queries.current))
    }, [queries, listings])
    const pollListing = useMemo(() => {
        return Boolean(showListing)
    }, [showListing])
    const gatheringCurrentListing = useRef(false)
    const pollAuction = async () => {
        if (gatheringCurrentListing.current || !showListing) return
        console.log('Polling Listing:', showListing.id)
        gatheringCurrentListing.current = true
        fetch(`${process.env.REACT_APP_DATA_PROXY}/auction?id=${showListing.id}&current=${Boolean(showListing.current)}&competition=${!showListing.auction}`).then(r => r.json()).then(r => {
            if (r.error) {
                console.log(r.message)
                return gatheringCurrentListing.current = false
            }
            let { auction } = r
            if (!auction) return gatheringCurrentListing.current = false
            setListings(allListings => allListings.map(a => {
                if (a.id === auction.id && a.current === auction.current && a.auction === auction.auction) {
                    return auction.auction ? {
                        ...auction,
                        currentBid: new BigNumber(auction.currentBid),
                        previousBid: new BigNumber(auction.previousBid),
                        startingPrice: new BigNumber(auction.startingPrice)
                    } : {
                        ...auction,
                        entryPriceETH: new BigNumber(auction.entryPriceETH),
                        entryPriceDust: new BigNumber(auction.entryPriceDust),
                        entryCost: new BigNumber(auction.entryCost),
                        ethCollected: new BigNumber(auction.ethCollected || 0),
                        maxEth: new BigNumber(auction.maxEth || 0),
                    }
                }
                return a
            }))
            gatheringCurrentListing.current = false
        }).catch(e => {
            console.log(e)
            gatheringCurrentListing.current = false
        })
    }
    useEffect(() => {
        if (pollListing && contract && raffleContract) {
            const interval = setInterval(pollAuction, 1000 * 10)
            return () => clearInterval(interval)
        }
    }, [showListing, contract, raffleContract])
    const [showingFilters, setShowingFilters] = useState(false)
    const [floorInETH, setFloorInETH] = useState(true)
    const sorter = useCallback((a, b) => {
        if (a && !b) return -1
        if (!a && b) return 1
        if (!a && !b) return 0
        if (sortMode == 'new-arrivals') {
            return b.startTime - a.startTime
        } else if (sortMode == 'ending-soon') {
            return a.endTime - b.endTime
        } else if (sortMode == 'highest-bid') {
            let c = parseFloat(b.currentBid?.dividedBy(10 ** 18).toString())
            let d = parseFloat(a.currentBid?.dividedBy(10 ** 18).toString())
            return c - d
        } else if (sortMode == 'lowest-bid') {
            let c = parseFloat(a.currentBid?.dividedBy(10 ** 18).toString())
            let d = parseFloat(b.currentBid?.dividedBy(10 ** 18).toString())
            return c - d
        }
        return b.currentBid?.minus(a.currentBid).toNumber()
    }, [sortMode])
    const filter = useCallback((listings) => {
        return listings.filter(l => {
            return ((showActive ? l.status === 'active' : l.status !== 'active') && (showType === 'all' ? true : showType === 'competition' ? !l.auction : l.auction))
        })
    }, [filters, showActive, showType])
    const filteredListings = useMemo(() => {
        let list = filter(listings).sort(sorter)
        if (collectionSort) list = list.filter(a => a.slug === collectionSort)
        return list
    }, [listings, filter, sorter, collectionSort])
    const { items: reducedListings, Paginator } = usePagination(filteredListings, PERPAGE)
    const collections = useMemo(() => Object.entries(reducedListings.reduce((a, b) => {
        if (!a[b.slug]) a[b.slug] = openSeaInfo.current?.[b.slug] ? openSeaInfo.current?.[b.slug]?.collection?.name ?? b.slug : b.slug
        return a
    }, {})), [reducedListings])
    const activeVaultValue = useMemo(() => listings.filter(a => a.status === 'active').reduce((a, b) => {
        return a + (openSeaInfo.current[b.slug]?.collection?.stats.floor_price || 0)
    }, 0).toLocaleString({ maximumFractionDigits: 3 }), [listings])
    return (<Paralax image={''} style={{ flexGrow: 2 }}>
        <div id="vault" style={{ padding: '3rem 0' }}>
            {showListing ? (showListing.auction ? <Auction floorInETH={floorInETH} similarListings={listings.filter(a => {
                return a.nftContract === showListing.nftContract && a.tokenId !== showListing.tokenId
            })} onNewOffer={({ id, bidder, amount, status }) => {
                setListings(a => a.map(l => {
                    if (l.id === showListing.id && l.activeBidId !== id) {
                        return {
                            ...l,
                            bidder,
                            currentBid: amount,
                            previousBid: l.currentBid,
                            activeBidId: id,
                            bidCount: l.bidCount + 1
                        }
                    }
                    return l
                }))
            }} onSettled={(auctionId, nftProject, tokenId, buyer, amount) => {
                setListings(a => a.map(l => {
                    if (l.id === auctionId && l.status !== 'settled') {
                        if (l.blind) { }
                        return {
                            ...l,
                            status: 'settled',
                            bidder: buyer,
                            currentBid: amount,
                        }
                    }
                    return l
                }))
            }} vaultInfo={vaultInfo} canBid={((mustHold && isHolder) || !mustHold)} showListing={showListing}></Auction> : <Competition setHasBonused={setHasClaimedBonus} ownCode={ownCode} code={code} referrer={referrer}
                referralEntries={referralEntries}
                isReferrer={isReferrer}
                hasClaimedBonus={hasClaimedBonus}
                bonusExpires={bonusExpires}
                referralCount={referralCount}
                refreshReferral={refreshReferral}
                floorInETH={floorInETH} autoSettleTimer={autoSettleTimer} devFee={devFee} showListing={showListing} similarListings={listings.filter(a => {
                    return a.nftContract === showListing.nftContract && a.tokenId !== showListing.tokenId
                })} />) : (<>
                    {showingFilters && <div className="filters"></div>}
                    <HeroHead image={UnicornPool} title={'Welcome to the Sweepers vault.'} description={`Welcome Sweepers to THE VAULT. Here is our online auction marketplace where all swept NFT assets get sent to auction starting at one $DUST Token. All swept assets from Sweepers Member Proposals, Sweep Tank Events, and Reserve Fund sweeps get sent here.
                            May the odds be ever in your favor!`} >
                        <Container className="exchange-stats">
                            <h3>VAULT INFO</h3>
                            <div className="listing-details">
                                <span className="heading">Active Vault Value:</span>
                                <span className="subheading">{activeVaultValue} ETH</span>
                            </div>
                            <div className="listing-details">
                                <span className="heading">Minimum Bid Increase:</span>
                                <span className="subheading">{minBidIncrementPercentage / 100}%</span>
                            </div>
                            <div className="listing-details">
                                <span className="heading">Auction Time Buffer Threshold:</span>
                                <span className="subheading">{toTimeLength(timeBufferThreshold)}</span>
                            </div>
                            <div className="listing-details">
                                <span className="heading">Auction Time Increase:</span>
                                <span className="subheading">{toTimeLength(timeBuffer)}</span>
                            </div>
                        </Container>
                    </HeroHead>
                    {!loadingListings && featuredListings?.length > 0 && <div id="featured-listings" className="column jfs afs w100">
                        <h2>Featured Listings</h2>
                        <div className="b2 gap-large wrap w100 jfs">
                            {featuredListings.map((listing, i) => <DisplayedListing key={listing?._id || i} floorInETH={floorInETH} display={display} listing={listing} />)}
                        </div>
                    </div>}
                    <div className="column jfs afs w100">
                        <h5>{`You have ${sweepersBalance} ${sweepersBalance === 1 ? 'Sweeper' : 'Sweepers'} and ${parseFloat(dustForm)} $DUST`}</h5>
                        <div className="exchange-options afe">
                            <div className="sorts-displays-options wrap">
                                <div className="sorts">
                                    <span>Sort By:</span>
                                    <select value={sortMode} onChange={e => {
                                        localStorage.setItem(sortKey, e.target.value)
                                        setSortMode(e.target.value)
                                    }}>
                                        <option value="new-arrivals">New Arrivals</option>
                                        <optgroup label="Current Bid">
                                            <option value="highest-bid">Highest</option>
                                            <option value="lowest-bid">Lowest</option>
                                        </optgroup>
                                        <option value="ending-soon">Ending Soonest</option>
                                    </select>
                                </div>
                                <div className="sorts">
                                    <span>Collection:</span>
                                    <select value={collectionSort} onChange={e => {
                                        setCollectionSort(e.target.value)
                                    }}>
                                        <option value="">All Collections</option>
                                        {collections.map(([slug, name], i) => <option key={i} value={slug}>{name}</option>)}
                                    </select>
                                </div>
                                <div className="sorts">
                                    <span>Type:</span>
                                    <div className="toggle-options b2 pointer">
                                        <div onClick={() => setShowType('all')} className={`toggle-option ${showType === 'all' ? 'active' : ''}`}>All</div>
                                        <div onClick={() => setShowType('auction')} className={`toggle-option ${showType === 'auction' ? 'active' : ''}`}>Auction</div>
                                        <div onClick={() => setShowType('competition')} className={`toggle-option ${showType === 'competition' ? 'active' : ''}`}>Competition</div>
                                    </div>
                                </div>
                                <div className="sorts">
                                    <span>Show:</span>
                                    <div className="toggle-options b2 pointer" onClick={e => setShowActive(a => !a)}>
                                        <div className={`toggle-option ${showActive ? 'active' : ''}`}>Active</div>
                                        <div className={`toggle-option ${!showActive ? 'active' : ''}`}>Completed</div>
                                    </div>
                                </div>
                                <div className="sorts">
                                    <span>Floor Price:</span>
                                    <div className="toggle-options b2 pointer" onClick={e => setFloorInETH(a => !a)}>
                                        <div className={`toggle-option ${floorInETH ? 'active' : ''}`}>ETH</div>
                                        <div className={`toggle-option ${!floorInETH ? 'active' : ''}`}>USD</div>
                                    </div>
                                </div>
                            </div>
                            <div className="cta-options afe">
                                <div className="refresh-icon" onClick={() => refreshListings()}>{refreshIcon}</div>
                            </div>
                        </div>
                        <div id="listings">
                            {loadingListings ? <span>Loading auctions...</span> : reducedListings.length ? <div className="b2 gap-large wrap w100">
                                {reducedListings.map((listing, i) => <DisplayedListing key={listing?._id || i} floorInETH={floorInETH} display={display} listing={listing} />)}
                            </div> : <div className="no-listings column jfs">
                                <span style={{ textAlign: 'center' }}>No auctions found</span>
                            </div>}
                        </div>
                        {Paginator}
                    </div>
                    <DustBanner/>
                </>)}
        </div>
    </Paralax>)
}