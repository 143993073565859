import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { BigNumber } from 'ethers'
import * as BN from 'bignumber.js'
import { useAppContext } from '../../App'
import './garage.css'
import { multicall, toTopia, useStakingContract, STAKING, StakingABI, useNFTWallet, NFT, NFTABI, useNewNFTWallet } from '../../web3-hooks'
import Svgs from '../../svgs'
import useDustBalance from '../../hooks/useDustBalance';
import useSweepersBalance from '../../hooks/useSweepersBalance';
import DustBanner from '../../components/DustBanner'
import { Button } from 'react-bootstrap'

const { checkmark } = Svgs
export const toTimeLength = (msTimestamp, fullLength, caps, plural) => {
    let seconds = Math.floor(msTimestamp / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)
    return `${days ? `${days}${fullLength ? ` ${caps ? 'D' : 'd'}ay${plural && days !== 1 ? 's' : ''} ` : 'D'} ` : ''}${hours % 24 ? `${hours % 24}${fullLength ? ` ${caps ? 'H' : 'h'}our${plural && hours % 24 !== 1 ? 's' : ''} ` : 'H'} ` : ''}${minutes % 60 ? `${minutes % 60}${fullLength ? ` ${caps ? 'M' : 'm'}inute${minutes % 60 !== 1 && plural ? 's' : ''} ` : 'M'} ` : ''}${seconds % 60 && (!fullLength || seconds % 60) ? `${seconds % 60}${fullLength ? ` ${caps ? 'S' : 's'}econd${plural && seconds % 60 !== 1 ? 's' : ''}` : 'S'}` : ''}`.trim() || 'forever!'
}
function Token({
    claim = () => new Promise(res => res()),
    dailyEarningRate,
    dailyEarnableAmount,
    unstake = () => new Promise(res => res()),
    earned,
    stakedAt,
    image,
    id,
    staked,
    selected,
    select,
    deselect,
    isListed,
    isSelectable
}) {
    const { redirect } = useAppContext()
    const handleSelection = useCallback(() => {
        if (selected) {
            deselect()
        } else {
            select()
        }
    }, [selected])
    const stakedTime = useMemo(() => <span className="staked-time">{staked ? 'Sweeping' : 'Not Sweeping'}</span>, [stakedAt, staked])
    const imageElem = useMemo(() => <img src={image} alt="token" />, [image])
    const multiplierElem = useMemo(() => <span className="align-text-right">{dailyEarningRate?.toString()}x</span>, [dailyEarningRate])
    const earningRateElem = useMemo(() => <span className="align-text-right">{toTopia(dailyEarnableAmount?.toString(), 0)}</span>, [dailyEarnableAmount])
    return (<div className="wallet-token">
        <a href={`https://sweepersclub.com/sweeper/${id}`} target="_blank" onClick={e => {
            e.preventDefault()
            redirect(`/sweeper/${id}`)
        }}>
            <div className="wallet-token-image">
                {imageElem}
            </div>
        </a>
        <div className="wallet-token-head">
            <h5 className="token-id">#{id.toString()}</h5>
            {stakedTime}
        </div>
        <div className="wallet-menu">
            <div className="b2 w100 jsb gap">
                <span>Multiplier:</span>
                {multiplierElem}
            </div>
            <div className="b2 w100 jsb gap">
                <span>Rate:</span>
                <span className="ellipsis">{earningRateElem}/day</span>
            </div>
            {staked && <div className="b2 w100 jsb gap">
                <span className="strong">Earned:</span>
                <span className="strong align-text-right ellipsis">{parseFloat(toTopia(earned?.toString(), 2)).toLocaleString()}</span>
            </div>}
        </div>
        {
            isListed ? <span className="column gap align-text-center">Token is listed on OpenSea. <a href={`https://opensea.io/assets/ethereum/0x2276C60F53c9a807e182d112f9b37D7277463Fec/${id}`} target="_blank">Click here to unlist.</a></span> : (!staked ? <>
                {selected && <div className="checkmark-icon">{checkmark}</div>}
                <button onClick={handleSelection} className={`w100 sweeper-action ${selected ? 'alt' : ''}`} style={{ marginTop: '.4rem' }}>{!selected ? 'Select' : 'Selected'}</button>
            </> : <>
                {selected && <div className="checkmark-icon">{checkmark}</div>}
                <button disabled={!isSelectable} onClick={handleSelection} className={`w100 sweeper-action ${selected ? 'alt' : ''}`} style={{ marginTop: '.4rem' }}>{!selected ? 'Select' : 'Selected'}</button>
                {/* <button onClick={unstake} className="w100 sweeper-action alt" style={{ marginTop: '.4rem' }}>Unstake</button> */}
            </>)
        }
    </div >)
}
export default function Garage() {
    const {
        refreshBalance = () => { console.log('refreshed balance') },
        balance,
        account = '',
        wallet, refreshWallet, loadingWallet
    } = useAppContext()
    const { contract, getDailyEarningRate, stake, unstake, claim, waitForResponse } = useStakingContract()
    const [stakingTokens, setStakingTokens] = useState(false)
    const [unstakingTokens, setUnstakingTokens] = useState(false)
    const [renders, setRenders] = useState(0)
    const [gotDailyEarnableAmount, setGotDailyEarnableAmount] = useState(false)
    const [gettingEarnableAmount, setGettingEarnableAmount] = useState(false)
    const [dailyEarnableAmount, setDailyEarnableAmount] = useState(BigNumber.from(10).mul(BigNumber.from(10).pow(18)))
    const [gotEarnings, setGotEarnings] = useState(false)
    const [claimingTokens, setClaimingTokens] = useState(false)
    const [refreshEarningsCount, setRefreshEarningsCount] = useState(0)
    const refreshEarnings = useCallback(() => setRefreshEarningsCount(refreshEarningsCount + 1), [refreshEarningsCount])
    const [gettingEarnings, setGettingEarnings] = useState(false)
    const [tokens, setTokens] = useState(wallet)
    const selected = useRef([])
    const dustForm = toTopia(balance?.toString(), 3);
    const sweepersBalance = useMemo(() => wallet.length, [wallet])
    const [unstaking, setUnstaking] = useState(false)
    const [isLockedFromStaking, setIsLockedFromStaking] = useState(false)
    const [unlockingStaking, setUnlockingStaking] = useState(false)
    const [lockedOutAmount, setLockedOutAmount] = useState(BigNumber.from(0))
    const [lockedOutEthCost, setLockedOutEthCost] = useState(BigNumber.from(0))
    const [checkingLockOut, setCheckingLockOut] = useState(false)
    const unlockStaking = useCallback(() => {
        if (unlockingStaking) return
        setUnlockingStaking(true)
        contract.claimWithPenalty({ value: lockedOutEthCost.toString() }).then(tx => waitForResponse(tx, 'DustClaimed')).then(() => {
            setUnlockingStaking(false)
            setLockedOutAmount(BigNumber.from(0))
            setLockedOutEthCost(BigNumber.from(0))
            setIsLockedFromStaking(false)
            refreshBalance()
            refreshWallet()
        }).catch(e => {
            setUnlockingStaking(false)
            console.log(e)
        })
    }, [lockedOutAmount, lockedOutEthCost, contract])
    const stakeTokens = () => {
        if (stakingTokens) return
        setStakingTokens(true)
        const toBeStaked = selected.current?.length > 0 ? selected.current : tokens.filter(a => !a.staked).map(u => u.id)
        fetch(`${process.env.REACT_APP_GARAGE_API}/check-if-listed`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ tokens: toBeStaked }) }).then((res) => res.json()).then(async ({ error, tokens: listedTokens, message }) => {
            if (error) return console.log(message || `Error checking if tokens are listed`)
            if (listedTokens.length) {
                return alert(`You have selected tokens that are listed on OpenSea. Please unlist them before staking.`)
            }
            return stake(toBeStaked)
        }).then(() => {
            refreshWallet()
            refreshEarnings()
            setSelected(() => [])
        }).catch(e => {
            console.error(e)
        }).finally(() => setStakingTokens(false))
    }
    const unstakeTokens = () => {
        if (unstakingTokens) return
        setUnstakingTokens(true)
        unstake(selected.current?.length > 0 ? selected.current : tokens.filter(a => a.staked).map(u => u.id)).then(() => {
            refreshWallet()
            refreshEarnings()
            setSelected(() => [])
        }).catch(e => {
            console.error(e)
        }).finally(() => setUnstakingTokens(false))
    }
    const claimRewards = useCallback(() => {
        setClaimingTokens(true)
        claim().then(() => {
            refreshEarnings()
            refreshBalance()
        }).catch(e => {
            console.error(e)
        }).finally(() => setClaimingTokens(false))
    }, [tokens, refreshBalance, refreshEarnings])
    const [listedTokens, setListedTokens] = useState([])
    const [loadingListed, setLoadingListed] = useState(false)
    useEffect(() => {
        if (account && !loadingListed) {
            setLoadingListed(true)
            fetch(`${process.env.REACT_APP_GARAGE_API}/listed-of-wallet`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ wallet: account }) })
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        return console.log(res.message || res)
                    } else {
                        setListedTokens(res.tokens)

                    }
                })
                .catch(err => {

                    console.log(err)
                }).finally(() => setLoadingListed(false))
        }
    }, [account])
    useEffect(() => {
        if (contract && !gotDailyEarnableAmount && !gettingEarnableAmount) {
            setGettingEarnableAmount(true)
            getDailyEarningRate().then(amount => {
                setDailyEarnableAmount(amount);
                setGotDailyEarnableAmount(true)
            }).catch(console.error).finally(() => setGettingEarnableAmount(false))
        }
    }, [contract])
    useEffect(() => {
        if (contract && account && wallet.length > 0 && !loadingWallet && !gotEarnings && !gettingEarnings) {
            const mapToUnclaimedAmount = async () => {
                return await contract.getUnclaimedDust(account).then(([total, owned, results, multipliers, staked]) => { return results.map((amount, i) => ({ ...wallet.find(a => a.id.toString() === owned[i].toString()), id: owned[i].toString(), multiplier: multipliers[i], dailyEarningRate: new BN(multipliers[i].toString()).dividedBy(10000).toString(), staked: staked[i], earned: amount || BigNumber.from(0) })) }).catch(e => {
                    console.error(e);
                    return wallet.map((token) => ({ ...token, dailyEarningRate: new BN(token.multiplier.toString()).dividedBy(10000).toString() }))
                })
            }
            setGettingEarnings(true)
            mapToUnclaimedAmount().then(tokens => { tokens = tokens.map(a => ({ ...a, dailyEarnableAmount: new BN(dailyEarnableAmount.toString()).multipliedBy(new BN(a.multiplier.toString()).dividedBy(10000)) })); setTokens(tokens); setGotEarnings(true) }).catch(e => { console.error(e); setTokens([]) }).finally(() => {
                setGettingEarnings(false)
            })
        } else if ((!account || !contract) && tokens?.length > 0) {
            setTokens([])
        }
    }, [contract, account, wallet, dailyEarnableAmount, gotEarnings, loadingWallet])
    useEffect(() => {
        if (account && contract && !checkingLockOut) {
            setCheckingLockOut(true)
            contract.blockedFromGarage(account).then(async (blocked) => {
                if (blocked) {
                    let requireDynamic = await contract.useCalculatedPenalty().catch(e => console.log(e))
                    if (typeof requireDynamic !== 'boolean') throw new Error(`Invalid requireDynamic value`)
                    let penaltyObj = await contract.penaltyEarnings(account).catch(e => console.log(e))
                    if (typeof penaltyObj === 'undefined') throw new Error(`Invalid penaltyObj value`)
                    const { earnings, penaltyOwed } = penaltyObj
                    let penalty = requireDynamic ? penaltyOwed : await contract.penalty().catch(e => console.log(e))
                    if (typeof penalty === 'undefined') throw new Error(`Invalid penalty value`)
                    setLockedOutAmount(earnings)
                    setLockedOutEthCost(penalty)
                    setIsLockedFromStaking(true)
                } else {
                    setIsLockedFromStaking(false)
                    setLockedOutAmount(BigNumber.from(0))
                    setLockedOutEthCost(BigNumber.from(0))
                }
            }).catch(e => {
                console.error(e)
            }).finally(() => setCheckingLockOut(false))

        }
    }, [account, contract])
    useEffect(() => {
        if (gotEarnings) setGotEarnings(false)
    }, [refreshEarningsCount])
    useEffect(() => {
        let int = setInterval(() => setRefreshEarningsCount(a => a + 1), 15000)
        /*         let walletCheck = setInterval(() => refreshWallet(), 60000) */
        return () => { clearInterval(int); /* clearInterval(walletCheck) */ }
    }, [])
    const isSelectable = (id, bool) => {
        if (bool) console.log('is Selectable', id, selected.current)
        if (selected.current.length < 1) return true
        return tokens.find(a => selected.current.find(b => b === a.id)).staked ? tokens.find(a => a.id === id).staked : !tokens.find(a => a.id === id).staked
    }
    const setSelected = (func) => {
        if (typeof func === 'function') selected.current = func(selected.current)
        setRenders(a => a + 1)
    }
    const handleSelection = (id) => setSelected(a => {
        let ids = [...a]
        let canSelect = isSelectable(id.toString(), true)
        if (canSelect) ids.push(id)
        return ids
    })
    const totalEarnedAmount = useMemo(() => tokens.reduce((a, b) => a.add(b.earned), BigNumber.from(0)), [tokens])
    const allStaked = useMemo(() => tokens.filter(a => a.staked).length === tokens.length, [tokens])
    return (
        <div id="garage">
            <h3 className='rewarded'>Rewards</h3>
            <h1 className='headed'>The Garage</h1>
            <h5>{`You have ${sweepersBalance} ${sweepersBalance === 1 ? 'Sweeper' : 'Sweepers'} and ${Number(dustForm) > 0 ? parseFloat(dustForm).toLocaleString() : '0'} $DUST`}</h5>
            <p>Welcome to your GARAGE. It's a bit dusty in here! Stake your Sweepers here and collect those $DUST Tokens.</p>
            {isLockedFromStaking && <div className="column w100 jfs">
                <h2>Looks like you lost the key to the garage!</h2>
                <p>Staking is currently disabled for you because you listed a staked token on OpenSea. You have {toTopia(lockedOutAmount.toString())} $DUST that is waiting to be claimed.</p>
                <Button disabled={unlockingStaking} onClick={unlockStaking}>{unlockingStaking ? "Unlocking" : 'Unlock staking'}</Button>
            </div>}
            <div id="stake-tokens">
                <div className="staking-head b2 jsb w100">
                    <div className="b2 jfs">
                        <h3>My Sweepers <span className='fader-vader'>({wallet.length})</span></h3>
                    </div>
                    <div className="b2 jfe gap-large">
                        <button className="collect-action" onClick={allStaked ? () => {
                            setUnstaking(true)
                            const tostake = tokens.filter(a => a && a.staked).map(a => a.id)
                            unstake(tostake).then(() => {
                                refreshBalance()
                                refreshWallet()
                                refreshEarnings()
                            }).catch(e => {
                                console.log(e)
                            }).finally(() => setUnstaking(false))
                        } : () => {
                            setStakingTokens(true)
                            const tostake = tokens.filter(a => a && !a.staked).map(a => a.id)
                            stake(tostake).then(() => {
                                refreshWallet()
                                refreshEarnings()
                            }).catch(e => {
                                console.log(e)
                            }).finally(() => setStakingTokens(false))
                        }}>{unstaking ? 'Unstaking...' : stakingTokens ? 'Staking...' : `${allStaked ? 'Unstake' : 'Stake'} All`}</button>
                        <h3>Total $DUST Claimable: {parseFloat(toTopia(totalEarnedAmount?.toString() || '0'))}</h3>
                        <span onClick={claimRewards} className="collect-action">Collect All</span>
                    </div>
                </div>
                <div className="staked-wallet">
                    {tokens.length > 0 ? tokens.map(({ earned, stakedAt, staked, id, image, dailyEarningRate, dailyEarnableAmount, multiplierRate }, i) => <Token key={id.toString()} id={id} claim={() => new Promise((res, rej) => {
                        claim([id]).then(() => {
                            refreshBalance()
                            refreshEarnings()
                            res()
                        }).catch(rej)
                    })} unstake={() => new Promise((res, rej) => {
                        unstake([id]).then(() => {
                            refreshBalance()
                            refreshEarnings()
                            res()
                        }).catch(rej)
                    })} isSelectable={isSelectable(id)} isListed={Boolean(listedTokens.find(a => a == id))} stakedAt={stakedAt} multiplier={multiplierRate} dailyEarningRate={dailyEarningRate} dailyEarnableAmount={dailyEarnableAmount} earned={earned} image={image} staked={staked} selected={selected.current.includes(id)} select={() => handleSelection(id)} deselect={() => setSelected(a => [...a].filter(j => j !== id))} />) : <span className="align-text-center w100">You don't own any Sweepers</span>}
                </div>
                {(selected.current.length ? tokens?.find(a => a.id == selected.current[0])?.staked === false : tokens?.filter(a => a && !a.staked).length > 0) && <button onClick={stakeTokens} disabled={stakingTokens} className="sweeper-action w100 marg-y">{stakingTokens ? 'Staking...' : selected.current.length < 1 ? 'Stake all Sweepers' : `Stake ${selected.current.length} Sweepers`}</button>}
                {(selected.current.length ? tokens?.find(a => a.id == selected.current[0])?.staked === true : tokens?.filter(a => a && a.staked).length > 0) && <button onClick={unstakeTokens} disabled={unstakingTokens} className="sweeper-action w100 marg-y">{unstakingTokens ? 'Unstaking...' : selected.current.length < 1 ? 'Unstake all Sweepers' : `Unstake ${selected.current.length} Sweepers`}</button>}
            </div>
            <div className="marg-y w100 column">
                <DustBanner sell={Number(dustForm) > 1000} />
            </div>
        </div>
    )
}