import classes from './InfoModal.module.css';
import ReactDOM from 'react-dom';
import React, { useMemo } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useAppContext } from '../../App';
import { toTimeLength } from '../../pages/Garage';
import { toTopia } from '../../web3-hooks';
export const Backdrop: React.FC<{ onDismiss: () => void }> = props => {
  return <div className={classes.backdrop} onClick={props.onDismiss} />;
};

const InfoModalOverlay: React.FC<{
  onDismiss: () => void;
}> = props => {
  const { onDismiss } = props;
  const appContext: any = useAppContext();
  const {
    reservePrice,
    duration,
    winnerSettlementTime,
    freeForAllSettlementTime,
    auctionBufferTimeIncrease
  } = appContext;
  const currentAuctionPrice = useMemo(() => parseFloat(toTopia(reservePrice.toString())), [reservePrice])
  return (
    <>
      <div className={classes.closeBtnWrapper}>
        <button onClick={onDismiss} className={classes.closeBtn}>
          <XIcon className={classes.icon} />
        </button>
      </div>

      <div className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.bidWrapper}>
            <div className={classes.header}>
              <div className={classes.title}>
                <h2>Info</h2>
                <h1>Bidding and Settling</h1>
              </div>
            </div>
            <div className={classes.headerText}>Automatic Settlement</div>
            Sweepers Club holders will not have to wait for auction winners to settle and claim their NFT for a new one to occur. The automatic settlement feature includes the settlement cost in the bid amount.
For example, if a holder wins the Sweeper NFT auction at the amount of {currentAuctionPrice} ETH. The {currentAuctionPrice} ETH will include the settlement cost, and once the auction is over, the automatic settlement feature will send the NFT to the winner's wallet, and the next one will begin.
            <br />
            <br />
            <div className={classes.headers}>Buy It Now</div>
            With the new settlement protocol, Sweepers Club features a "buy it now" feature for auctions. If no one bids on a Sweeper NFT and the auction's {toTimeLength(duration, true)} time frame ends at 0.00 ETH, anyone can purchase the Sweeper NFT for {currentAuctionPrice} ETH plus gas fees.
This feature will eliminate the need for individuals to pay to burn the Sweeper NFT so the next auction can proceed.

          </div>
        </div>
      </div>
    </>
  );
};

const InfoModal: React.FC<{
  onDismiss: () => void;
}> = props => {
  const { onDismiss } = props;
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onDismiss={onDismiss} />,
        document.getElementById('backdrop-root')!,
      )}
      {ReactDOM.createPortal(
        <InfoModalOverlay onDismiss={onDismiss} />,
        document.getElementById('overlay-root')!,
      )}
    </>
  );
};

export default InfoModal;
