import { ImageData as data, getSweeperData as getSweeperData, getBigSweeperData as getBigSweeperData, BigNounImageData as bigSweeperData } from '@nouns/assets';
import { buildSVG } from '@nouns/sdk';
import { BigNumber, BigNumber as EthersBN } from 'ethers';
import { ISweeperSeed, useBigSweeperSeed, useSweeperSeed, useSweeperSVG, useSweeperToken } from '../../wrappers/sweeperToken';
import Sweeper from '../Sweeper';
import { Link } from 'react-router-dom';
import classes from './StandaloneSweeper.module.css';
import { useDispatch } from 'react-redux';
import { setOnDisplayAuctionSweeperId } from '../../state/slices/onDisplayAuction';
import loadingSweeper from '../../assets/main-sweeper.png';
import sweeperClasses from '../Sweeper/Sweeper.module.css';
import { useMemo } from 'react';

interface StandaloneSweeperProps {
  sweeperId: EthersBN;
}
interface StandaloneCircularSweeperProps {
  sweeperId: EthersBN;
}

interface StandaloneSweeperWithSeedProps {
  sweeperId: EthersBN;
  onLoadSeed?: (seed: ISweeperSeed) => void;
  shouldLinkToProfile: boolean;
  shouldShowBanner: boolean;
}

const getSweeper = (sweeperId: string | EthersBN | number, seed: ISweeperSeed, imageString: string) => {
  const id = sweeperId.toString();
  const name = `Sweeper ${id}`;
  const description = `Sweeper ${id} is a member of the Sweepers DAO`;
  const { parts, background } = getSweeperData(seed);
  // const svg = buildSVG(parts, data.palette, background);
  const svg = imageString
  // const image = seed ? `data:image/svg+xml;base64,${btoa(svg)}` : loadingSweeper;
  const image = seed ? imageString?.toString() : loadingSweeper;
  return {
    name,
    svg,
    description,
    image,
    parts,
  };
};

// const getSweeper = (sweeperId: string | EthersBN | number, seed: ISweeperSeed) => {
//   const id = sweeperId.toString();
//   const name = `Sweeper ${id}`;
//   const description = `Sweeper ${id} is a member of the Sweepers DAO`;
//   const { parts, background } = getSweeperData(seed);
//   const { svg } = useSweeperSVG(seed).toString();
//   const image = seed ? `data:image/svg+xml;base64,${btoa(svg)}` : loadingSweeper;
//   console.log('seed', seed);
//   console.log('svg', svg);
//   return {
//     name,
//     svg,
//     description,
//     image,
//     parts,
//   };
// };

const getBigSweeper = (sweeperId: string | EthersBN | number, seed: ISweeperSeed) => {
  const id = sweeperId.toString();
  const name = `Sweeper ${id}`;
  const description = `Sweeper ${id} is a member of the Sweepers DAO`;
  const { parts, background } = getBigSweeperData(seed);
  const svg = buildSVG(parts, bigSweeperData.palette, background);
  const image = `data:image/svg+xml;base64,${btoa(svg)}`;

  return {
    name,
    svg,
    description,
    image,
    parts,
  };
};

export const useSweeperData = (sweeperId: string | EthersBN | number) => {
  const seed = useSweeperSeed(BigNumber.from(sweeperId));
  const json = useSweeperToken(BigNumber.from(sweeperId));
  return useMemo(() => seed && getSweeper(sweeperId, seed, json ? json?.image : ''), [sweeperId, seed]);
};

const StandaloneSweeper: React.FC<StandaloneSweeperProps> = (props: StandaloneSweeperProps) => {
  const { sweeperId } = props;
  const seed = useSweeperSeed(sweeperId);
  const json = useSweeperToken(sweeperId);
  const sweeper = seed && getSweeper(sweeperId, seed, json ? json?.image : '');
  // console.log('standlalone', 'sweeperid', sweeperId, 'seed', seed, 'sweeper', sweeper);
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionSweeperId(sweeperId.toNumber()));
  };

  return (
    <Link
      to={'/sweeper/' + sweeperId.toString()}
      className={classes.clickableSweeper}
      onClick={onClickHandler}
    >
      <Sweeper showBanner={true} imgPath={sweeper ? sweeper.image : ''} alt={sweeper ? sweeper.description : 'Sweeper'} />
    </Link>
  );
};

export const StandaloneSweeperCircular: React.FC<StandaloneCircularSweeperProps> = (
  props: StandaloneCircularSweeperProps,
) => {
  const { sweeperId } = props;
  const seed = useSweeperSeed(sweeperId);
  const json = useSweeperToken(sweeperId);
  const sweeper = seed && getSweeper(sweeperId, seed, json ? json?.image : '');

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionSweeperId(sweeperId.toNumber()));
  };

  return (
    <Link
      to={'/sweeper/' + sweeperId.toString()}
      className={classes.clickableSweeper}
      onClick={onClickHandler}
    >
      <Sweeper
        showBanner={true}
        imgPath={sweeper ? sweeper.image : ''}
        alt={sweeper ? sweeper.description : 'Sweeper'}
        wrapperClassName={sweeperClasses.circularSweeperWrapper}
        className={sweeperClasses.circular}
      />
    </Link>
  );
};

export const StandaloneSweeperRoundedCorners: React.FC<StandaloneSweeperProps> = (
  props: StandaloneSweeperProps,
) => {
  const { sweeperId } = props;
  const seed = useSweeperSeed(sweeperId);
  const json = useSweeperToken(sweeperId);
  const sweeper = seed && getSweeper(sweeperId, seed, json ? json?.image : '');

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionSweeperId(sweeperId.toNumber()));
  };

  return (
    <Link
      to={'/sweeper/' + sweeperId.toString()}
      className={classes.clickableSweeper}
      onClick={onClickHandler}
    >
      <Sweeper
      showBanner={true}
        imgPath={sweeper ? sweeper.image : ''}
        alt={sweeper ? sweeper.description : 'Sweeper'}
        className={sweeperClasses.rounded}
      />
    </Link>
  );
};

export const StandaloneSweeperWithSeed: React.FC<StandaloneSweeperWithSeedProps> = (
  props: StandaloneSweeperWithSeedProps,
) => {
  const { sweeperId, onLoadSeed, shouldLinkToProfile, shouldShowBanner } = props;

  const dispatch = useDispatch();
  const seed = useSweeperSeed(sweeperId);
  // const svg = useSweeperSVG(seed);
  const json = useSweeperToken(sweeperId);
  const validSeed = typeof seed !== 'undefined' && !(seed.accessory === 0 && seed.background === 0 && seed.body === 0 && seed.eyes === 0 && seed.mouth === 0 && seed.head === 0);
  if (!validSeed || !sweeperId || !onLoadSeed) return <Sweeper showBanner={false} imgPath="" alt="Sweeper" />;

  setTimeout(() => onLoadSeed(seed), 0);

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionSweeperId(sweeperId.toNumber()));
  };

  const { image, description, parts } = getSweeper(sweeperId, seed, json ? json?.image : '');

  const sweeper = <Sweeper showBanner={shouldShowBanner} imgPath={image} alt={description} parts={parts} />;
  const sweeperWithLink = (
    <Link
      to={'/sweeper/' + sweeperId.toString()}
      className={classes.clickableSweeper}
      onClick={onClickHandler}
    >
      {sweeper}
    </Link>
  );
  return shouldLinkToProfile ? sweeperWithLink : sweeper;
};

export const StandaloneBigSweeperCircular: React.FC<StandaloneCircularSweeperProps> = (
  props: StandaloneCircularSweeperProps,
) => {
  const { sweeperId } = props;
  const seed = useBigSweeperSeed(sweeperId);
  const sweeper = seed && getBigSweeper(sweeperId, seed);

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionSweeperId(sweeperId.toNumber()));
  };

  return (
    <a target="_blank" href={'https://sweepers.wtf/sweeper/' + sweeperId.toString()}>
      <Sweeper
        showBanner={true}
        isBigSweeper={true}
        imgPath={sweeper ? sweeper.image : ''}
        alt={sweeper ? sweeper.description : 'Sweeper'}
        wrapperClassName={sweeperClasses.circularSweeperWrapper}
        className={sweeperClasses.circular}
      />
    </a>
  );
};

export default StandaloneSweeper;
