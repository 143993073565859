import React from 'react';
import { useAppContext } from '../../App';
import './banner.css'
export default function DustBanner({sell = false}) {
    const {redirect} = useAppContext()
    return (
        <div className="dust-banner">
            <div className="content">
                <h2 className="title">{sell ? 'Too much' : 'Need more'} $DUST?</h2>
                <p className="description">{sell ? 'Sell' : 'Purchase'} $DUST {sell ? 'to' : 'from'} other holders on the $DUST Peer Exchange </p>
                <button className="button" onClick={() => redirect('/exchange')}>{sell ? 'Sell' : 'Purchase'} $DUST</button>
            </div>
        </div>
    );
}