import { StandaloneSweeperCircular, StandaloneBigSweeperCircular } from '../StandaloneSweeper';
import { BigNumber as EthersBN } from 'ethers';
import classes from './SweeperImageVoteTable.module.css';
import { GrayCircle } from '../GrayCircle';
import { pseudoRandomPredictableShuffle } from '../../utils/pseudoRandomPredictableShuffle';

interface SweeperImageVoteTableProps {
  sweeperIds: string[];
  propId: number;
  isSweepersTreasuryProp?: boolean;
}
const SWEEPERS_PER_VOTE_CARD_DESKTOP = 15;

const isXLScreen = window.innerWidth > 1200;

const SweeperImageVoteTable: React.FC<SweeperImageVoteTableProps> = props => {
  const { sweeperIds, propId, isSweepersTreasuryProp } = props;

  const shuffledSweeperIds = pseudoRandomPredictableShuffle(sweeperIds, propId);
  const paddedSweeperIds = shuffledSweeperIds
    .map((sweeperId: string) => {
      if(isSweepersTreasuryProp){
        return <StandaloneBigSweeperCircular sweeperId={EthersBN.from(sweeperId)} />;
      }
      return <StandaloneSweeperCircular sweeperId={EthersBN.from(sweeperId)} />;
    })
    .concat(Array(SWEEPERS_PER_VOTE_CARD_DESKTOP).fill(<GrayCircle />))
    .slice(0, SWEEPERS_PER_VOTE_CARD_DESKTOP);

  const content = () => {
    const rows = 3;
    const rowLength = isXLScreen ? 5 : 4;

    return Array(rows)
      .fill(0)
      .map((_, i) => (
        <tr>
          {Array(rowLength)
            .fill(0)
            .map((_, j) => (
              <td>{paddedSweeperIds[i * rowLength + j]}</td>
            ))}
        </tr>
      ));
  };

  return <table className={classes.wrapper}>{content()}</table>;
};

export default SweeperImageVoteTable;
