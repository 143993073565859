import { utils } from 'ethers';
import { Col, Alert, Button } from 'react-bootstrap';
import Section from '../../layout/Section';
import {
  ProposalState,
  ProposalTransaction,
  CallVal,
  useProposal,
  useProposalCount,
  useProposalThreshold,
  usePropose,
} from '../../wrappers/SweepersTreasury';
import { useUserVotes } from '../../wrappers/sweeperToken';
import classes from './CreateProposal.module.css';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { AlertModal, setAlertModal } from '../../state/slices/application';
import ProposalEditor from '../../components/ProposalEditor';
import CreateProposalButton from '../../components/CreateProposalButton';
import ProposalTransactions from '../../components/ProposalTransactions';
import ProposalTransactionFormModal from '../../components/ProposalTransactionFormModal';
import { withStepProgress } from 'react-stepz';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks';

const CreateProposalPage = () => {
  const { account } = useEthers();
  const latestProposalId = useProposalCount();
  const latestProposal = useProposal(latestProposalId ?? 0);
  const availableVotes = useUserVotes();
  const proposalThreshold = useProposalThreshold();

  const { propose, proposeState } = usePropose();

  const [proposalTransactions, setProposalTransactions] = useState<ProposalTransaction[]>([]);
  const [titleValue, setTitleValue] = useState('');
  const [slugValue, setSlugValue] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [bodyValue, setBodyValue] = useState('## Summary\n\nInsert your summary here\n\n## Methodology\n\nInsert your methodology here\n\n## Conclusion\n\nInsert your conclusion here');
  const [sweepValue, setSweepValue] = useState('');
  const [callValue, setCallValue] = useState([''])
  const sig = useState(['']);
  const targets = useState(['0xF803B11bcfF23D9Ed1dF84ee897beEF26DF9ACdd']);
  const calldat = useState(['0x']);

  const addressValidator = (s: string) => {
    return utils.isAddress(s);
  };

  const handleAddProposalAction = useCallback(
    (transaction: ProposalTransaction) => {
      if (!transaction.address.startsWith('0x')) {
        transaction.address = `0x${transaction.address}`;
      }
      if (!transaction.calldata.startsWith('0x')) {
        transaction.calldata = `0x${transaction.calldata}`;
      }
      setProposalTransactions([...proposalTransactions, transaction]);
      setShowTransactionFormModal(false);
    },
    [proposalTransactions],
  );

  const handleRemoveProposalAction = useCallback(
    (index: number) => {
      setProposalTransactions(proposalTransactions.filter((_, i) => i !== index));
    },
    [proposalTransactions],
  );

  const handleTitleInput = useCallback(
    (title: string) => {
      setTitleValue(title);
    },
    [setTitleValue],
  );

  const handleSlugInput = useCallback(
    (slug: string) => {
      setSlugValue(slug);
    },
    [setSlugValue],
  );

  const handleProjectInput = useCallback(
    (project: string) => {
      setProjectValue(project);
    },
    [setProjectValue],
  );

  const handleBodyInput = useCallback(
    (body: string) => {
      setBodyValue(body);
    },
    [setBodyValue],
  );

  const handleSweepAmountInput = useCallback(
    (sweepAmount: string) => {
      setSweepValue(sweepAmount);
      const sweepValueFor = (Number(sweepAmount) * 10 ** 18).toString();
      setCallValue([sweepValueFor]);
    },
    [setSweepValue],
  );

  const isFormInvalid = useMemo(
    () => proposalTransactions.length > 0 || titleValue === '' || bodyValue === '',
    [proposalTransactions, titleValue, bodyValue],
  );

  const hasEnoughVote = Boolean(
    availableVotes && proposalThreshold !== undefined && availableVotes >= proposalThreshold,
  );

  const handleCreateProposal = async () => {
    // if (!proposalTransactions?.length) return;

    // console.log(targets[0], // Targets
    //   callValue, // Values
    //   sig[0], // Signatures
    //   calldat[0], // Calldatas
    //   `# ${titleValue}\n\n${bodyValue}`, // Description
    //   `${slugValue}`,
    //   `${projectValue}`)

    // console.log(proposalTransactions.map(({ address }) => address), // Targets
    //   proposalTransactions.map(({ value }) => value ?? '0'), // Values
    //   proposalTransactions.map(({ signature }) => signature), // Signatures
    //   proposalTransactions.map(({ calldata }) => calldata), // Calldatas
    //   `# ${titleValue}\n\n${bodyValue}`, // Description
    //   slugValue,
    //   projectValue)

    if (!addressValidator(projectValue)) {
      setModal({
        title: 'Failed',
        message: 'Project Address is Invalid',
        show: true,
      });
      return;
    }

    await propose(
      targets[0], // Targets
      callValue, // Values
      sig[0], // Signatures
      calldat[0], // Calldatas
      `# ${titleValue}\n\n${bodyValue}`, // Description
      `${slugValue}`,
      `${projectValue}`,
    );
  };

  const [showTransactionFormModal, setShowTransactionFormModal] = useState(false);
  const [isProposePending, setProposePending] = useState(false);

  const dispatch = useAppDispatch();
  const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);

  useEffect(() => {
    switch (proposeState.status) {
      case 'None':
        setProposePending(false);
        break;
      case 'Mining':
        setProposePending(true);
        break;
      case 'Success':
        setModal({
          title: 'Success',
          message: 'Proposal Created!',
          show: true,
        });
        setProposePending(false);
        break;
      case 'Fail':
        setModal({
          title: 'Transaction Failed',
          message: proposeState?.errorMessage || 'Please try again.',
          show: true,
        });
        setProposePending(false);
        break;
      case 'Exception':
        setModal({
          title: 'Error',
          message: proposeState?.errorMessage || 'Please try again.',
          show: true,
        });
        setProposePending(false);
        break;
    }
  }, [proposeState, setModal]);

  return (
    <Section fullWidth={false} className={classes.createProposalPage}>
      {/* <ProposalTransactionFormModal
        show={showTransactionFormModal}
        onHide={() => setShowTransactionFormModal(false)}
        onProposalTransactionAdded={handleAddProposalAction}
      /> */}
      <Col lg={{ span: 8, offset: 2 }}>
        <Link to="/vote">← All Proposals</Link>
      </Col>
      <Col lg={{ span: 8, offset: 2 }} className={classes.createProposalForm}>
        <h3 className={classes.heading}>Create Proposal</h3>
        <Alert variant="secondary" className={classes.voterIneligibleAlert}>
          <b>Tip</b>: The proposal cannot modified after submission, so please verify all
          information before submitting. The voting period will begin after 1 hour and last for 2 days.
        </Alert>
        {/*
         <div className="d-grid">
          <Button
            className={classes.addTransactionButton}
            variant="dark"
            onClick={() => setShowTransactionFormModal(true)}
          >
            Add Transaction
          </Button>
        </div>
        <ProposalTransactions
          proposalTransactions={proposalTransactions}
          onRemoveProposalTransaction={handleRemoveProposalAction}
        />
        */}
        <ProposalEditor
          title={titleValue}
          slug={slugValue}
          project={projectValue}
          body={bodyValue}
          sweepAmount={sweepValue}
          onTitleInput={handleTitleInput}
          onSlugInput={handleSlugInput}
          onProjectInput={handleProjectInput}
          onBodyInput={handleBodyInput}
          onSweepAmountInput={handleSweepAmountInput}
        />
        <CreateProposalButton
          className={classes.createProposalButton}
          isLoading={isProposePending}
          proposalThreshold={proposalThreshold}
          hasActiveOrPendingProposal={
            (latestProposal?.status === ProposalState.ACTIVE ||
              latestProposal?.status === ProposalState.PENDING) &&
            latestProposal.proposer === account
          }
          hasEnoughVote={hasEnoughVote}
          isFormInvalid={isFormInvalid}
          handleCreateProposal={handleCreateProposal}
        />
      </Col>
    </Section>
  );
};

export default withStepProgress(CreateProposalPage);
