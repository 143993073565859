import React, { useRef, useState } from 'react';

export function ValidateOpenSeaLinkInput({ className = "", invalid = '', placeholder = '', modifier = '', value = '', data = null, setData = (a) => { return }, set = (a) => { return }, wide = false }) {
    const [current, setValue] = useState(value)
    const [valid, setValid] = useState('')
    return (<>
        {data ? <>
            <img style={{ maxWidth: '100%', objectFit: 'contain' }} src={data?.collection?.banner_image_url} alt="Banner Image" />
            <h5>{data?.collection?.name}</h5>
        </> : valid ? <span className="warn">{valid}</span> : ''}
        <div className={`input-wrapper ${wide ? ' w100' : ''} ${className}`} >
            <input placeholder={placeholder || 'https://'} style={{ color: '#212529' }} id='slugInput' className='w100 slugInput' onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    if (current) {
                        let finished = false
                        const finish = (num) => {
                            if (finished) return
                            finished = true
                            if (typeof num === 'string') { return setValid(num) }
                            setValid('')
                            set(current)
                        }
                        try {
                            const that = new XMLHttpRequest()
                            that.open('GET', modifier + current, true)
                            that.onerror = e => {
                                finish(invalid || 'Invalid Slug for use on this site.')
                            }
                            that.onabort = e => {
                                finish(invalid || 'Invalid Slug for use on this site.')
                            }
                            that.onload = e => {
                                if (e.currentTarget?.status !== 200) return finish(invalid || 'Invalid Slug for use on this site.')
                                setData(JSON.parse(e.currentTarget?.response))
                                finish(e.total)
                            }
                            that.send()
                        } catch {
                            finish('')
                        }
                    }
                }
            }} onBlur={() => {
                if (current) {
                    let finished = false
                    const finish = (num) => {
                        if (finished) return
                        finished = true
                        if (typeof num === 'string') return setValid(num)
                        setValid('')
                        set(current)
                    }
                    try {
                        const that = new XMLHttpRequest()
                        that.open('GET', modifier + current, true)
                        that.onerror = e => {
                            finish(invalid || 'Invalid Slug for use on this site.')
                        }
                        that.onabort = e => {
                            finish(invalid || 'Invalid Slug for use on this site.')
                        }
                        that.onload = e => {
                            if (e.currentTarget?.status !== 200) return finish(invalid || 'Invalid Slug for use on this site.')
                            setData(JSON.parse(e.currentTarget?.response))
                            finish(e.total)
                        }
                        that.send()
                    } catch {
                        finish('')
                    }
                }
            }} onChange={e => { setData(null); setValue(e.target.value) }} value={current} />
        </div>
    </>)
}
export default function ValidateLinkInput({ className = "", invalid = '', placeholder = '', modifier = '', value = '', set = (a) => { return }, wide = false }) {
    const [current, setValue] = useState(value)
    const [valid, setValid] = useState('')
    return (<>
        <span className="warn">{valid}</span>
        <div className={`input-wrapper ${wide ? ' w100' : ''} ${className}`} >
            <input placeholder={placeholder || 'https://'} style={{ padding: '5px' }} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    if (current) {
                        let finished = false
                        const finish = (num) => {
                            if (finished) return
                            finished = true
                            if (typeof num === 'string') return setValid(num)
                            setValid('')
                            set(current)
                        }
                        try {
                            const that = new XMLHttpRequest()
                            that.open('GET', modifier + current, true)
                            that.onerror = e => {
                                finish(invalid || 'Invalid URL for use on this site.')
                            }
                            that.onabort = e => {
                                finish(invalid || 'Invalid URL for use on this site.')
                            }
                            that.onload = e => {
                                if (e.currentTarget?.status !== 200) return finish(invalid || 'Invalid URL for use on this site.')
                                finish(e.total)
                            }
                            that.send()
                        } catch {
                            finish(' ')
                        }
                    }
                }
            }} onBlur={() => {
                if (current) {
                    let finished = false
                    const finish = (num) => {
                        if (finished) return
                        finished = true
                        if (typeof num === 'string') return setValid(num)
                        setValid('')
                        set(current)
                    }
                    try {
                        const that = new XMLHttpRequest()
                        that.open('GET', modifier + current, true)
                        that.onerror = e => {
                            finish(invalid || 'Invalid URL for use on this site.')
                        }
                        that.onabort = e => {
                            finish(invalid || 'Invalid URL for use on this site.')
                        }
                        that.onload = e => {
                            if (e.currentTarget?.status !== 200) return finish(invalid || 'Invalid URL for use on this site.')
                            finish(e.total)
                        }
                        that.send()
                    } catch {
                        finish(' ')
                    }
                }
            }} onChange={e => setValue(e.target.value)} value={current} />
        </div>
    </>)
}