import classes from './css.module.css';
import { Button } from 'react-bootstrap';
import WalletConnectModal from '../WalletConnectModal';
import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';

const ConnectedManuallyBtn: React.FC = props => {
  const {account: activeAccount} = useWeb3React();
  const [showConnectModal, setShowConnectModal] = useState(false);
  return (
    <p className={classes.emergencySettleWrapper}>
            {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal onDismiss={() => setShowConnectModal(false)} />
      )}
      <Button className={`${classes.bidBtnAuctionEnded} column`} onClick={() => setShowConnectModal(true)}>
        <span>Connect Wallet</span>
      </Button>
    </p >
  );
};

export default ConnectedManuallyBtn;
