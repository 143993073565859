import React from 'react';
import { Image } from 'react-bootstrap';
import classes from './SweeperInfoRowButton.module.css';
import { useAppSelector } from '../../hooks';

interface SweeperInfoRowButtonProps {
  iconImgSource: string;
  btnText: string;
  onClickHandler: () => void;
}

const SweeperInfoRowButton: React.FC<SweeperInfoRowButtonProps> = props => {
  const { iconImgSource, btnText, onClickHandler } = props;
  const isCool = useAppSelector(state => state.application.isCoolBackground);
  return (
    <button
      className={"action-button w100 row"}
      onClick={onClickHandler}
    >
      <div style={{ maxWidth: '4rem' }} className="row">
        <img src={iconImgSource} />
      </div>
      {btnText}
    </button>
  );
};

export default SweeperInfoRowButton;
