const copy = text => {
    if (!text) return 
    /* COPY */
    try {
        navigator?.clipboard?.writeText(text)
        if (typeof window.flash === 'function') window.flash('Copied to clipboard')
    } catch(e) {
        console.error(e)
        if (typeof window.flash === 'function') window.flash('Failed to copy to clipboard')
    }
}
export default copy