import React from "react";
import { useAppContext } from "../../App";
import './hero-head.css'

export default function HeroHead({ action, actionText, description, title, image, children }) {
    const { width } = useAppContext();
    return (<div className="hero-head" style={{ backgroundImage: width > 1080 && `url(${image})` }}>
        {width < 1080 && <img className="hero-image" src={image} alt="Hero" />}
        <div className="head-content column afs">
            {title && <h2 className="uppercase">{title}</h2>}
            {description && <p>{description}</p>}
            {action && <button onClick={action}>{actionText || 'Read More'}</button>}
        </div>
        {children}
    </div>)
}