import React from 'react';
import { Nav } from 'react-bootstrap';
import IconFix from '../../../icon';
import SVGs from '../../../svgs';
import NavBarButton, { NavBarButtonStyle } from '../../NavBarButton';

const {leftArrow} = SVGs;
interface WalletConnectButtonProps {
  className: string;
  onClickHandler: () => void;
  buttonStyle: NavBarButtonStyle;
}

const WalletConnectButton: React.FC<WalletConnectButtonProps> = props => {
  const { className, onClickHandler, buttonStyle } = props;
  return (
    <Nav.Link className={className} onClick={onClickHandler}>
      <NavBarButton className="connect-wallet-button minwide-but" buttonStyle={buttonStyle} buttonText={'Connect'}>Connect <div style={{width: '1rem', maxWidth: '1rem', marginLeft: '.4rem'}}><IconFix svg={leftArrow}/></div></NavBarButton>
    </Nav.Link>
  );
};

export default WalletConnectButton;
