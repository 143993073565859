
import React from 'react'
import logo from '../NavBar/logo.svg'
import './footer.css'
import svgs from '../../svgs';
import IconFix from '../../icon';
import { useAppContext } from '../../App';

const { medium, twitter, instagram, tiktok, facebook, gitbook, discord, reddit } = svgs;
const SocialBar = () => {
  const socials = [
    {
      name: 'Discord',
      link: 'https://discord.com/invite/CXr2buTaCh',
      icon: discord
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/sweepersclub',
      icon: twitter
    },
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/sweepersclub/',
      icon: instagram
    },
    {
      name: 'Reddit',
      link: 'https://www.reddit.com/u/SweepersClub',
      icon: reddit
    },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/sweepersclub',
      icon: facebook
    },
    {
      name: 'TikTok',
      link: 'https://www.tiktok.com/@sweepersclub',
      icon: tiktok
    },
    {
      name: 'Medium',
      link: 'https://medium.com/legacylab',
      icon: medium
    },
  ]
  const SocialLink = ({ link, icon }) => (<a className="social-bar-link" href={link} target="_blank"><IconFix svg={icon} /></a>)
  return (<div className="social-bar">{socials.map((u, i) => <SocialLink key={i} {...u} />)}</div>)
}
const SiteLinks = () => {
  const { redirect } = useAppContext()
  const links = [
    {
      text: 'Garage',
      url: '/garage'
    },
    {
      text: 'Exchange',
      url: '/exchange'
    },
    {
      text: 'Vault',
      url: '/vault'
    },
    {
      text: 'Ideas',
      url: '/ideas'
    },
    {
      text: 'Governance',
      url: '/governance'
    },
    {
      text: 'Docs',
      url: 'http://docs.sweepersclub.com/'
    },
    {
      text: 'Dust Pan',
      url: '/dust-pan'
    }
  ]
  return (<div className="site-links">{links.map(({ text, url }, i) => (<a key={i} href={url} onClick={e => {
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget.getAttribute('href') === 'no-link') {
      const value = e.currentTarget.innerHTML
      const elem = e.currentTarget
      elem.innerHTML = 'Coming Soon'
      setTimeout(() => {
        elem.innerHTML = value
      }, 2000)
    } else if (/http/.test(url)) {
      window.open(e.currentTarget.href, '_blank')
    } else {
      redirect(url)
    }
  }} target={'_blank'}>{text}</a>))}</div>)
}
const GitbookLink = () => {
  return (<a href="http://docs.sweepersclub.com/" target="_blank"><div className="gitbook-link"><div style={{ width: '1.5rem', maxWidth: '1.5rem', marginRight: '.4rem' }}><IconFix svg={gitbook} /></div>Gitbook</div></a>)
}
const Footer = () => {
  return (<><hr></hr><div id="footer">
    <div className="logo-social-wrap">
      <img className="logo" src={logo} />
      <SocialBar />
    </div>
    <div className="footer-links-wrap">
      <SiteLinks />
      {/*       <GitbookLink /> */}
    </div>
  </div></>)
}
export default React.memo(Footer)
