import { useContractCall } from '@usedapp/core';
import { BigNumber as EthersBN, utils } from 'ethers';
import { SweepersAuctionHouseABI } from '@nouns/sdk';
import config from '../config';
import BigNumber from 'bignumber.js';
import { BigNumber as bNum } from '@ethersproject/bignumber';
import { findAuction, isFounderSweeper, isSweepersTreasurySweeper } from '../utils/founderSweeper';
import { useAppSelector } from '../hooks';
import { AuctionState } from '../state/slices/auction';

export enum AuctionHouseContractFunction {
  auction = 'auction',
  duration = 'duration',
  minBidIncrementPercentage = 'minBidIncrementPercentage',
  sweepers = 'sweepers',
  createBid = 'createBid',
  settleCurrentAndCreateNewAuction = 'settleCurrentAndCreateNewAuction',
}

export interface Auction {
  amount: EthersBN;
  bidder: string;
  endTime: EthersBN;
  startTime: EthersBN;
  sweeperId?: EthersBN;
  // nounId?: EthersBN;
  settled: boolean;
  sniped: boolean;
  snipedAmount: EthersBN;
  snipedBy: string | null;
}

const abi = new utils.Interface(SweepersAuctionHouseABI);

export const useAuction = (auctionHouseProxyAddress: string) => {
  const auction = useContractCall<Auction>({
    abi,
    address: auctionHouseProxyAddress,
    method: 'auction',
    args: [],
  });
  return auction as Auction;
};

export const useAuctionMinBidIncPercentage = () => {
  const minBidIncrement = useContractCall({
    abi,
    address: config.addresses.sweepersAuctionHouseProxy,
    method: 'minBidIncrementPercentage',
    args: [],
  });

  if (!minBidIncrement) {
    return;
  }

  return new BigNumber(minBidIncrement[0]);
};

/**
 * Computes timestamp after which a Sweeper could vote
 * Small Revision by sweeper dao to account for lil founder and sweepers dao rewards
 * @param sweeperId TokenId of Sweeper
 * @returns Unix timestamp after which Sweeper could vote
 */

export const useSweeperCanVoteTimestamp = (sweeperId: number) => {

  const pastAuctions = useAppSelector(state => state.pastAuctions.pastAuctions);

  if(isFounderSweeper(EthersBN.from(sweeperId)) || isSweepersTreasurySweeper(EthersBN.from(sweeperId))) {
    const distanceToAuctionAbove = isFounderSweeper(EthersBN.from(sweeperId)) ? 2 : 1;
    const auctionAbove = findAuction(EthersBN.from(sweeperId).add(distanceToAuctionAbove), pastAuctions);

   return EthersBN.from(auctionAbove?.startTime || 0);
  }

  const auction = findAuction(EthersBN.from(sweeperId), pastAuctions);
  return auction?.startTime ? EthersBN.from(auction?.startTime) : EthersBN.from(0);

};
