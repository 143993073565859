import { ChainId } from '@usedapp/core';
import { CHAIN_ID } from '../config';

const getBaseURL = (network: ChainId) => {
  switch (network) {
    case ChainId.Goerli:
      return 'https://testnets.opensea.io/';
    default:
      return 'https://opensea.io/';
  }
};

const BASE_URL = getBaseURL(CHAIN_ID);

export const buildOpenseaAddressLink = (address: string): string => {
  const path = `assets/ethereum/${address}`;
  return new URL(path, BASE_URL).toString();
};

