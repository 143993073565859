import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useEthers as useWeb3React } from '@usedapp/core'
import BigNumber from 'bignumber.js'
import { ethers, Contract, BigNumber as ethNum } from 'ethers'
import vaultABI from './abis/vaultABI.json'
import vaultABI2 from './abis/vaultABI2.json'
import ERC721_ABI from './abis/erc721.json'
import ERC1155_ABI from './abis/erc1155.json'
import SweeperABI from './abis/sweeperABI.json'
import topiaABI from './abis/topiaABI.json'
import stakingABI from './abis/stakingABI.json'
import multicallABI from './abis/Multicall.json'
import exchangeABI from './abis/peerExchangeABI.json'
import raffleABI from './abis/raffleABI.json'
import newRaffleABI from './abis/new-raffle-abi.json'
import nftABI from './abis/nftABI.json'
import { CHAIN_ID, createNetworkHttpUrl } from './config'
import { useAuth } from './hooks/useAuth'

export const zeroAddress = `0x${Array(40).fill('0').join('')}`
const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider(createNetworkHttpUrl(CHAIN_ID === 5 ? 'goerli' : 'mainnet'))
const TOPIA = CHAIN_ID === 5 ? '0x91b47831698e5D494c27f1931c11287Fc9D18BE1' : '0x68bABA24ee60933c6E22Ea7701D9771285DDE27a'
export const NFT = CHAIN_ID === 5 ? '0x62FcF9b9107890C2Cb1182582964a52e0FFBd636' : '0x2276C60F53c9a807e182d112f9b37D7277463Fec'
export const SWEEPER = CHAIN_ID === 5 ? '0xf56978ff529C328b6503F479E34C02ebB3840863' : '0x2276C60F53c9a807e182d112f9b37D7277463Fec'
export const VAULT = '0xCE8ACE992Ec11B3d51aDd3C1D94FDce1964D2A67'
export const VAULT2 = '0xBF7248469027d5C6316ac771e7465143542dA8d1'
export const NEWRAFFLE = CHAIN_ID === 5 ? '0x2A0aE51d177d5945E37390FfEa405e3Ad582c01B' : '0x619F793e2d7e077986D06630c11C6C0563Eb28e6'
export const RAFFLE = CHAIN_ID === 5 ? '0x7C7543b907B6404670d645c8fe215e3451fbF629' : '0x3e812b6Dae06029b17b7b7522CeE9F8A324183F3'
const MULTICALL = CHAIN_ID === 5 ? '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e' : '0xb0A452DcB9c7cC99bA6a16A0583b8e18e9D3A4c1'
export const EXCHANGE = CHAIN_ID === 5 ? '' : '0xC509b9DfF009a626f58934E66179c0039Ebd98D4'
export const STAKING = CHAIN_ID === 5 ? '0x8F7d63c0275204d42DEf8Fcf772cfcd0d5792834' : '0xE107CBB96681D258EAB97f68ae53CE9d42A31310'
export const SWEEPER_ABI = SweeperABI
export const StakingABI = stakingABI
export const VaultABI = vaultABI
export const VaultABI2 = vaultABI2
export const ExchangeABI = exchangeABI;
export const NFTABI = nftABI
export const RAFFLEABI = raffleABI
export const NEWRAFFLEABI = newRaffleABI
const toBytes32 = (string) => ethers.utils.formatBytes32String(string)
const toUserDataQuery = (address, auctionId) => ethers.utils.keccak256(new ethers.utils.AbiCoder().encode(['address', 'uint256'], [address, auctionId]))
class NFTCache {
  constructor() {
    this.canCache = 'caches' in self
    this.contract = new ethers.Contract(NFT, nftABI, simpleRpcProvider)
  }
  async save(id, data){
    if (!this.canCache) return {...data, id: id.toString()}
    if (!this.cache) this.cache = await caches.open('sweepers')
    this.cache.put(`sweeper-info/${id}`, new Response(JSON.stringify({...data, id: id.toString()})))
  }
  async get(id){
    if (!this.canCache) return console.log('CANNOT CACHE IN THIS BROWSER DUE TO SETTINGS.')
    if (!this.cache) this.cache = await caches.open('sweepers')
    try {
      let data = await this.cache.match(`sweeper-info/${id}`)
      if (!data) return null
      let result =  await data.json()
      console.log('GOT FROM THE CACHE', result)
      return result
    } catch(e) {
      console.log(e)
      return null
    }
  }
  async cacheMany(nfts) {
    let mapped = nfts.map(({id, data}) => ({...data, id: id.toString()}))
    if (!this.canCache) return mapped
    for (let i = 0; i < mapped.length; i++) {
      await this.save(mapped[i].id, mapped[i])
    }
    return mapped
  }
  getWithFetch(id) {
    return new Promise((res, rej) => {
      const getToken = async id => {
        let token = await this.contract.tokenURI(id).catch(e => {
          console.error(e)
          return ''
        })
        token.id = id
        return token
      }
      let cached = this.get(id)
      if (cached) return res(cached)
      getToken(id).then(token => {
        this.save(id, token)
        return res(token)
      }).catch(e => {
        return rej(e)
      })
    })
  }
}

export const nftCache = new NFTCache()
export const useNFTWallet = () => {
  const { account } = useWeb3React()
  const contract = useContract(NFT, nftABI, false)
  const [wallet, setWallet] = useState([])
  const [loading, setLoading] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const refresh = useCallback(() => setRefreshCount(count => count + 1), [])
  useEffect(() => {
    if (!account || !contract || loading) return
    setLoading(true)
    contract.balanceOf(account).then(async result => {
      let length = result.toNumber()
      let tokensCount = Array(length).fill('').map((_, i) => [account, i])
      let tokenIds = await multicall(NFT, nftABI, 'tokenOfOwnerByIndex', tokensCount).then(results => {
        return results.map(result => result[0].toNumber())
      }).catch(e => {
        console.log('FAILED TO GET THE TOKEN INFO')
        console.error(e)
        return []
      })
      let missing = []
      let tokenInfo = []
      for (let i = 0; i < tokenIds.length; i++) {
        let id = tokenIds[i]
        let token = nftCache.get(id.toString())
        if (!token) {
          missing.push({ id, index: i })
        } else {
          tokenInfo.push({ token, index: i })
        }
      }
      let nfts = await multicall(NFT, nftABI, 'tokenURI', missing.map(u => [u])).then(async results => {
        let tokens = []
        for (let i = 0; i < results.length; i++) {
          let uri = results[i][0]
          let token = await fetch(uri).then(res => res.json()).catch(e => {
            console.error(e)
            return {}
          })
          if (token.image) cacheable.push({id: missing[i].toString(), data: token})
          token.id = missing[i]
          tokens.push(token)
        }
        for (let i = 0; i < tokenInfo.length; i++) tokens.push(tokenInfo[i].token)
        nftCache.cacheMany(tokens)
        return tokens
      }).catch(e => {
        console.error(e)
        return []
      })
      console.log('NFTS', nfts)
      setWallet(nfts)
    }).catch(e => {
      console.error(e)
      setWallet([])
    }).finally(() => setLoading(false))
  }, [account, contract, refreshCount])
  return { wallet, loading, refresh }
}
export const useNewNFTWallet = () => {
  const { account } = useWeb3React()
  const contract = useContract(SWEEPER, SweeperABI, false)
  const [wallet, setWallet] = useState([])
  const [claimable, setClaimable] = useState(new BigNumber(0))
  const [loading, setLoading] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const refresh = useCallback(() => setRefreshCount(count => count + 1), [])
  const getTime = useRef(0)
  useEffect(() => {
    if (!account || !contract || loading) return
    setLoading(true)
    getTime.current = Date.now()
    contract.getUnclaimedDust(account).then(async ({ owed, ownedSweepers, dustPerNFTList, multipliers, isStaked }) => {
      let sweeperIds = [...ownedSweepers].map(id => id.toString())
      let missing = []
      let tokenInfo = []
      for (let i = 0; i < sweeperIds.length; i++) {
        let id = sweeperIds[i]
        let token = await nftCache.get(id.toString())
        if (!token) {
          missing.push({ id, index: i })
        } else {
          tokenInfo.push({ token, index: i })
        }
      }
      let nfts = await multicall(SWEEPER, SweeperABI, 'tokenURI', missing.map(u => [u.id])).then(async results => {
        let tokens = []
        let cacheable = []
        for (let i = 0; i < missing.length; i++) {
          let uri = results[i][0]
          let token = await fetch(uri).then(res => res.json()).catch(e => {
            console.error(e)
            return {}
          })
          if (token.image) cacheable.push({ id: missing[i].id, data: token })
          token.id = ownedSweepers[missing[i].index]
          token.earned = dustPerNFTList[missing[i].index]
          token.multiplier = multipliers[missing[i].index]
          token.staked = isStaked[missing[i].index]
          tokens.push(token)
        }
        for (let i = 0; i < tokenInfo.length; i++) {
          let token = tokenInfo[i].token
          token.earned = dustPerNFTList[tokenInfo[i].index]
          token.multiplier = multipliers[tokenInfo[i].index]
          token.staked = isStaked[tokenInfo[i].index]
          tokens.push(token)
        }
        console.log("CACHE NEW TOKENS", cacheable.length)
        await nftCache.cacheMany(cacheable)
        return tokens
      }).catch(e => {
        console.error(e)
        return []
      })
      console.log('NFTS', nfts)
      setWallet(nfts)
      setClaimable(new BigNumber(owed.toString()))
    }).catch(e => {
      console.error(e)
      setWallet([])
    }).finally(() => setLoading(false))
  }, [account, contract, refreshCount])
  return { wallet, claimable, loading, refresh }
}
export const useEthToUSD = () => {
  const [price, setPrice] = useState(0)
  const [loading, setLoading] = useState(false)
  const [gotPrice, setGotPrice] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const refresh = useCallback(() => setRefreshCount(a => a + 1), [setRefreshCount])
  useEffect(() => {
    if (!gotPrice && !loading) {
      setLoading(true)
      fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd').then(res => res.json()).then(data => {
        setPrice(data.ethereum.usd)
        setGotPrice(true)
      }).catch(e => console.error(e)).finally(() => setLoading(false))
    }
  }, [gotPrice])
  useEffect(() => {
    if (gotPrice) {
      setGotPrice(false)
    }
  }, [refreshCount])
  return { price, loading, refresh }
}
export const toTopia = (big, maxDecimals = 3) => {
  let r = new BigNumber(big || 0).dividedBy(10 ** 18)
  if (r.isNaN()) return '0'
  if (r.isLessThanOrEqualTo(new BigNumber(0))) return maxDecimals ? `0.${Array(maxDecimals).fill('0').join('')}` : '0'
  if (r.isLessThan(new BigNumber(maxDecimals ? `0.${Array(maxDecimals - 1).fill('0').join('')}1` : '0'))) return maxDecimals ? `0.${Array(maxDecimals - 1).fill('0').join('')}1` : '0'
  return r.toFixed(maxDecimals)
}
export const getOpenSeaProjectInfo = async (slug) => new Promise((res, rej) => fetch(`https://api.opensea.io/api/v1/collection/${slug}`).then(r => r.json()).then(r => res(r)).catch(e => rej(e)))
export const getContract = (address, abi, library) => {
  const contract = new Contract(address, abi, library)
  return contract
}
function toCallState(callResult, contractInterface, fragment) {
  if (!callResult) return null
  let result
  try {
    result = contractInterface.decodeFunctionResult(fragment, callResult)
  } catch (error) {
    console.log(error)
    console.debug('Result data parsing failed', fragment, callResult)
    return null
  }
  return result
}
export const multicall = (contractAddress, abi, method, data = []) => {
  return new Promise(async (res, rej) => {
    const multicallContract = new Contract(MULTICALL, multicallABI, simpleRpcProvider)
    const callContract = new Contract(contractAddress, abi, simpleRpcProvider)
    const contractInterface = callContract.interface
    const fragment = contractInterface.getFunction(method)
    const callData = data.map(d => {
      return ({ target: contractAddress, callData: contractInterface.encodeFunctionData(fragment, d) })
    })
    let results = []
    let callGroups = []
    let groupSize = 50
    for (let i = 0; i < callData.length; i += groupSize) {
      callGroups.push(callData.slice(i, i + groupSize))
    }
    for (let i = 0; i < callGroups.length; i++) {
      await multicallContract.functions.aggregate(callGroups[i]).then(([block, result]) => {
        const parsedResults = result instanceof Array ? result.map(u => toCallState(u, contractInterface, fragment.name)) : toCallState(result, contractInterface, fragment.name) ?? []
        results.push(...parsedResults)
      }).catch(e => rej(e))
    }
    res(results)
  })
}
export const useGetTokenURI = nftContractAddress => {
  const contract = useContract(nftContractAddress, ERC721_ABI, false)
  const getTokenURI = useCallback((tokenId) => {
    return new Promise((res, rej) => {
      if (!tokenId) return rej('Invalid token id')
      if (!contract) return rej('Contract not loaded')
      contract.tokenURI(tokenId).then((uri) => {
        res(uri)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  return { getTokenURI }
}
export const getTokenURI = (nftContractAddress, tokenId, isERC1155) => {
  return new Promise(async (res, rej) => {
    if (!tokenId) return rej('Invalid token id')
    if (!nftContractAddress) return rej('Contract not loaded')
    const contract = new ethers.Contract(nftContractAddress, ERC721_ABI, getProvider())
    let func = contract[(isERC1155 ? 'uri' : 'tokenURI')]
    if (typeof func === 'function') {
      func(tokenId).then((uri) => {
        res(uri)
      }).catch(e => {
        rej(e)
      })
    } else {
      rej('Invalid contract')
    }
  })
}
const getProvider = () => {
  if (typeof window !== 'undefined') {
    if (window.ethereum) {
      return new ethers.providers.Web3Provider(window.ethereum)
    } else if (window.web3) {
      return new ethers.providers.Web3Provider(window.web3.currentProvider)
    }
  }
  return null
}
export const useContract = (address, abi, requireAccount) => {
  const { library, account } = useWeb3React()
  const [contract, setContract] = useState(null)
  useEffect(() => {
    if (address && abi && (requireAccount ? account : true)) {
      setContract(getContract(address, abi, account ? library?.getSigner(account).connectUnchecked() : simpleRpcProvider))
    } else {
      setContract(null)
    }
  }, [address, abi, account])
  return contract
}
const getTransactionResult = (txReceipt, contract, eventName, isMany) => {
  if (!txReceipt) throw new Error('No transaction receipt')
  let { status, logs } = txReceipt
  if (status === 0) throw new Error('Transaction failed')
  if (!eventName || !contract) return null
  if (isMany) {
    let names = eventName.split(',')
    return []
  } else {
    try {
      let topic = contract.interface.getEventTopic(eventName)
      let log = logs.find(log => log.topics.indexOf(topic) >= 0)
      if (!log) return null
      let { args } = contract.interface.parseLog(log)
      return args
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
const useWaitForResponse = (contract) => {
  const { library } = useWeb3React()
  return useCallback((transactionRequest, eventName, isMany = false) => {
    return new Promise(async (res, rej) => {
      if (!transactionRequest) return rej('No transaction request')
      let { hash } = transactionRequest
      if (!hash) return rej('No transaction hash')
      let receipt = await library.waitForTransaction(hash, 1, 1000 * 60 * 60 * 20).catch(e => console.error(e))
      if (!receipt) return rej('Unable to confirm transaction')
      try {
        res(getTransactionResult(receipt, contract, eventName, isMany))
      } catch (e) {
        return rej(e)
      }
    })
  }, [library, contract])
}
export const useEthBalance = () => {
  const { account, library } = useWeb3React()
  const [balance, setBalance] = useState(null)
  const [gettingBalance, setGettingBalance] = useState(false)
  const [gotBalance, setGotBalance] = useState(false)
  const [balanceRefreshCount, setBalanceRefreshCount] = useState(0)
  const refreshBalance = useCallback(() => setBalanceRefreshCount(a => a + 1), [setBalanceRefreshCount])
  useEffect(() => {
    if (account && library && !gettingBalance && !gotBalance) {
      setGettingBalance(true)
      library.getBalance(account).then(b => {
        setBalance(b)
        setGotBalance(true)
      }).catch(e => {
        console.error(e)
        setBalance(ethNum.from(0))
      }).finally(() => setGettingBalance(false))
    } else if (!gettingBalance) {
      setBalance(ethNum.from(0))
    }
  }, [account, library, gotBalance])
  useEffect(() => {
    if (gotBalance) setGotBalance(false)
  }, [balanceRefreshCount])
  return { balance, refreshBalance }
}
export const useTokenBalance = () => {
  const { account } = useWeb3React()
  const contract = useContract(TOPIA, topiaABI, false)
  const [balance, setBalance] = useState(ethNum.from(0))
  const [gettingBalance, setGettingBalance] = useState(false)
  const [gotBalance, setGotBalance] = useState(false)
  const [balanceRefreshCount, setBalanceRefreshCount] = useState(0)
  const refreshBalance = useCallback(() => setBalanceRefreshCount(a => a + 1), [setBalanceRefreshCount])
  useEffect(() => {
    if (contract && account && !gettingBalance && !gotBalance) {
      setGettingBalance(true)
      contract.balanceOf(account).then(b => {
        setBalance(b)
        setGotBalance(true)
      }).catch(e => {
        console.error(e)
        setBalance(ethNum.from(0))
      }).finally(() => setGettingBalance(false))
    } else if (!gettingBalance) {
      setBalance(ethNum.from(0))
    }
  }, [contract, account, gotBalance])
  useEffect(() => {
    if (gotBalance) setGotBalance(false)
  }, [balanceRefreshCount])
  return { balance, refreshBalance }
}
export const useWallet = () => {
  const [wallet, setWallet] = useState([])
  const [gettingWallet, setGettingWallet] = useState(false)
  const [gotWallet, setGotWallet] = useState(false)
  const [walletRefreshCount, setWalletRefreshCount] = useState(0)
  const refreshWallet = useCallback(() => setWalletRefreshCount(a => a + 1), [setWalletRefreshCount])
  const { account } = useWeb3React()
  const contract = useContract(TOPIA, topiaABI, false)
  /*   useEffect(() => {
      if (contract && account && !gettingWallet && !gotWallet) {
        setGettingWallet(true)
        contract.walletOfOwner(account).then(w => {
          setWallet(w)
          setGotWallet(true)
        }).catch(e => {
          console.error(e)
          setWallet([])
        }).finally(() => setGettingWallet(false))
      } else if (!gettingWallet) {
        setWallet([])
      }
    }, [account, gotWallet, contract])
    useEffect(() => {
      if (gotWallet) setGotWallet(false)
    }, [walletRefreshCount]) */
  return { wallet, refreshWallet }
}
export const useApproval = (address, abi) => {
  const contract = useContract(address, abi, false)
  const { account } = useWeb3React()
  const waitForResponse = useWaitForResponse(contract)
  const approve = useCallback((game) => {
    return new Promise((res, rej) => {
      if (!game) return rej('Invalid game')
      if (!account) return rej('You must be logged in')
      contract.setApprovalForAll(game, true).then(transaction => waitForResponse(transaction)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [account, contract])
  const hasApproved = useCallback((game) => {
    return new Promise(async (res, rej) => {
      if (!game) return rej('Invalid game')
      if (!account || !contract) return res(false)
      await contract.isApprovedForAll(account, game).then((isApproved) => {
        res(isApproved)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract, account])
  return { approve, hasApproved, contract }
}
const useTokenApproval = (address, abi) => {
  const contract = useContract(address, abi, true)
  const { account } = useWeb3React()
  const waitForResponse = useWaitForResponse(contract)
  const approve = useCallback((token) => {
    return new Promise((res, rej) => {
      if (!token) return rej('Invalid token')
      if (!account) return rej('You must be logged in')
      contract.approve(token, ethers.constants.MaxUint256).then(transaction => waitForResponse(transaction)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [account, contract])
  const hasApproved = useCallback((token) => {
    return new Promise(async (res, rej) => {
      if (!token) return rej('Invalid token')
      if (!account) return res(false)
      if (!contract) return res(false)
      await contract.allowance(account, token).then((isApproved) => {
        res(new BigNumber(isApproved.toString()).isGreaterThan(0))
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract, account])
  return { approve, hasApproved, contract }
}


export const useGameApproval = game => {
  const { account } = useWeb3React()
  const { approve: approveTopiaCallback, hasApproved: checkTopia, contract: topiaContract } = useTokenApproval(TOPIA, topiaABI)
  const [approving, setApproving] = useState(false)
  const [checkingApproval, setCheckingApproval] = useState(false)
  const [hasApprovedTopia, setHasApprovedTopia] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  useEffect(() => {
    if (account && topiaContract && !checkingApproval) {
      setCheckingApproval(true)
      let approvalCheck = async () => {
        await checkTopia(game).then(isApproved => {
          setHasApprovedTopia(isApproved)
        }).catch(e => {
          console.error(e)
          setHasApprovedTopia(false)
        })
      }
      approvalCheck().catch(e => console.error(e)).finally(() => { setCheckingApproval(false); })
    } else if (!account) {
      setHasApprovedTopia(false)
    }
  }, [account, topiaContract, refreshCount])
  const approveTopia = useCallback(() => {
    return new Promise((res, rej) => {
      if (approving) return rej('Already approving')
      setApproving(true)
      approveTopiaCallback(game).then(() => {
        setHasApprovedTopia(true)
        res()
      }).catch(e => {
        rej(e)
      }).finally(() => {
        setApproving(false)
      })
    })
  }, [approveTopiaCallback])
  const approved = useMemo(() => ({ topia: hasApprovedTopia }), [hasApprovedTopia])
  return {
    approveTopia,
    approving,
    approved,
    checkingApproval,
    refresh: () => setRefreshCount(r => r + 1)
  }
}
export const useMigrationContract = () => {
  const contract = useContract(STAKING, stakingABI, false)
  const waitForResponse = useWaitForResponse(contract)
  const isStaked = useCallback((id) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.StakedAndLocked(id).then((isStaked) => {
        res(isStaked)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getStakedInfo = useCallback((id) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.StakedNFTInfo(id).then((stakedInfo) => {
        /* 
          {
            id: number,
            stakedTimestamp: BigNumber,
            lastClaimTimestamp: BigNumber
          }
        */
        res(stakedInfo)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getTokenMultiplier = useCallback((id) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.NFTMultiplier(id).then((tokenMultiplier) => {
        console.log(id, tokenMultiplier)
        res(tokenMultiplier)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getMultiplierRate = useCallback((multiplier) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.multiplier(multiplier).then((multiplierRate) => {
        res(multiplierRate)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getMinimumStakeTime = useCallback(() => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.minimumStakeTime().then((minimumStakeTime) => {
        res(minimumStakeTime)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getDailyEarningRate = useCallback(() => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.dailyDust().then((dailyEarningRate) => {
        res(dailyEarningRate)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const stake = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.stakeAndLock(ids).then(tx => waitForResponse(tx)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const unstake = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.unstake(ids).then(tx => waitForResponse(tx)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getUnclaimedAmount = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.getUnclaimedDust(ids).then((amount) => {
        res(amount)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const claim = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.claimDust(ids).then(tx => waitForResponse(tx)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  return { contract, isStaked, getStakedInfo, getTokenMultiplier, getMultiplierRate, getMinimumStakeTime, getDailyEarningRate, stake, unstake, getUnclaimedAmount, claim }
}
export const useStakingContract = () => {
  const contract = useContract(SWEEPER, SweeperABI, false)
  const waitForResponse = useWaitForResponse(contract)
  const isStaked = useCallback((id) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.StakedAndLocked(id).then((isStaked) => {
        res(isStaked)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getStakedInfo = useCallback((id) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.StakedNFTInfo(id).then((stakedInfo) => {
        /* 
          {
            id: number,
            stakedTimestamp: BigNumber,
            lastClaimTimestamp: BigNumber
          }
        */
        res(stakedInfo)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getTokenMultiplier = useCallback((id) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.NFTMultiplier(id).then((tokenMultiplier) => {
        console.log(id, tokenMultiplier)
        res(tokenMultiplier)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getMultiplierRate = useCallback((multiplier) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.multiplier(multiplier).then((multiplierRate) => {
        res(multiplierRate)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getMinimumStakeTime = useCallback(() => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.minimumStakeTime().then((minimumStakeTime) => {
        res(minimumStakeTime)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getDailyEarningRate = useCallback(() => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.dailyDust().then((dailyEarningRate) => {
        res(dailyEarningRate)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const stake = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      console.log('staking', ids)
      await contract.stakeAndLock(ids).then(tx => waitForResponse(tx)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const unstake = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.unstake(ids).then(tx => waitForResponse(tx)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const getUnclaimedAmount = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.getUnclaimedDust(ids).then((amount) => {
        res(amount)
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  const claim = useCallback((ids = []) => {
    return new Promise(async (res, rej) => {
      if (!contract) return rej('Contract not loaded')
      await contract.claimDust(ids).then(tx => waitForResponse(tx)).then(() => {
        res()
      }).catch(e => {
        rej(e)
      })
    })
  }, [contract])
  return { contract, isStaked, getStakedInfo, getTokenMultiplier, getMultiplierRate, getMinimumStakeTime, getDailyEarningRate, stake, unstake, getUnclaimedAmount, claim, waitForResponse }
}
export const useVaultContract = (isOldContract) => {
  const { account } = useWeb3React()
  const contract = useContract(isOldContract ? VAULT : VAULT2, isOldContract ? vaultABI : vaultABI2, false)
  const waitForResponse = useWaitForResponse(contract)
  /* 
    Auction {
      INFT: contractAddress;
      tokenId: number;
      startTime: BigNumber
      endTime: BigNumber
      bidder: address
      currentBid: ethers.BigNumber
      previousBid: ethers.BigNumber
      activeBidId: ethers.BigNumber
      numberBids: number
      hiddenImage: string | nullish
      blind: boolean
      settled: boolean
      failed: boolean
    }
 
    Bid {
      bidder: address
      amount: BigNumber
      auctionId: ethers.BigNumber
      bidStatus: 1 = active, 2 = outbid, 3 = canceled, 4 = accepted
    }
 
    contract functions:
      write: 
        createBid(uint256 _id, uint256 _bidAmount) => BidPlaced(_bidId, _id, msg.sender, _bidAmount)
        _settleAuction(uint256 _id) => AuctionSettled(_id, address(auctionId[_id].contractAddress), auctionId[_id].tokenId, auctionId[_id].bidder, auctionId[_id].currentBid);
      read:
        auctionStatus(uint256 _id) => (uint8)
        getBidsByAuctionId(uint256 _id) external => (uint256[] bidIds)
        getBidsByUser(address _user) =>(uint256[] bidIds)
        getTotalBidsLength() => (uint256)
        getBidsLengthForAuction(uint256 _id) => (uint256)
        getBidsLengthForUser(address _user) => (uint256)
        getBidInfoByIndex(uint256 _bidId) => (address _bidder, uint256 _bidAmount, uint256 _auctionId, string _bidStatus)
        getActiveAuctions() => (uint256[] _activeAuctions)
        getAllAuctions() => (uint256[] auctions, uint256[] status)
        timeBuffer() => (uint256)
        reservePrice() => (uint256)
        minBidIncrementPercentage() => (uint8)
        duration() => (uint256)
        DevFee() => (uint256)
        bidId(uint256 _id) => Bid 
        auctionId(uint256 _id) => Auction
        activeAuctionCount() => (uint256)
        mustHold() => (bool)
  */
  const manageListeners = useRef([]) // stores {event, callback, time, timeout, onTimeout, address, token}
  const ContractEvent = useCallback((name, identify = (result, event) => true) => ({
    /* 
    Returns an event handler
    when the event happens it passes the events arguments to the identify function
    for each of the listeners in manageListeners and if the result is truthy it will fire that event's callback with the arguments
  */
    event: name,
    callback: function () {
      let eventIndex = manageListeners.current.findIndex(event => identify(arguments, event))
      if (eventIndex > -1) {
        let event = manageListeners.current.splice(eventIndex, 1)[0]
        if (typeof event.callback === 'function') event.callback(arguments)
      }
    }
  }), [])
  const contractEvents = [
    'AuctionCreated',
    'AuctionSettled',
    'AuctionCanceled',
    'AuctionFailed',
    'AuctionExtended',
    'AuctionTimeBufferUpdated',
    'AuctionMinBidIncrementPercentageUpdated',
    'BidPlaced'
  ].map(name => ContractEvent(name, (args, event) => {
    return event.name === name
  }))// stores the list of ContractEvent() that the contract will listen for
  /* Adds an listener for an event with optional timeout */
  const addEvent = useCallback((event = {
    event: 'ClaimRewardsbyAddr',
    address: '0x00000',
    callback: (args = []) => { },
    time: new Date().getTime(),
    timeout: 60000,
    onTimeout: () => { }
  }) => manageListeners.current.push(event))
  useEffect(() => {
    /* Handles the timeouts of different listeners */
    let interval = setInterval(() => {
      let length = manageListeners.current.length
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          let event = manageListeners.current[i]
          if (event.time && event.timeout) {
            if (event.time + event.timeout < new Date().getTime()) {
              let event = manageListeners.current.splice(i, 1)[0]
              if (typeof event.onTimeout === 'function') event.onTimeout()
              i--
              length--
            }
          }
        }
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  })
  useEffect(() => {
    /* Adds listeners to the contract for each {event, callback} */
    if (contract) {
      contract.removeAllListeners()
      contractEvents.forEach(({ event, callback }) => contract.on(event, callback))
    } else if (manageListeners.current.length > 0) {
      manageListeners.current = []
    }
    return () => {
      if (contract) contract.removeAllListeners()
    }
  }, [contract, contractEvents])

  const createBid = useCallback((id, bidAmount, fee) => {
    return new Promise((res, rej) => {
      if (!account) return rej('You must be logged in to place a bid')
      contract.createBid(id, bidAmount, { value: fee }).then(transaction => waitForResponse(transaction, 'BidPlaced')).then((created) => {
        res(created)
      }).catch(rej)
    })
  }, [contract, account])
  const settleAuction = useCallback((id) => {
    return new Promise((res, rej) => {
      if (!account) return rej('You must be logged in to settle an auction')
      contract._settleAuction(id).then(transaction => waitForResponse(transaction, 'AuctionSettled')).then((created) => {
        res(created)
      }).catch(rej)
    })
  }, [contract, account])
  const getAuctionStatus = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.auctionStatus(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getBidsByAuctionId = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.getBidsByAuctionId(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getBidsByUser = useCallback((user) => {
    return new Promise((res, rej) => {
      contract.getBidsByUser(user).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getTotalBidsLength = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getTotalBidsLength().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getBidsLengthForAuction = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.getBidsLengthForAuction(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getBidsLengthForUser = useCallback((user) => {
    return new Promise((res, rej) => {
      contract.getBidsLengthForUser(user).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getBidInfoByIndex = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.getBidInfoByIndex(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getActiveAuctions = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getActiveAuctions().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getAllAuctions = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getAllAuctions().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getTimeBuffer = useCallback(() => {
    return new Promise((res, rej) => {
      contract.timeBuffer().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getReservePrice = useCallback(() => {
    return new Promise((res, rej) => {
      contract.reservePrice().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getMinBidIncrementPercentage = useCallback(() => {
    return new Promise((res, rej) => {
      contract.minBidIncrementPercentage().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  /*   const getDuration = useCallback(() => {
      return new Promise((res, rej) => {
        contract.duration().then(result => {
          res(result)
        }).catch(rej)
      })
    }, [contract]) */
  const getTimeBufferThreshold = useCallback(() => {
    return new Promise((res, rej) => {
      contract.timeBufferThreshold().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getDevFee = useCallback(() => {
    return new Promise((res, rej) => {
      contract.DevFee().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getBid = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.bidId(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getAuction = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.auctionId(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getActiveAuctionCount = useCallback(() => {
    return new Promise((res, rej) => {
      contract.activeAuctionCount().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getMustHold = useCallback(() => {
    return new Promise((res, rej) => {
      contract.mustHold().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  return { contract, addEvent, createBid, settleAuction, getAuctionStatus, getBidsByAuctionId, getBidsByUser, getTotalBidsLength, getBidsLengthForAuction, getBidsLengthForUser, getBidInfoByIndex, getActiveAuctions, getAllAuctions, getTimeBuffer, getReservePrice, getMinBidIncrementPercentage, getTimeBufferThreshold, getDevFee, getBid, getAuction, getActiveAuctionCount, getMustHold }
}
export const useExchangeContract = () => {
  const contract = useContract(EXCHANGE, exchangeABI, false)
  const waitForResponse = useWaitForResponse(contract)
  const getListing = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.listingId(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getListingStatus = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.listingStatus(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getOffers = useCallback((listing) => {
    return new Promise((res, rej) => {
      contract.getOffersByListingId(listing).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const userOffers = useCallback((address) => {
    return new Promise((res, rej) => {
      contract.getOffersByUser(address).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getTotalOfferCount = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getTotalOffersLength().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getOfferCount = useCallback((listing) => {
    return new Promise((res, rej) => {
      contract.getOfferCount(listing).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getUserOffersCount = useCallback((address) => {
    return new Promise((res, rej) => {
      contract.getOffersLengthForUser(address).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getOfferInfo = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.getOfferInfoByIndex(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getOfferStatus = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.getOfferStatus(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getAllActiveListings = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getAllActiveListings().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getAllListings = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getAllListings().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getTotalListingCount = useCallback(() => {
    return new Promise((res, rej) => {
      contract.totalListingCount().then(result => {
        res(result)
      }).catch(rej)
    })
  })
  const getOffer = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.offerId(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const createOffer = useCallback((listing, amount) => {
    return new Promise((res, rej) => {
      contract.createOffer(listing, { value: amount?.toString() || '0' }).then(transaction => waitForResponse(transaction, 'OfferPlaced')).then((created) => {
        res(created)
      }).catch(rej)
    })
  }, [contract])
  const cancelOffer = useCallback((listing, offer) => {
    return new Promise((res, rej) => {
      contract.cancelOffer(listing, offer).then(transaction => waitForResponse(transaction)).then(() => {
        res()
      }).catch(rej)
    })
  }, [contract])
  const buyNow = useCallback((listing, price) => {
    return new Promise((res, rej) => {
      contract.buyNow(listing, { value: price }).then(transaction => waitForResponse(transaction, 'ListingSettled')).then((settled) => {
        res(settled)
      }).catch(rej)
    })
  }, [contract])
  const acceptOffer = useCallback((listing) => {
    return new Promise((res, rej) => {
      contract.acceptOffer(listing).then(transaction => waitForResponse(transaction, 'ListingSettled')).then((settled) => {
        res(settled)
      }).catch(rej)
    })
  }, [contract])
  const createListing = useCallback((amount, price, endTime) => {
    return new Promise((res, rej) => {
      contract.createListing(amount, price, endTime).then(transaction => waitForResponse(transaction, 'ListingCreated')).then((created) => {
        res(created)
      }).catch(rej)
    })
  })
  const cancelListing = useCallback((listing) => {
    return new Promise((res, rej) => {
      contract.cancelListing(listing).then(transaction => waitForResponse(transaction)).then(() => {
        res()
      }).catch(rej)
    })
  }, [contract])
  const claimRefund = useCallback((listing) => {
    return new Promise((res, rej) => {
      contract.claimRefundOnExpire(listing).then(transaction => waitForResponse(transaction, 'ListingRefunded')).then((refunded) => {
        res(refunded)
      }).catch(rej)
    })
  }, [contract])
  const getPaused = useCallback(() => {
    return new Promise((res, rej) => {
      contract.isPaused().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getMinListPrice = useCallback(() => {
    return new Promise((res, rej) => {
      contract.minListPrice().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getMinOfferPercentage = useCallback(() => {
    return new Promise((res, rej) => {
      contract.minOfferPercent().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getMinOfferIncrementPercentage = useCallback(() => {
    return new Promise((res, rej) => {
      contract.minOfferIncrementPercentage().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  return { contract, getMinOfferPercentage, getMinOfferIncrementPercentage, getMinListPrice, getListing, getListingStatus, getOffers, userOffers, getTotalOfferCount, getOfferCount, getUserOffersCount, getOfferInfo, getOfferStatus, getAllActiveListings, getAllListings, getTotalListingCount, getOffer, createOffer, cancelOffer, buyNow, acceptOffer, createListing, cancelListing, claimRefund, getPaused }
}
export const useRaffleContract = (current = true) => {
  const { account } = useWeb3React()
  const contract = useContract(!current ? RAFFLE : NEWRAFFLE, !current ? raffleABI : newRaffleABI, false)
  const waitForResponse = useWaitForResponse(contract)
  const getRaffle = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.compId(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getRaffleStatus = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.compStatus(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getAllRaffles = useCallback(() => {
    return new Promise((res, rej) => {
      contract.getAllComps().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getDevFee = useCallback(() => {
    return new Promise((res, rej) => {
      contract.DevFee().then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract, current])
  const buyEntryDust = useCallback((id, count, amount, redeemFrom = '', redeemCount = 0) => {
    return new Promise((res, rej) => {
      if (current) {
        const redeemTeam = toBytes32(redeemFrom)
        contract.buyEntryDust(id, count, redeemTeam, redeemCount, { value: amount.toString() }).then(transaction => waitForResponse(transaction)).then((bought) => {
          res(bought)
        }).catch(rej)
      } else {
        contract.buyEntryDust(id, count, { value: amount?.toString() || '0' }).then(transaction => waitForResponse(transaction)).then((bought) => {
          res(bought)
        }).catch(rej)
      }
    })
  }, [contract, current])
  const buyEntryETH = useCallback((id, count, amount, redeemFrom = '', redeemCount = 0) => {
    return new Promise(async (res, rej) => {
      if (current) {
        const redeemTeam = toBytes32(redeemFrom)
        contract.buyEntryETH(id, count, redeemTeam, redeemCount, { value: amount?.toString() || '0' }).then(transaction => waitForResponse(transaction)).then((bought) => {
          res(bought)
        }).catch(rej)
      } else {
        contract.buyEntryETH(id, count, { value: amount?.toString() || '0' }).then(transaction => waitForResponse(transaction)).then((bought) => {
          res(bought)
        }).catch(rej)
      }
    })
  }, [contract, current])
  const getUserEntryCount = useCallback((id) => {
    return new Promise((res, rej) => {
      if (!account) return res(0)
      if (current) {
        contract.userData(toUserDataQuery(account, id)).then(result => {
          res(result.numberEntries)
        }).catch(rej)
      } else {
        contract.getEntriesLengthForUser(id, account).then(result => {
          res(result.toNumber())
        }).catch(rej)
      }
    })
  }, [contract, account, current])
  const getRaffleWinner = useCallback((id) => {
    return new Promise((res, rej) => {
      contract.compWinner(id).then(result => {
        res(result)
      }).catch(rej)
    })
  }, [contract])
  const getAutoSettleTime = useCallback(() => {
    return new Promise((res, rej) => {
      contract.autoSettleTimer().then(result => {
        res(result.toNumber())
      }).catch(rej)
    })
  }, [contract])

  const codeAvailable = useCallback((code) => {
    return new Promise((res, rej) => {
      if (!current) return rej('Unsupported by old contract')
      if (!code) return rej('Invalid code')
      const bytes = toBytes32(code)
      contract.referrer(bytes).then(result => {
        if (result.referrerAddress === account) return res({ ownCode: true })
        if (!result.isValidReferrer || result.referrerAddress === `0x${Array(40).fill('0').join('')}`) return res({ ownCode: false })
        return res(false)
      }).catch(rej)
    })
  }, [account, contract, current])
  const enrollReferrer = useCallback((code) => {
    return new Promise((res, rej) => {
      if (!current) return rej('Unsupported by old contract')
      if (!code) return rej('Invalid code')
      contract.enrollReferrer(code).then(transaction => waitForResponse(transaction)).then(res).catch(rej)
    })
  }, [contract, current])
  const getReferrer = useCallback((code) => {
    return new Promise((res, rej) => {
      if (!current) return rej('Unsupported by old contract')
      if (!code) return rej('Invalid code')
      const bytes = toBytes32(code)
      contract.referrer(bytes).then(async result => {
        if (!result.isValidReferrer) return rej('Invalid referrer')
        const count = result.referralCount.toNumber()
        const referralEntries = Math.ceil(result.referralCredits.toNumber() / 1000)
        const referreeBonus = await contract.referreeBonus().catch(e => {
          console.error(e)
          return 0
        })
        const hasClaimed = !account ? true : await contract.hasBonused(account).catch(e => {
          console.error(e)
          return true
        })
        if (result.referrerAddress === account) {
          const earningRate = await contract.earningRate().then(rate => rate.toNumber()).catch(e => {
            console.error(e)
            return 0
          })
          return res({ referrer: account, freeEntries: referralEntries + (hasClaimed ? 0 : referreeBonus), isReferrer: true, hasClaimed, expires: Infinity, referreeBonus })
        } else if (account) {
          const expires = await contract.referralExpiration(bytes, account).then(time => time.toNumber() * 1000).catch(e => {
            console.error(e)
            return Infinity
          })
          res({ referrer: result.referrerAddress, freeEntries: referreeBonus, isReferrer: false, hasClaimed, expires, referreeBonus })
        } else {
          res({ referrer: result.referrerAddress, freeEntries: 0, isReferrer: false, hasClaimed: false, expires: Infinity, referreeBonus })
        }
      }).catch(rej)
    })
  }, [account, contract, current])
  const checkReferrer = useCallback((address) => {
    return new Promise((res, rej) => {
      if (!current) return rej('Unsupported by old contract')
      if (!address) return rej('Invalid address')
      contract.referrerId(address).then(code => {
        if (code === `0x${Array(40).fill('0').join('')}`) return res(null)
        contract.referrer(code).then(async result => {
          if (!result.isValidReferrer) return res(null)
          if (result.referrerAddress !== address) return res(null)
          const count = result.referralCount.toNumber()
          const referralEntries = Math.ceil(result.referralCredits.toNumber() / 1000)
          /*           const earningRate = await contract.earningRate().then(rate => rate.toNumber()).catch(e => {
                      console.error(e)
                      return 0
                    }) */
          return res({ code: ethers.utils.parseBytes32String(code), referrer: address, referrals: count, referralEntries, isReferrer: true })
        }).catch(rej)
      }).catch(rej)
    })
  }, [contract, current])
  const checkRefundAmounts = useCallback((id) => {
    return new Promise((res, rej) => {
      if (!current) return rej('Unsupported by old contract')
      if (!account) return rej('No account')
      contract.userData(toUserDataQuery(account, id)).then(async result => {
        res({ dust: new BigNumber(result.claimed ? 0 : result.dustSpent.toString()), eth: new BigNumber(result.claimed ? 0 : result.ethSpent.toString()) })
      }).catch(rej)
    })
  }, [contract, account, current])
  const claimRefund = useCallback((id) => {
    return new Promise((res, rej) => {
      if (!current) return rej('Unsupported by old contract')
      if (!account) return rej('No account')
      contract.claimRefund(id).then(transaction => waitForResponse(transaction)).then(res).catch(rej)
    })
  }, [contract, account, current])
  return { contract, checkRefundAmounts, claimRefund, enrollReferrer, getRaffle, getReferrer, codeAvailable, checkReferrer, getRaffleStatus, getAllRaffles, getDevFee, buyEntryDust, buyEntryETH, getUserEntryCount, getRaffleWinner, getAutoSettleTime }
}
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React()
  const { triggerSignIn } = useAuth()
  useEffect(() => {
    const { ethereum } = window
    if (ethereum && ethereum.on && !active && !error) {
      /*       const handleAccountsChanged = (accounts) => {
              if (!suppress) console.log("Handling 'accountsChanged' event with payload", accounts)
              if (accounts.length > 0) {
                activate(injected)
              }
            } */
      ethereum.on('accountsChanged', triggerSignIn)
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('accountsChanged', triggerSignIn)
        }
      }
    }
  }, [active, error, suppress, activate])
}
