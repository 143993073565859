import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";


export default function useGasPrice() {
    const { library } = useEthers();
    const [gasPrice, setGasPrice] = useState<BigNumber>(BigNumber.from(0));
    const [gettingGasPrice, setGettingGasPrice] = useState<boolean>(false);
    const [refreshCount, setRefreshCount] = useState<number>(0);
    useEffect(() => {
      if (library && !gettingGasPrice) {
        setGettingGasPrice(true)
        library.getGasPrice().then((res: BigNumber) => {
          setGasPrice(res);
        }).catch((e: Error) => {
          console.log(e)
        }).finally(() => setGettingGasPrice(false))
      }
    }, [library, refreshCount])
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshCount((count) => count + 1)
        }, 10000)
        return () => clearInterval(interval)
    }, [])
    return gasPrice
}