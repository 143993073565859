import { Auction, AuctionHouseContractFunction } from '../../wrappers/sweepersAuction';
import { connectContractToSigner, useEthers, useContractFunction } from '@usedapp/core';
import { useAppSelector } from '../../hooks';
import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react';
import { utils, BigNumber as EthersBN } from 'ethers';
import BigNumber from 'bignumber.js';
import classes from './Bid.module.css';
import { Spinner, InputGroup, FormControl, Button, Col } from 'react-bootstrap';
import { useAuctionMinBidIncPercentage } from '../../wrappers/sweepersAuction';
import { useAppDispatch } from '../../hooks';
import { AlertModal, setAlertModal } from '../../state/slices/application';
import { SweepersAuctionHouseFactory } from '@nouns/sdk';
import config from '../../config';
import WalletConnectModal from '../WalletConnectModal';
import SettleManuallyBtn from '../SettleManuallyBtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import InfoModal from '../InfoModal';
import IconFix from '../../icon';
import svgs from '../../svgs';
import { Bid as BidType } from '../../utils/types';

import { useAppContext } from '../../App';
import tryTWQ from '../../tryTWQ';
import ConnectedManuallyBtn from '../ConnectedManuallyBtn';

const { infoIcon } = svgs;
function InfoCircle() {
  return (<div style={{ width: '2rem', margin: '.2rem', maxWidth: '2rem' }}><IconFix svg={infoIcon} /></div>)
}
const computeMinimumNextBid = (
  currentBid: BigNumber,
  minBidIncPercentage: BigNumber | undefined,
): BigNumber => {
  if (!minBidIncPercentage) {
    return new BigNumber(0);
  }
  return currentBid
    .times(minBidIncPercentage.div(100).plus(1))
    .decimalPlaces(0, BigNumber.ROUND_CEIL);
};

//Using current bid, calculates fat finger threshold fo next bid
const computeFatFingerNextBid = (
  currentBid: BigNumber,
  minBidIncPercentage: BigNumber | undefined,
): BigNumber => {
  return !minBidIncPercentage ? new BigNumber(0) : currentBid.times(10);
};

const minBidEth = (minBid: BigNumber): string => {
  if (minBid.eq(0)) {
    return '0.01';
  }
  const eth = utils.formatEther(EthersBN.from(minBid.toString()));
  return parseFloat(new BigNumber(eth).toFixed(3, BigNumber.ROUND_CEIL)).toString();
};

const currentBid = (bidInputRef: React.RefObject<HTMLInputElement>) => {
  if (!bidInputRef.current || !bidInputRef.current.value) {
    return new BigNumber(0);
  }
  return new BigNumber(utils.parseEther(bidInputRef.current.value).toString());
};

const Bid: React.FC<{
  auction: Auction;
  auctionEnded: boolean;
}> = props => {
  const activeAccount = useAppSelector(state => state.account.activeAccount);
  const { library } = useEthers();
  const { auction, auctionEnded } = props;
  const appContext: any = useAppContext()
  const { reservePrice } = appContext
  const sweepersAuctionHouseContract = new SweepersAuctionHouseFactory().attach(
    config.addresses.sweepersAuctionHouseProxy,
  );

  const account = useAppSelector(state => state.account.activeAccount);

  const bidInputRef = useRef<HTMLInputElement>(null);

  const [bidInput, setBidInput] = useState('');
  const [bidButtonContent, setBidButtonContent] = useState({
    loading: false,
    content: auctionEnded ? 'Settle' : 'Place bid',
  });

  const [showConnectModal, setShowConnectModal] = useState(false);

  const hideModalHandler = () => {
    setShowConnectModal(false);
  };

  const dispatch = useAppDispatch();
  const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);

  const minBidIncPercentage = useAuctionMinBidIncPercentage();
  const minBid = computeMinimumNextBid(
    auction && new BigNumber(auction.amount.toString()),
    minBidIncPercentage,
  );

  const fatFingerBid = computeFatFingerNextBid(
    auction && auction.amount.gt(0) ? new BigNumber(auction.amount.toString()) : new BigNumber(reservePrice.toString()),
    minBidIncPercentage,
  );

  const { send: placeBid, state: placeBidState } = useContractFunction(
    sweepersAuctionHouseContract,
    AuctionHouseContractFunction.createBid,
  );
  const { send: settleAuction, state: settleAuctionState } = useContractFunction(
    sweepersAuctionHouseContract,
    AuctionHouseContractFunction.settleCurrentAndCreateNewAuction,
  );

  const bidInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // disable more than 2 digits after decimal point
    if (input.includes('.') && event.target.value.split('.')[1].length > 3) {
      return;
    }

    setBidInput(event.target.value);
  };

  const placeBidHandler = async () => {
    if (!auction || !bidInputRef.current || !bidInputRef.current.value) {
      return;
    }

    const currentMinBid = Number(auction.amount) > 0 ? minBid : reservePrice
    if (BigInt(currentBid(bidInputRef).toString()) < BigInt(currentMinBid)) {
      setModal({
        show: true,
        title: 'Insufficient bid amount',
        message: `Please place a bid higher than or equal to the minimum bid amount of ${minBidEth(
          currentMinBid,
        )} ETH.`,
      });
      setBidInput(minBidEth(currentMinBid));
      return;
    }

    const value = utils.parseEther(bidInputRef.current.value.toString());
    const contract = connectContractToSigner(sweepersAuctionHouseContract, undefined, library);
    const gasLimit = await contract.estimateGas.createBid(auction.sweeperId, {
      value,
    });

    const placeBidWarned = () => {
      placeBid(auction.sweeperId, {
        value,
        gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
      });
    };

    if (
      // minBid.gt(reservePrice) &&
      // value.gte(auction.amount.mul(10)) &&
      value.gte(fatFingerBid.toString())
    ) {
      setModal({
        show: true,
        title: `Woah there!`,
        message: `The bid you're about to place is ${utils.formatEther(
          value,
        )} Eth which is over 10x the bid before. Sure this wasn't fat-fingered?`,
        isActionPrompt: true,
        actionMessage: 'Place bid',
        action: placeBidWarned,
      });
    } else {
      tryTWQ('tw-od1d9-od1dl');
      placeBid(auction.sweeperId, {
        value,
        gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
      });
    }
  };

  const settleAuctionHandler = async () => {
    const contract = connectContractToSigner(sweepersAuctionHouseContract, undefined, library);
    const settlementAmount = auction.bidder === '0x0000000000000000000000000000000000000000' ? reservePrice : '0'
    const gasLimit = await contract.estimateGas.settleCurrentAndCreateNewAuction({ value: settlementAmount.toString() });
    settleAuction({ value: settlementAmount.toString(), gasLimit: gasLimit.add(25_000) });
  };

  const clearBidInput = () => {
    if (bidInputRef.current) {
      bidInputRef.current.value = '';
    }
  };

  // successful bid using redux store state
  useEffect(() => {
    if (!account) return;

    // tx state is mining
    const isMiningUserTx = placeBidState.status === 'Mining';
    // allows user to rebid against themselves so long as it is not the same tx
    const isCorrectTx = currentBid(bidInputRef).isEqualTo(new BigNumber(auction.amount.toString()));
    if (isMiningUserTx && auction.bidder === account && isCorrectTx) {
      placeBidState.status = 'Success';
      setModal({
        title: 'Success',
        message: `Bid was placed successfully!`,
        isMilestone: auction.amount.toString().includes('69'),
        show: true,
      });
      setBidButtonContent({ loading: false, content: 'Place bid' });
      clearBidInput();
    }
  }, [auction, placeBidState, account, setModal]);
  // placing bid transaction state hook
  useEffect(() => {
    switch (!auctionEnded && placeBidState.status) {
      case 'None':
        setBidButtonContent({
          loading: false,
          content: 'Place bid',
        });
        break;
      case 'Mining':
        setBidButtonContent({ loading: true, content: '' });
        break;
      case 'Fail':
        setModal({
          title: 'Transaction Failed',
          message: placeBidState.errorMessage ? placeBidState.errorMessage : 'Please try again.',
          show: true,
        });
        setBidButtonContent({ loading: false, content: 'Bid' });
        break;
      case 'Exception':
        setModal({
          title: 'Error',
          message: placeBidState.errorMessage ? placeBidState.errorMessage : 'Please try again.',
          show: true,
        });
        setBidButtonContent({ loading: false, content: 'Bid' });
        break;
    }
  }, [placeBidState, auctionEnded, setModal]);

  //TODO: Refactor Modal to utilitse new modal design
  const [showBidHistoryModal, setShowBidHistoryModal] = useState(false);
  const showBidModalHandler = () => {
    setShowBidHistoryModal(true);
  };
  const dismissBidModalHanlder = () => {
    setShowBidHistoryModal(false);
  };

  // settle auction transaction state hook
  useEffect(() => {
    switch (auctionEnded && settleAuctionState.status) {
      case 'None':
        setBidButtonContent({
          loading: false,
          content: 'Settle Auction',
        });
        break;
      case 'Mining':
        setBidButtonContent({ loading: true, content: '' });
        break;
      case 'Success':
        setModal({
          title: 'Success',
          message: `Settled auction successfully!`,
          show: true,
        });
        setBidButtonContent({ loading: false, content: 'Settle Auction' });
        break;
      case 'Fail':
        setModal({
          title: 'Transaction Failed',
          message: settleAuctionState.errorMessage
            ? settleAuctionState.errorMessage
            : 'Please try again.',
          show: true,
        });
        setBidButtonContent({ loading: false, content: 'Settle Auction' });
        break;
      case 'Exception':
        setModal({
          title: 'Error',
          message: settleAuctionState.errorMessage
            ? settleAuctionState.errorMessage
            : 'Please try again.',
          show: true,
        });
        setBidButtonContent({ loading: false, content: 'Settle Auction' });
        break;
    }
  }, [settleAuctionState, auctionEnded, setModal]);

  if (!auction) return null;

  const isDisabled = placeBidState.status === 'Mining' || settleAuctionState.status === 'Mining' || !activeAccount;

  const minBidCopy = <><span style={{ color: 'black' }}><FontAwesomeIcon icon={faEthereum} /></span> {minBidEth(auction.amount.eq(0) ? reservePrice : minBid)} ETH or more</>;

  const isWalletConnected = activeAccount !== undefined;

  return (
    <>
      {showBidHistoryModal && <InfoModal onDismiss={dismissBidModalHanlder} />}

      {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal onDismiss={hideModalHandler} />
      )}
      <InputGroup style={{ justifyContent: 'center' }}>
        {!auctionEnded && (
          <>
            <span style={{ position: 'absolute', top: '.9rem', left: '.55rem', color: '#00000060', fontSize: '1.1rem', whiteSpace: 'nowrap', maxWidth: '55%', overflow: 'hidden', zIndex: 1, fontWeight: '700', pointerEvents: 'none' }}>

              {!auctionEnded && !bidInput ? minBidCopy : ''}
            </span>
            <FormControl
              className={classes.bidInput}
              onWheel={event => event.currentTarget.blur()}
              type="number"
              min="0"
              onChange={bidInputHandler}
              ref={bidInputRef}
              value={bidInput}
            />
          </>
        )}
        {!auctionEnded ? (
          <>
            <Button
              className={auctionEnded ? classes.bidBtnAuctionEnded : classes.bidBtn}
              onClick={auctionEnded ? settleAuctionHandler : placeBidHandler}
              disabled={isDisabled}
            >
              {bidButtonContent.loading ? <Spinner animation="border" /> : bidButtonContent.content}
            </Button>
          </>
        ) : (
          <>
            {/* Only show force settle button if wallet connected */}
            {isWalletConnected ? (
              <Col lg={12}>
                <SettleManuallyBtn settleAuctionHandler={settleAuctionHandler} auction={auction} reservePrice={new BigNumber((reservePrice || EthersBN.from(0)).toString()).dividedBy(10**18).toString()} />
                <button style={{ fontWeight: '600' }} onClick={showBidModalHandler} className={classes.infoButton}>
                  <InfoCircle />
                  {` Bidding and settling`}
                </button>
              </Col>
            ) : (
              <>
                <Col lg={12}>
                <SettleManuallyBtn disabled settleAuctionHandler={settleAuctionHandler} auction={auction} reservePrice={new BigNumber((reservePrice || EthersBN.from(0)).toString()).dividedBy(10**18).toString()} />
                <ConnectedManuallyBtn  />
                  <button style={{ fontWeight: '600' }} onClick={showBidModalHandler} className={classes.infoButton}>
                    <InfoCircle />
                    {` Bidding and settling`}
                  </button>
                </Col>
              </>
            )}
          </>
        )}
      </InputGroup>

      {!auctionEnded ? (
        <Col lg={12} style={{ paddingTop: '0.5em', justifyContent: 'center' }}>
          <button style={{ fontWeight: '600' }} onClick={showBidModalHandler} className={classes.infoButton}>
            <InfoCircle />
            {` Bidding and settling`}
          </button>
        </Col>
      ) : (
        <></>
      )}
    </>
  );
};
export default Bid;
