"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@nouns/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = (0, addresses_1.getContractAddressesForChainOrThrow)(chainId);
    return {
        sweepersTokenContract: contracts_1.SweepersTokenFactory.connect(addresses.sweepersToken, signerOrProvider),
        sweepersAuctionHouseContract: contracts_1.SweepersAuctionHouseFactory.connect(addresses.sweepersAuctionHouseProxy, signerOrProvider),
        sweepersDescriptorContract: contracts_1.SweepersDescriptorFactory.connect(addresses.sweepersDescriptor, signerOrProvider),
        sweepersSeederContract: contracts_1.SweepersSeederFactory.connect(addresses.sweepersSeeder, signerOrProvider),
        SweepersTreasuryContract: contracts_1.SweepersGovernanceLogicV1Factory.connect(addresses.SweepersTreasuryProxy, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;
