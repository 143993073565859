import {
  Container,
  Col,
  Button,
  Image,
  Row,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import classes from './Playground.module.css';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Link from '../../components/Link';
import { ImageData, getSweeperData as getSweeperData, getRandomSweeperSeed } from '@nouns/assets';
import { buildSVG, EncodedImage, PNGCollectionEncoder } from '@nouns/sdk';
import InfoIcon from '../../assets/icons/Info.svg';
import Sweeper from '../../components/Sweeper';
import SweeperModal from './SweeperModal';
import { PNG } from 'pngjs';

interface Trait {
  title: string;
  traitNames: string[];
}

interface PendingCustomTrait {
  type: string;
  data: string;
  filename: string;
}

// const sweepersProtocolLink = (
//   <Link
//     text="Sweepers Protocol"
//     url="https://www.notion.so/Sweeper-Protocol-32e4f0bf74fe433e927e2ea35e52a507"
//     leavesPage={true}
//   />
// );

// const sweepersAssetsLink = (
//   <Link
//     text="sweepers-assets"
//     url="https://github.com/SweepersTreasury/sweepers-monorepo/tree/master/packages/sweepers-assets"
//     leavesPage={true}
//   />
// );

// const sweepersSDKLink = (
//   <Link
//     text="sweepers-sdk"
//     url="https://github.com/SweepersTreasury/sweepers-monorepo/tree/master/packages/sweepers-sdk"
//     leavesPage={true}
//   />
// );

const DEFAULT_TRAIT_TYPE = 'heads';

const encoder = new PNGCollectionEncoder(ImageData.palette);

const traitKeyToTitle: Record<string, string> = {
  heads: 'head',
  glasses: 'glasses',
  bodies: 'body',
  accessories: 'accessory',
};

const parseTraitName = (partName: string): string =>
  capitalizeFirstLetter(partName.substring(partName.indexOf('-') + 1));

const capitalizeFirstLetter = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

const Playground: React.FC = () => {
  const [sweeperSvgs, setSweeperSvgs] = useState<string[]>();
  const [traits, setTraits] = useState<Trait[]>();
  const [modSeed, setModSeed] = useState<{ [key: string]: number }>();
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [displaySweeper, setDisplaySweeper] = useState<boolean>(false);
  const [indexOfSweeperToDisplay, setIndexOfSweeperToDisplay] = useState<number>();
  const [selectIndexes, setSelectIndexes] = useState<Record<string, number>>({});
  const [pendingTrait, setPendingTrait] = useState<PendingCustomTrait>();
  const [isPendingTraitValid, setPendingTraitValid] = useState<boolean>();

  const customTraitFileRef = useRef<HTMLInputElement>(null);

  const generateSweeperSvg = React.useCallback(
    (amount = 1) => {
      for (let i = 0; i < amount; i++) {
        const seed = { ...getRandomSweeperSeed(), ...modSeed };
        const { parts, background } = getSweeperData(seed);
        const svg = buildSVG(parts, encoder.data.palette, background);
        setSweeperSvgs(prev => {
          return prev ? [svg, ...prev] : [svg];
        });
      }
    },
    [pendingTrait, modSeed],
  );

  useEffect(() => {
    const traitTitles = ['background', 'body', 'head', 'accessory', 'eyes', 'mouth'];
    const traitNames = [
      ...Object.values(ImageData.images).map(i => {
        return i.map((imageData: any)=> imageData.filename);
      }),
    ];
    setTraits(
      traitTitles.map((value, index) => {
        return {
          title: value,
          traitNames: traitNames[index],
        };
      }),
    );

    if (initLoad) {
      generateSweeperSvg(8);
      setInitLoad(false);
    }
  }, [generateSweeperSvg, initLoad]);

  const traitOptions = (trait: Trait) => {
    return Array.from(Array(trait.traitNames.length + 1)).map((_, index) => {
      const traitName = trait.traitNames[index - 1];
      const parsedTitle = index === 0 ? `Random` : parseTraitName(traitName);
      return (
        <option key={index} value={traitName}>
          {parsedTitle}
        </option>
      );
    });
  };

  const traitButtonHandler = (trait: Trait, traitIndex: number) => {
    setModSeed(prev => {
      // -1 traitIndex = random
      if (traitIndex < 0) {
        const state = { ...prev };
        delete state[trait.title];
        return state;
      }
      return {
        ...prev,
        [trait.title]: traitIndex,
      };
    });
  };

  const resetTraitFileUpload = () => {
    if (customTraitFileRef.current) {
      customTraitFileRef.current.value = '';
    }
  };

  let pendingTraitErrorTimeout: NodeJS.Timeout;
  const setPendingTraitInvalid = () => {
    setPendingTraitValid(false);
    resetTraitFileUpload();
    pendingTraitErrorTimeout = setTimeout(() => {
      setPendingTraitValid(undefined);
    }, 5_000);
  };

  const validateAndSetCustomTrait = (file: File | undefined) => {
    if (pendingTraitErrorTimeout) {
      clearTimeout(pendingTraitErrorTimeout);
    }
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      try {
          // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
        const buffer = Buffer.from(e?.target?.result!);
        const png = PNG.sync.read(buffer);
        if (png.width !== 32 || png.height !== 32) {
          throw new Error('Image must be 32x32');
        }
        const filename = file.name?.replace('.png', '') || 'custom';
        const data = encoder.encodeImage(filename, {
          width: png.width,
          height: png.height,
          rgbaAt: (x: number, y: number) => {
            const idx = (png.width * y + x) << 2;
            const [r, g, b, a] = [
              png.data[idx],
              png.data[idx + 1],
              png.data[idx + 2],
              png.data[idx + 3],
            ];
            return {
              r,
              g,
              b,
              a,
            };
          },
        });
        setPendingTrait({
          data,
          filename,
          type: DEFAULT_TRAIT_TYPE,
        });
        setPendingTraitValid(true);
      } catch (error) {
        setPendingTraitInvalid();
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadCustomTrait = () => {
    const { type, data, filename } = pendingTrait || {};
    if (type && data && filename) {
      const images = ImageData.images as Record<string, EncodedImage[]>;
      images[type].unshift({
        filename,
        data,
      });
      const title = traitKeyToTitle[type];
      const trait = traits?.find(t => t.title === title);

      resetTraitFileUpload();
      setPendingTrait(undefined);
      setPendingTraitValid(undefined);
      traitButtonHandler(trait!, 0);
      setSelectIndexes({
        ...selectIndexes,
        [title]: 0,
      });
    }
  };

  return (
    <>
      {displaySweeper && indexOfSweeperToDisplay !== undefined && sweeperSvgs && (
        <SweeperModal
          onDismiss={() => {
            setDisplaySweeper(false);
          }}
          svg={sweeperSvgs[indexOfSweeperToDisplay]}
        />
      )}

      <Container fluid="lg">
        <Row className="w100">
          <Col lg={12} className={classes.headerRow}>
            <span>Explore</span>
            <h1>Dust Pan</h1>
            <p>
            Welcome to the Dust Pan, and check out these wacky Sweeper characters! Here you can have some fun and play around with all the different traits and attributes that can be generated for potential Sweeper NFTs. 
            </p>
          </Col>
        </Row>
        <Row style={{alignItems: 'flex-start'}}>
          <Col lg={3}>
            <Col lg={12}>
              <Button
                onClick={() => {
                  generateSweeperSvg();
                }}
                className={classes.primaryBtn}
              >
                Generate Sweepers
              </Button>
            </Col>
            <Row>
              {traits &&
                traits.map((trait, index) => {
                  return (
                    <Col lg={12} xs={6}>
                      <Form className={classes.traitForm}>
                        <FloatingLabel
                          controlId="floatingSelect"
                          label={capitalizeFirstLetter(trait.title)}
                          key={index}
                          className={classes.floatingLabel}
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            className={classes.traitFormBtn}
                            value={trait.traitNames[selectIndexes?.[trait.title]] ?? -1}
                            onChange={e => {
                              const index = e.currentTarget.selectedIndex;
                              traitButtonHandler(trait, index - 1); // - 1 to account for 'random'
                              setSelectIndexes({
                                ...selectIndexes,
                                [trait.title]: index - 1,
                              });
                            }}
                          >
                            {traitOptions(trait)}
                          </Form.Select>
                        </FloatingLabel>
                      </Form>
                    </Col>
                  );
                })}
            </Row>
            <label style={{ margin: '1rem 0 .25rem 0' }} htmlFor="custom-trait-upload">
              Upload Custom Trait
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={
                  <Popover>
                    <div style={{ padding: '0.25rem' }}>Only 32x32 PNG images are accepted</div>
                  </Popover>
                }
              >
                <Image
                  style={{ margin: '0 0 .25rem .25rem' }}
                  src={InfoIcon}
                  className={classes.voteIcon}
                />
              </OverlayTrigger>
            </label>
            <Form.Control
              type="file"
              id="custom-trait-upload"
              accept="image/PNG"
              isValid={isPendingTraitValid}
              isInvalid={isPendingTraitValid === false}
              ref={customTraitFileRef}
              className={classes.fileUpload}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                validateAndSetCustomTrait(e.target.files?.[0])
              }
            />
            {pendingTrait && (
              <>
                <FloatingLabel label="Custom Trait Type" className={classes.floatingLabel}>
                  <Form.Select
                    aria-label="Custom Trait Type"
                    className={classes.traitFormBtn}
                    onChange={e => setPendingTrait({ ...pendingTrait, type: e.target.value })}
                  >
                    {Object.entries(traitKeyToTitle).map(([key, title]) => (
                      <option value={key}>{capitalizeFirstLetter(title)}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
                <Button onClick={() => uploadCustomTrait()} className={classes.primaryBtn}>
                  Upload
                </Button>
              </>
            )}
            <p className={classes.sweeperYearsFooter}>
              You've generated {sweeperSvgs ? (sweeperSvgs.length / (144 * 365)).toFixed(5) : '0'} years
              worth of Sweepers
            </p>
          </Col>
          <Col lg={9}>
            <Row>
              {sweeperSvgs &&
                sweeperSvgs.map((svg, i) => {
                  return (
                    <Col xs={4} lg={3} key={i}>
                      <div
                        onClick={() => {
                          setIndexOfSweeperToDisplay(i);
                          setDisplaySweeper(true);
                        }}
                      >
                        <Sweeper
                        showBanner={true}
                          imgPath={`data:image/svg+xml;base64,${btoa(svg)}`}
                          alt="sweeper"
                          className={classes.sweeperImg}
                          wrapperClassName={classes.sweeperWrapper}
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Playground;
