import React, { useEffect, useState } from 'react';
import classes from './AuctionNavigation.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAppContext } from '../../App';
import SVGs from '../../pages/Vault/game-svgs';
import { BigNumber } from 'ethers';
import { setOnDisplayAuctionSweeperId } from '../../state/slices/onDisplayAuction';
const { doubleArrow } = SVGs;
const AuctionNavigation = props => {
  const { isFirstAuction, isLastAuction, currentAuction, lastAuction, onPrevAuctionClick, onNextAuctionClick } = props;
  const dispatch = useAppDispatch();
  const [onAuction, setOnAuction] = useState(currentAuction)
  useEffect(() => {
    if (onAuction !== currentAuction) {
      setOnAuction(currentAuction)
    }
  }, [currentAuction])
  useEffect(() => {
    const auc = onAuction && typeof onAuction === 'number' ? BigNumber.from(onAuction) : null
    if (auc && auc !== currentAuction && pathname !== `/sweeper/${onAuction}`) {
      redirect(`/sweeper/${onAuction}`)
      if (pathname === '/') dispatch(setOnDisplayAuctionSweeperId(onAuction));
    }
  }, [onAuction])
  const { redirect, pathname } = useAppContext();
  const isCool = useAppSelector(state => state.application.stateBackgroundColor) === '#d5d7e1';
  return (
    <div className={classes.navArrowsContainer}>
      <button
        onClick={() => onPrevAuctionClick()}
        className={isCool ? classes.leftArrowCool : classes.leftArrowWarm}
        disabled={isFirstAuction}
      >
        ←
      </button>
      <input id="auction-page-select" type="number" value={onAuction} onChange={e => {
        if (!e.target.value) {
          return setOnAuction('')
        } else {
          let val = Math.abs(parseInt(e.target.value));
          let finalVal = Number.isNaN(val) ? currentAuction : val === 0 ? 1 : val > lastAuction ? currentAuction : val
          setOnAuction(finalVal)
        }
      }} />
      <button
        onClick={() => onNextAuctionClick()}
        className={isCool ? classes.rightArrowCool : classes.rightArrowWarm}
        disabled={isLastAuction}
      >
        →
      </button>
      <button disabled={isLastAuction} onClick={() => {
        return redirect(`/sweeper/${lastAuction}`)
      }} className={`column ${isCool ? classes.rightArrowCool : classes.rightArrowWarm}`}><div style={{ maxWidth: '1rem' }}>{doubleArrow}</div></button>
    </div>
  );
};
export default AuctionNavigation;