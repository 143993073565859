import { useMemo, useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { useEthers } from '@usedapp/core';
import { utils, BigNumber } from 'ethers';
import config from '../config';
import ERC721 from '../libs/abi/ERC721.json';

const { addresses } = config;

const erc721Interface = new utils.Interface(ERC721);

function useSweepersBalance(user: string | null | undefined): { balance: number, loading: boolean, refresh: () => void } {
  const { library } = useEthers();

  const [balance, setBalance] = useState(0);
  const [gettingBalance, setGettingBalance] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const sweepersContract = useMemo((): Contract | undefined => {
    if (!library || !addresses.sweepersToken) return;
    return new Contract(addresses.sweepersToken, erc721Interface, library);
  }, [library]);

  useEffect(() => {
    if (sweepersContract && !gettingBalance) {
      setGettingBalance(true);
      sweepersContract.balanceOf(user).then((big: BigNumber) => setBalance(big.toNumber())).catch((e: Error) => {
        console.error(e);
        setBalance(0);
      }).finally(() => setGettingBalance(false));
    }
  }, [sweepersContract, refreshCount]);

  return { balance, loading: gettingBalance, refresh: () => setRefreshCount(refreshCount + 1) };
}

export default useSweepersBalance;
