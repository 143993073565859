import React, { useState, useMemo } from 'react';
import { useAppContext } from '../../App';
import './paginator.css'
import svgs from './game-svgs'

const { leftPage, rightPage } = svgs
export default function usePagination(items = [], itemsPerPage = 5) {
    const { width } = useAppContext()
    const [currentPage, setCurrentPage] = useState(1)
    const minideckSize = useMemo(() => width > 800 ? 5 : 2, [width])
    const totalPages = useMemo(() => {
        return Math.ceil(items.length / itemsPerPage)
    }, [itemsPerPage, items])
    const shownItems = useMemo(() => {
        if (currentPage > totalPages) setCurrentPage(1)
        return items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    }, [items, itemsPerPage, currentPage, totalPages])
    const maxDeckCount = useMemo(() => Math.min(minideckSize, totalPages - 2), [minideckSize, totalPages])
    const Paginator = useMemo(() => {
        return totalPages > 1 ? <div className="pagination">
            <div className="arrow pointer" onClick={() => {
                if (currentPage > 1) setCurrentPage(a => a - 1)
            }}>{leftPage}</div>
            <span className={`page-number ${currentPage === 1 ? 'active' : ''}`} onClick={() => setCurrentPage(1)}>1</span>
            {totalPages === 3 && <span className={`page-number ${currentPage === 2 ? 'active' : ''}`} onClick={() => setCurrentPage(2)}>2</span>}
            {maxDeckCount > 3 && currentPage >= minideckSize - 1 && <span>...</span>}
            {totalPages > 3 && Array(maxDeckCount).fill('').map((u, i) => { let n = currentPage + i - 1; return n < totalPages && n > 1 && (<span key={i} onClick={() => setCurrentPage(n)} className={`page-number ${currentPage === n ? 'active' : ''}`}>{n}</span>) })}
            {totalPages > 3 && currentPage < totalPages - maxDeckCount + 1 && <span>...</span>}
            {totalPages > 1 ? <span className={`page-number ${currentPage === totalPages ? 'active' : ''}`} onClick={() => setCurrentPage(totalPages)}>{totalPages}</span> : null}
            <div className="arrow pointer" onClick={() => {
                if (currentPage < totalPages) setCurrentPage(a => a + 1)
            }}>{rightPage}</div>
        </div> : null
    }, [currentPage, totalPages, maxDeckCount, minideckSize])
    
    return { Paginator, items: shownItems, currentPage, setCurrentPage }
}