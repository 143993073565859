import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRaffleContract } from '../../web3-hooks';
import svgs from '../Vault/game-svgs'
import './index.css'
import copy from '../../copy'
import CarrotPosition from './carrot-position'
import { toTimeLength } from '../Garage';
import { useAppContext } from '../../App';
import WalletConnectButton from '../../components/NavWallet/WalletConnectButton';
import WalletConnectModal from '../../components/WalletConnectModal';
import { treasuryWallet } from '../../config';

const { greenCheck, copyIcon } = svgs
const defaultValue = 'EX. BANANAS'
const Referral = () => {
    const { account } = useAppContext()
    const { contract, codeAvailable, enrollReferrer, checkReferrer } = useRaffleContract(true)
    const [code, setCode] = useState(defaultValue)
    const [warning, setWarning] = useState('')
    const [success, setSuccess] = useState('')
    const [canEdit, setCanEdit] = useState(treasuryWallet)
    const [checkingCode, setCheckingCode] = useState(false)
    const [freeCount, setFreeCount] = useState(0)
    const [earningRate, setEarningRate] = useState(0)
    const [referralPeriod, setReferralPeriod] = useState(0)
    const [loadingRates, setLoadingRates] = useState(false)
    const [enrolling, setEnrolling] = useState(false)
    const [isValid, setIsValid] = useState(false)
    useEffect(() => {
        if (account && contract && code && code !== defaultValue && !checkingCode) {
            setCheckingCode(true)
            codeAvailable(code).then((available) => {
                if (!available) {
                    setWarning('Code already taken')
                    setSuccess('')
                    setCanEdit(true)
                    setIsValid(false)
                } else if (available.ownCode) {
                    setWarning('')
                    setSuccess('Your code has been set.')
                    setIsValid(true)
                    setCanEdit(false)
                } else {
                    setWarning('')
                    setSuccess('Your code looks great!')
                    setIsValid(true)
                    setCanEdit(true)
                }
            }).catch(e => {
                console.log('code check error', e)
                setWarning('Error checking code')
                setSuccess('')
            }).finally(() => setCheckingCode(false))
        } else if (contract && account && code === defaultValue && !checkingCode) {
            setCheckingCode(true)
            checkReferrer(account).then((exists) => {
                if (exists && exists.code) {
                    setWarning('')
                    setCode(exists.code)
                    setCanEdit(false)
                    setSuccess('Your code has been set.')
                    setIsValid(true)
                }
            }).catch(e => {
                console.log('code check error', e)
            }).finally(() => setCheckingCode(false))
        } else if (code === defaultValue) {
            setWarning('')
            setSuccess('')
            setCanEdit(true)
        }
    }, [contract, code, account])
    useEffect(() => {
        if (contract && !loadingRates) {
            setLoadingRates(true)
            Promise.all([contract.referreeBonus(), contract.earningRate(), contract.referralPeriod()]).then(([freeCount, earningRate, referralPeriod]) => {
                setFreeCount(freeCount)
                setEarningRate(earningRate.toNumber())
                setReferralPeriod(referralPeriod.toNumber() * 1000)
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                setLoadingRates(false)
            })
        }
    }, [contract])
    const setReferralCode = useCallback(() => {
        if (contract && code && code !== defaultValue && !enrolling) {
            setEnrolling(true)
            enrollReferrer(code).then(() => {
                setCanEdit(false)
            }).catch(e => {
                console.log(e)
                setWarning('Error enrolling. Please refresh and try again.')
            }).finally(() => {
                setEnrolling(false)
            })
        }
    }, [contract, code])
    const referralRef = useRef()
    const referralInput = useMemo(() => {
        return (<h5 ref={referralRef} style={{ cursor: canEdit ? 'text' : 'not-allowed', flexGrow: 3 }} contentEditable={canEdit} onFocus={() => {
            if (code === defaultValue) setCode('')
        }} onBlur={() => {
            if (!code) setCode(defaultValue)
        }} onInput={() => {
            const carrotPosition = CarrotPosition.saveSelection(referralRef.current)
            setCheckingCode(false);
            setCode(referralRef.current.innerText.toUpperCase())
            setTimeout(() => {
                CarrotPosition.restoreSelection(referralRef.current, carrotPosition)
            }, 5)
        }}>{code}</h5>)
    }, [code, canEdit])
    const [showConnectModal, setShowConnectModal] = useState(false)
    return (<div id="referral" className="column w100 jfs afs">
        {showConnectModal && !account && <WalletConnectModal onDismiss={() => setShowConnectModal(false)} />}
        <span className="page-master-head">Referral Program</span>
        <h2 style={{ fontFamily: 'cafeteria' }}>Earn Bonus Entries Using Referrals</h2>
        <div className="referral-container column gap jfs afs">
            <h3 style={{ fontWeight: '600' }}>Generate Your Referral Code</h3>
            <div className="format-me column w100 afs jfs">
                <h5>What does a referral code do?</h5>
                <p>A referral code allows you and the person you refer to get free bonus entries. The person you refer to a Sweepers listing will receive {freeCount} bonus entr{freeCount === 1 ? 'y' : 'ies'} in 1 competition. You will get earn 1 free entry for every {earningRate} entries that are purchased using your referral code. Once a user completes their purchase using your code, you will continue to earn free entries for every entry that they purchase for {toTimeLength(referralPeriod, true, false, true)}.</p>
            </div>
            <div className="format-me column w100 afs jfs">
                <h5>How do I make a referral code?</h5>
                <p>Please enter you desired referral code in the field below. This must be unique and distinct from other users referral codes.</p>
            </div>
            {!account ? <div className="column w100">
                <h5>Please connect your wallet to generate a referral code.</h5>
                <WalletConnectButton onClickHandler={() => setShowConnectModal(true)} />
            </div> : <>
                <span className="warn">NOTE: This cannot be changed later.</span>
                <div className="column w100 afs jfs" style={{ marginTop: '1rem' }}>
                    <label style={{ fontWeight: '600' }}>Your referral code:</label>
                    <div className="b2 jsb gap">
                        <div className="referral-input gap">
                            {referralInput}
                            {!canEdit && <div className="b2 jfs gap" style={{ cursor: 'copy', flexGrow: 4 }} onClick={() => {
                                copy(`https://sweepersclub.com/vault?code=${code}`)
                                setSuccess('Copied to clipboard!')
                            }}><span>Copy Link</span><div className="copy-icon">{copyIcon}</div></div>}
                            {isValid && <div className="referral-check"><div className="checkmark">{greenCheck}</div></div>}
                        </div>
                        <button onClick={setReferralCode} className="referralButton" disabled={!canEdit || enrolling || !code || code === defaultValue}>{enrolling ? 'Creating...' : 'Create'}</button>
                    </div>
                    {warning && <span className="warn">{warning}</span>}
                    {success && <span className="success">{success}</span>}
                </div>
            </>}
        </div>
    </div>)
}
export default Referral;