import { Auction } from '../../wrappers/sweepersAuction';
import { useState, useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { BigNumber as ETHNum } from 'ethers';
import { Row, Col } from 'react-bootstrap';
import classes from './AuctionActivity.module.css';
import bidHistoryClasses from './BidHistory.module.css';
import Bid from '../Bid';
import AuctionTimer from '../AuctionTimer';
import CurrentBid from '../CurrentBid';
import Winner from '../Winner';
import BidHistory from '../BidHistory';
import AuctionNavigation from '../AuctionNavigation/index.jsx';
import AuctionActivityWrapper from '../AuctionActivityWrapper';
import AuctionTitleAndNavWrapper from '../AuctionTitleAndNavWrapper';
import AuctionActivitySweeperTitle from '../AuctionActivitySweeperTitle';
import AuctionActivityDateHeadline from '../AuctionActivityDateHeadline';
import BidHistoryBtn from '../BidHistoryBtn';
import config from '../../config';
import { buildEtherscanAddressLink } from '../../utils/etherscan';
import SweeperInfoCard from '../SweeperInfoCard';
import { useAppSelector } from '../../hooks';
import Svgs from '../../pages/Vault/game-svgs'
import BidHistoryModal from '../BidHistoryModal';
import { Bid as BidType } from '../../utils/types';
import { useAuctionBids } from '../../wrappers/onDisplayAuction';
import { useAppContext } from '../../App';
const openEtherscanBidHistory = () => {
  const url = buildEtherscanAddressLink(config.addresses.sweepersAuctionHouseProxy);
  window.open(url);
};

interface AuctionActivityProps {
  auction: Auction;
  isFirstAuction: boolean;
  isLastAuction: boolean;
  lastAuctionId: number;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
  displayGraphDepComps: boolean;
}
interface ButtonProps {
  className: any;
  children: any;
  onClick: any;
}
const linkTo = (link: any) => {
  const a = document.createElement('a')
  a.href = link
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  a.className = 'hidden'
  document.body.appendChild(a)
  a.click()
  a.remove()
};

const { openSea, etherscan: etherscanLogo } = Svgs;

function Button({ className, children, onClick }: ButtonProps) {
  return <button className={`exchange-button ${className || ''}`} onClick={onClick}>{children}</button>
};
const AuctionActivity: React.FC<AuctionActivityProps> = (props: AuctionActivityProps) => {
  const {
    auction,
    isFirstAuction,
    isLastAuction,
    lastAuctionId,
    onPrevAuctionClick,
    onNextAuctionClick,
    displayGraphDepComps,
  } = props;
  const appContext: any = useAppContext()
  const { winnerSettlementTime, freeForAllSettlementTime } = appContext
  const isCool = useAppSelector(state => state.application.isCoolBackground);
  const [auctionEnded, setAuctionEnded] = useState(false);
  const [auctionTimer, setAuctionTimer] = useState(false);
  const previousBids = useAuctionBids(ETHNum.from(lastAuctionId));
  const [showBidHistoryModal, setShowBidHistoryModal] = useState(false);
  const showBidModalHandler = () => {
    setShowBidHistoryModal(true);
  };
  const dismissBidModalHanlder = () => {
    setShowBidHistoryModal(false);
  };
  // timer logic - check auction status every 30 seconds, until five minutes remain, then check status every second
  useEffect(() => {
    if (!auction) return;

    const timeLeft = Number(auction.endTime) - Math.floor(Date.now() / 1000);

    if (auction && timeLeft <= 0) {
      setAuctionEnded(true);
    } else {
      setAuctionEnded(false);
      const timer = setTimeout(
        () => {
          setAuctionTimer(!auctionTimer);
        },
        timeLeft > 300 ? 30000 : 1000,
      );

      return () => {
        clearTimeout(timer);
      };
    }
  }, [auctionTimer, auction]);
  const settlementIntervals = useMemo(() => {
    return {
      normal: freeForAllSettlementTime,
      winner: winnerSettlementTime
    }
  }, [freeForAllSettlementTime, winnerSettlementTime]);
  if (!auction) return null;
  return (
    <>
      {showBidHistoryModal && (
        <BidHistoryModal onDismiss={dismissBidModalHanlder} auction={auction} />
      )}

      <AuctionActivityWrapper>
        <div className={classes.informationRow}>
          <Row className={classes.activityRow}>
            <div className="b2 jfs gap pad w100">
              {displayGraphDepComps && (
                <AuctionNavigation
                  isFirstAuction={isFirstAuction}
                  isLastAuction={isLastAuction}
                  lastAuction={lastAuctionId}
                  currentAuction={auction.sweeperId}
                  onNextAuctionClick={onNextAuctionClick}
                  onPrevAuctionClick={onPrevAuctionClick}
                />
              )}
              <AuctionActivityDateHeadline startTime={auction.startTime} />
            </div>
            <Col lg={12}>
              <AuctionActivitySweeperTitle isCool={isCool} sweeperId={auction.sweeperId || ETHNum.from(0)} />
            </Col>
          </Row>
          <Row className={classes.activityRow}>
            <Col lg={4} className={classes.currentBidCol}>
              <CurrentBid
                currentBid={auction.settled ? (((auction.sweeperId?.toNumber() || 0) > 2537 && auction.sniped) ? new BigNumber(auction.snipedAmount?.toString() || '0') : new BigNumber(auction.amount?.toString() || '0')) : new BigNumber(auction.amount.toString())}
                auctionEnded={auctionEnded}
              />
            </Col>
            <Col lg={6} className={classes.auctionTimerCol}>
              {auctionEnded ? (
                auction.amount.gt(0) || ((auction?.sweeperId?.toNumber() || 0) > 2537 && auction.snipedBy) ? <Winner winner={auction.snipedBy || auction.bidder} /> : <Winner winner={'0x0000000000000000000000000000000000000000'} />
              ) : (
                <AuctionTimer auction={auction} auctionEnded={auctionEnded} />
              )}
            </Col>
          </Row>
        </div>
        {isLastAuction && (
          <>
            <Row className={classes.activityRow}>
              <Col lg={12}>
                <Bid auction={auction} auctionEnded={auctionEnded} />
              </Col>
            </Row>
          </>
        )}
        <div className="column w100 afs jfs">
          {!isLastAuction ? (
            <SweeperInfoCard
              sweeperId={auction.sweeperId?.toNumber() || 0}
              bidHistoryOnClickHandler={showBidModalHandler}
            />
          ) : (
            displayGraphDepComps && (
              <BidHistory
                auctionId={auction.sweeperId?.toString() || '0'}
                max={3}
                classes={bidHistoryClasses}
                auction={auction}
                pastBids={previousBids?.slice(0, 3)}
              />
            )
          )}
          {/* If no bids, show nothing. If bids avail:graph is stable? show bid history modal,
            else show etherscan contract link */}
          {isLastAuction &&
            !auction.amount.eq(0) &&
            (displayGraphDepComps ? (
              <BidHistoryBtn onClick={showBidModalHandler} />
            ) : (
              <BidHistoryBtn onClick={openEtherscanBidHistory} />
            ))}
        </div>
        {/* {auctionEnded && (<div className="column w100">
          <Col lg={12}>
            <Button className="sea-button w100" onClick={() => linkTo(`https://opensea.io/assets/ethereum/0x2276c60f53c9a807e182d112f9b37d7277463fec/${auction?.sweeperId}`)}>
              <div className="sea-icon">{openSea}</div>
              <span>View on OpenSea</span>
            </Button>
          </Col>
        </div>)} */}
      </AuctionActivityWrapper>
    </>
  );
};

export default AuctionActivity;
