import React, { useState, useEffect, useCallback, useLayoutEffect, useMemo, useReducer } from 'react'
import { Paralax } from '../Vault'
import BigNumber from 'bignumber.js'
import { toTopia, useExchangeContract, useGameApproval, EXCHANGE, multicall, ExchangeABI, useEthToUSD } from '../../web3-hooks'
import { useAppContext } from '../../App'
import Svgs from '../Vault/game-svgs'
import useSweepersBalance from '../../hooks/useSweepersBalance';
import usePagination from '../Vault/paginator'
import ShortAddress from '../../components/ShortAddress'
BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
    FORMAT: {
        prefix: '',
        decimalSeparator: '.',
        groupSeparator: '',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: '',
        fractionGroupSize: 0,
        suffix: ''

    }
})
const DECIMALS = 6
const PERPAGE = 12
/* 
    
    The UI wants the data like this:

    Offer {
        id: ethers.BigNumber
        listing: number
        price: BigNumber
        status: 'active' | 'canceled' | 'outOffered' | 'accepted'
    }

    Listing {
        id: ethers.BigNumber
        amount: BigNumber
        price: BigNumber
        status: 'settled' | 'canceled' | 'failed' | 'active'
        startTime: number
        endTime: number
        offerer: string
        currentOffer: BigNumber | null
        previousOffer: BigNumber | null
        activeOfferId: number | null
        offerCount: number
        offers: number[]
    }

*/

const { grid: gridIcon, table: tableIcon, refresh: refreshIcon, rightPage, leftPage, carrot, eyeBall } = Svgs
const shortAddress = (address = '') => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`
}
function Container({ id, className, children }) {
    return <div id={id} className={`exchange-container ${className || ''}`}>{children}</div>
}
function Button({ id, className, children, onClick, alt }) {
    return <button id={id} className={`exchange-button ${alt === 2 ? 'alt-2' : alt ? 'alt' : ''} ${className || ''}`} onClick={onClick}>{children}</button>
}
function BuyNow({ id, price, className, alt, onBought }) {
    const [buying, setBuying] = useState(false)
    const { flash } = useAppContext()
    const { buyNow } = useExchangeContract()
    return <Button onClick={() => {
        if (buying) return
        setBuying(true)
        buyNow(id, price?.toString()).then(() => {
            if (typeof onBought === 'function') onBought()
        }).catch(e => {
            console.error(e)
            flash(e.message)
        }).finally(() => setBuying(false))
    }} className={className} alt={alt}>{buying ? 'Buying...' : 'Buy Now'}</Button>
}
function CancelListing({ id, onCancelled }) {
    const { flash } = useAppContext()
    const { cancelListing } = useExchangeContract()
    const [cancelling, setCancelling] = useState(false)
    return <Button onClick={() => {
        if (cancelling) return
        setCancelling(true)
        cancelListing(id).then(() => {
            if (typeof onCancelled === 'function') onCancelled()
        }).catch(e => {
            console.error(e)
            flash(e.message)
        }).finally(() => setCancelling(false))
    }}>{cancelling ? 'Cancelling...' : 'Cancel Listing'}</Button>
}
function ClaimRefund({ id, onClaimed }) {
    const { flash } = useAppContext()
    const { claimRefund } = useExchangeContract()
    const [claiming, setClaiming] = useState(false)
    return <Button onClick={() => {
        if (claiming) return
        setClaiming(true)
        claimRefund(id).then(() => {
            if (typeof onClaimed === 'function') onClaimed()
        }).catch(e => {
            console.error(e)
            flash(e.message)
        }).finally(() => setClaiming(false))
    }}>{claiming ? 'Claiming...' : 'Claim Refund'}</Button>
}
const TimeSpan = React.memo(({ endTime: time }) => {
    const [remaining, setRemaining] = useState((time || new Date().getTime()) - new Date().getTime())
    useLayoutEffect(() => {
        setRemaining((time || new Date().getTime()) - new Date().getTime())
        const interval = setInterval(() => {
            setRemaining(t => t - 1000)
        }, 1000)
        return () => clearInterval(interval)
    }, [time])
    let days = Math.floor(remaining / (1000 * 60 * 60 * 24))
    let hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((remaining % (1000 * 60)) / 1000)
    return <span>
        {days > 0 || minutes > 0 || hours > 0 || seconds > 0 ? <>
            {days > 0 && `${days}D`}{hours > 0 && `${days ? '-' : ''}${hours}H`}{minutes > 0 && `${hours || days ? '-' : ''}${minutes}M`}{seconds > 0 && `${minutes || hours || days ? '-' : ''}${seconds}S`}
        </> : <>Ended</>}
    </span>
})
const toDateValue = date => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}
function CreateListing({ create, close, minListPrice }) {
    const { flash, balance, refreshBalance } = useAppContext()
    const { approved, checkingApproval, approving, approveTopia } = useGameApproval(EXCHANGE)
    const [creating, setCreating] = useState(false)
    const [formData, setFormData] = useReducer((state, change) => {
        if (change.price) {
            let pricePerTopia = state.amount / change.price
            return { ...state, ...change, pricePerTopia }
        } else if (change.amount) {
            let pricePerTopia = change.amount / state.price
            return { ...state, ...change, pricePerTopia }
        } else if (change.date) {
            return { ...state, ...change }
        } else {
            return state
        }
    }, { amount: new BigNumber(0), price: new BigNumber(0), pricePerTopia: new BigNumber(0), date: new Date(new Date().getTime() + 1000 * 60 * 60 * 24) })
    const [error, setError] = useState('')
    const [calendarOpen, setCalendarOpen] = useState(false)
    const er = useCallback((message = '') => {
        flash(message)
        setError(message)
        setTimeout(() => setError(''), 5000)
    }, [setError])
    return (<div id="create-listing" onClick={e => {
        if (e.currentTarget === e.target && !creating && !calendarOpen) close()
    }}>
        <div className="pointer close-button" onClick={close}>X</div>
        <div className="create-listing-form column jfs afs">
            <h1 className="bolden">Create Listing</h1>
            <span>Create your listings below. By offering $DUST you relinquish your $DUST to the Exchange vault. This $DUST is then held until purchased. You can retrieve your offer if you change your mind later. Once someone has accpeted your offer your ETH will then be immediately transfered to your wallet.</span>
            <span className={`${error ? 'warn' : 'hidden'}`}>{error}</span>
            <div className="column w100 " style={{ marginTop: '.5rem' }}>
                <div className="row w100 jfs afs">
                    <div className="row w100">
                        <label htmlFor="amount">$DUST for Listing</label>
                        <span>Current Balance: {balance ? new BigNumber(balance.toString()).dividedBy(10 ** 18).toFixed(2) : 0} $DUST</span>
                    </div>
                    <input type="number" value={formData.amount.toString()} onChange={(e) => setFormData({ amount: e.target.value })} id="amount" />
                </div>
                <div className="row w100 jfs afs">
                    <label htmlFor="price">ETH Requested</label>
                    <input type="number" value={formData.price.toString()} onChange={(e) => {
                        setFormData({ price: e.target.value })
                    }} id="price" />
                </div>
                <div className="row jfs afs w100">
                    <label style={{ whiteSpace: 'nowrap' }} htmlFor="price">Sale Ends:</label>
                    <input onFocus={() => {
                        setCalendarOpen(true)
                    }} onBlur={() => {
                        setTimeout(() => {
                            setCalendarOpen(false)
                        }, 500)
                    }} type="datetime-local" value={toDateValue(formData.date)} onChange={(e) => {
                        setFormData({ date: new Date(e.target.value) })
                    }} id="price" />
                </div>
                <span>$DUST per ETH: {formData.pricePerTopia.toString()} by {formData.date.toLocaleString()}</span>
                <div className="cta-buttons">
                    <Button disabled={checkingApproval || creating} className="cta-button" onClick={approved?.topia ? () => {
                        let amount = new BigNumber(formData.amount).multipliedBy(10 ** 18)
                        let bigAmount = BigInt(formData.amount * (10 ** 18))
                        let requested = new BigNumber(formData.price).multipliedBy(10 ** 18)
                        if (requested.multipliedBy(10 ** 9).dividedBy(amount).isLessThan(minListPrice)) return er(`Minimum $DUST per ETH is ${(new BigNumber(10 ** 9).dividedBy(minListPrice)).toFixed(0)}`)
                        setCreating(true)
                        create(bigAmount.toString(), requested.toString(), Math.floor(formData.date / 1000).toString()).then(created => {
                            console.log('created listing', created)
                            refreshBalance()
                            close()
                        }).catch(e => {
                            console.error(e)
                            er(e.message)
                        }).finally(() => setCreating(false))
                    } : approveTopia}>{checkingApproval ? 'Checking Approval...' : approving ? 'Approving...' : !approved?.topia ? 'Approve' : creating ? 'Creating' : 'Create'}</Button>
                    <Button disabled={creating} className="cta-button" alt onClick={close}>Cancel</Button>
                </div>
            </div>
        </div>
    </div>)
}
function CreateOffer({ create, close, listing, minOfferPercentage, minOfferIncrementPercentage }) {
    const minOffer = listing.currentOffer == 0 ? toTopia(listing.price.multipliedBy(.6)) : toTopia(listing.currentOffer.plus(listing.currentOffer.multipliedBy(minOfferIncrementPercentage).dividedBy(10000)))
    const { flash } = useAppContext()
    const [creating, setCreating] = useState(false)
    const [error, setError] = useState('')
    const [amount, setAmount] = useState(minOffer)
    const er = useCallback((message = '') => {
        setError(message)
        flash(message)
        setTimeout(() => setError(''), 5000)
    }, [setError])
    return (<div id="create-offer" onClick={e => {
        if (e.currentTarget === e.target && !creating) close()
    }}>
        <div className="pointer close-button" onClick={close}>X</div>
        <div className="create-offer-form column jfs afs">
            <h1 className="bolden">Create Offer</h1>
            <span>Create your offer below. By creating an offer you announcing that you are willing to pay X amount of  ethereum for X amount of $DUST. If the holder accepts your offer the $DUST will be automatically transfered your wallet.</span>
            <span className={error ? 'warn' : 'hidden'}>{error}</span>
            <div className="column w100 jfs afs" style={{ marginTop: '.5rem' }}>
                <div className="row w100 jfs afs">
                    <label htmlFor="amount">$DUST on Offer</label>
                    <h3>{parseFloat(toTopia(listing.amount, DECIMALS))} $DUST</h3>
                </div>
                <div className="b2 jfs w100 gap-large flip-mob">
                    <label style={{ whiteSpace: 'nowrap' }} htmlFor="price">ETH to Offer</label>
                    <input type="number" value={amount.toString()} onChange={(e) => {
                        setAmount(e.target.value)
                    }} id="price" />
                </div>
                <span>$DUST per ETH: {listing.amount.dividedBy(amount).dividedBy(10 ** 18).toString()}</span>
                {listing.currentOffer == 0 ? <span>Minimum offer is {toTopia(listing.price.multipliedBy(.6))} ETH</span> : <span>Minimum offer is {toTopia(listing.currentOffer.plus(listing.currentOffer.multipliedBy(minOfferIncrementPercentage).dividedBy(10000)))} ETH</span>}
                {/* {listing.currentOffer == 0 ? <span></span> : <span>Minimum offer is {toTopia(listing.currentOffer.plus(listing.currentOffer.multipliedBy(minOfferIncrementPercentage).dividedBy(10000)))} ETH</span>} */}
                <div className="column w100">
                    <div className="cta-buttons">
                        <Button disabled={creating} className="cta-button" onClick={() => {
                            let total = new BigNumber(amount * 10 ** 18)
                            // if (listing.price.multipliedBy(.5).isGreaterThan(total)) return er(`Minimum offer is ${toTopia(listing.price.multipliedBy(.5))} ETH`)
                            // if (listing.currentOffer.plus(listing.currentOffer.multipliedBy(minOfferIncrementPercentage / 10000)).isGreaterThan(total)) return er(`Offer must be at least ${toTopia(listing.currentOffer.plus(listing.currentOffer.multipliedBy(minOfferIncrementPercentage).dividedBy(10000)))} ETH`)
                            if (amount < minOffer) return er(`Offer must be at least ${listing.currentOffer == 0 ? toTopia(listing.price.multipliedBy(.6)) : toTopia(listing.currentOffer.plus(listing.currentOffer.multipliedBy(minOfferIncrementPercentage).dividedBy(10000)))} ETH`)
                            setCreating(true)
                            create(total.toString()).then(created => {
                                console.log('created offer', created)
                                close()
                            }).catch(e => {
                                console.error(e)
                                er(e.message)
                            }).finally(() => setCreating(false))
                        }}>{creating ? 'Creating' : 'Create'}</Button>
                        <Button disabled={creating} className="cta-button" alt onClick={close}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
const sortKey = 'EXCHANGE_SORT_MODE'
const displayKey = 'EXCHANGE_DISPLAY_MODE'
const possibleOfferStatuses = ['active', 'canceled', 'outOffered', 'accepted']
const possibleListingsStatuses = ['settled', 'canceled', 'failed', 'active']
const statKeys = ['7-Day Amount Sold', '7-Day Average Price/Amount Sold', '7-Day Amount Sold in USD', '7-Day Average Amount Sold Per Listing', '7-Day Average USD/$DUST']
function Listing({ showListing, minOfferPercentage, minOfferIncrementPercentage }) {
    const { redirect, account, refreshBalance } = useAppContext()
    const { contract, getOfferInfo, cancelOffer, acceptOffer, createOffer, getOffers, cancelListing, claimRefund } = useExchangeContract()
    const offers = useMemo(() => {
        return showListing?.offers.map(a => ({ ...a, price: new BigNumber(a.price) })).reverse() || []
    }, [showListing])
    const [gotOffers, setGotOffers] = useState(false)
    const [refreshOffersCount, setRefreshOffersCount] = useState(0)
    const [loadingOffers, setLoadingOffers] = useState(false)
    const [showCreateOffer, setShowCreateOffer] = useState(false)
    const refreshOffers = useCallback(() => setRefreshOffersCount(a => a + 1), [setRefreshOffersCount])
    const capitalize = string => !string ? '' : string.split(' ').map(string => string.charAt(0).toUpperCase() + string.slice(1)).join(' ')
    /* useEffect(() => {
        if (contract && showListing && !gotOffers && !loadingOffers) {
            let ids = showListing.offers
            if (!ids?.length) {
                return setOffers([])
            } else {
                if (!showListing.offers?.length) {
                    setOffers([])
                    setGotOffers(true)
                } else {
                    setLoadingOffers(true)
                    multicall(EXCHANGE, ExchangeABI, 'getOfferInfoByIndex', ids.map(id => [id])).then(offers => {
                        setOffers(offers.map((offer, i) => {
                            return {
                                id: ids[i],
                                offerer: offer._offerer,
                                price: new BigNumber(offer._offerAmount.toString()),
                                status: offer._offerStatus
                            }
                        }).reverse())
                        setGotOffers(true)
                    }).catch(e => console.error(e)).finally(() => setLoadingOffers(false))
                }
            }
        }
    }, [showListing, gotOffers, contract]) */
    /*     useEffect(() => {
            if (gotOffers) setGotOffers(false)
        }, [refreshOffersCount]) */
    return (<>
        {showCreateOffer && <CreateOffer minOfferPercentage={minOfferPercentage} minOfferIncrementPercentage={minOfferIncrementPercentage} listing={showListing} create={(amount) => new Promise((res, rej) => createOffer(showListing.id, amount).then((created) => {
            refreshOffers()
            refreshBalance()
            res(created)
        }).catch(rej))} close={() => setShowCreateOffer(false)} />}
        <div className="column w100 afs">
            <button style={{ justifySelf: 'flex-start' }} onClick={() => redirect('/exchange')}>Back</button>
            <h2 style={{ fontFamily: 'cafeteria, sans serif' }}>{showListing.lister === account ? 'Your Listing' : <ShortAddress address={showListing.lister} />}</h2>
        </div>
        <div className="local-listing">
            <div className="local-listing-header">
                <div className="column jfs afs" id="listing-details">
                    <div className="b2 w100 gap" style={{ marginBottom: '.8rem' }}>
                        <div className="input-child column afs w100">
                            <span className="heading">Offering:</span>
                            <span className="input-display">{parseFloat(toTopia(showListing.amount, DECIMALS))} $DUST</span>
                        </div>
                        <div className="input-child column afs w100">
                            <span className="heading">Wants:</span>
                            <span className="input-display">{parseFloat(showListing.price.dividedBy(10 ** 18).toFixed(4))} ETH</span>
                        </div>
                    </div>
                    <h3>Offers ({showListing.offerCount}) {showListing.status === 'settled' && `Winning Bid: ${parseFloat(toTopia(showListing.currentOffer))} ETH`}</h3>
                </div>
                <div className="column jfs afe" id="listing-actions">
                    {new Date().getTime() < showListing.endTime && showListing.status === 'active' ? <h3>Ends In: <TimeSpan endTime={showListing.endTime} /></h3> : <h3>{capitalize(showListing.status)}</h3>}
                    <div className="cta-buttons">
                        {console.log('failed', showListing.status === 'failed', 'settled', showListing.status === 'settled')}
                        {account === showListing.lister && showListing.status === 'active' ? (<><CancelListing id={showListing.id} onCancelled={() => {
                            redirect('/exchange')
                        }} /></>) : (offers.find(a => a.offerer === account) || account === showListing.lister) && new Date().getTime() > showListing.endTime && showListing.status !== 'failed' && showListing.status !== 'settled' ? <ClaimRefund id={showListing.id} onClaimed={() => {
                            console.log('Claimed')
                        }} /> : showListing.status === 'active' && <>

                            <BuyNow alt={2} id={showListing.id} price={showListing.price} onBought={refreshOffers} />
                            <Button onClick={() => setShowCreateOffer(true)}>Make Offer</Button>
                        </>}

                        <div className="refresh-icon" onClick={refreshOffers}>{refreshIcon}</div>
                    </div>
                </div>
            </div>
            <div className="column jfs afs w100" style={{ overflowX: 'auto' }}>
                <table id="offer-list">
                    <thead>
                        <tr>
                            <th>Amount</th>
                            <th>From</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingOffers ? <tr><td colSpan={4} style={{ textAlign: 'center' }}>Loading Offers...</td></tr> : offers?.length ? offers.map((offer, i) => (<tr key={i}>
                            <td>{parseFloat(offer.price.dividedBy(10 ** 18).toFixed(4))} ETH</td>
                            <td style={{ width: '100%' }}>{offer.offerer === account ? 'You' : <ShortAddress address={(offer.offerer)} />}</td>
                            <td>{offer.status === 'outOffered' ? 'Out Bid' : capitalize(offer.status)}</td>
                            {account === showListing.lister && showListing.activeOfferId.toString() === offer.id.toString() && offer.status === 'active' ?
                                <td><Button className="borderless-button" onClick={() => acceptOffer(showListing.id).then(() => refreshBalance())}>Accept</Button></td> :
                                offer.offerer === account && showListing.activeOfferId.toString() === offer.id.toString() && showListing.status === 'active' ?
                                    <td><Button className="borderless-button cancel-button" onClick={() => cancelOffer((showListing.id).toString(), (offer.id).toString()).then(() => refreshBalance())}>Cancel</Button></td> : ''}
                            {offer.offerer === account && showListing.activeOfferId.toString() === offer.id.toString() && (showListing.status === 'ended' || new Date().getTime() > showListing.endTime) && showListing.status !== 'failed' && showListing.status !== 'settled' && <td><Button onClick={() => claimRefund(showListing.id).then(() => refreshBalance())}>Refund</Button></td>}
                        </tr>)) : <tr><td colSpan={4} style={{ textAlign: 'center' }}><span>No Offers Currently</span>
                            {showListing.status === 'active' && <div className="cta-buttons">
                                <BuyNow id={showListing.id} onBought={refreshOffers} />
                                <Button alt onClick={() => setShowCreateOffer(true)}>Make Offer</Button>
                            </div>}
                        </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </>)
}
export default function Exchange() {
    const { account, redirect, queries, flash, width, balance, ethPrice: etherPrice, refreshEthPrice: refreshEtherPrice } = useAppContext()
    useEffect(() => {
        let interval = setInterval(() => refreshEtherPrice(), 180000)
        return () => clearInterval(interval)
    })
    const toDollars = useCallback((ethAmount) => {
        let dollars = new BigNumber(etherPrice)
        return ethAmount.dividedBy(10 ** 18).multipliedBy(dollars).toFixed(2)
    }, [etherPrice])
    const { contract, getAllListings, getListing, createListing, getOffers, getMinListPrice, getPaused, getMinOfferPercentage, getMinOfferIncrementPercentage } = useExchangeContract()
    const [display, setDisplay] = useState(localStorage.getItem(displayKey) ?? 'grid')
    const [sortMode, setSortMode] = useState(localStorage.getItem(sortKey) ?? 'price')
    const [filters, setFilters] = useState([])
    const [showCreateListing, setShowCreateListing] = useState(false)
    const [loadingListings, setLoadingListings] = useState(false)
    const [gotListings, setGotListings] = useState(false)
    const [listingRefreshes, setListingRefreshes] = useState(0)
    const refreshListings = useCallback(() => setListingRefreshes(a => a + 1), [setListingRefreshes])
    const capitalize = string => !string ? '' : string.split(' ').map(string => string.charAt(0).toUpperCase() + string.slice(1)).join(' ')
    const [showActive, setShowActive] = useState(true)
    const [showListers, setShowListers] = useState(false)
    const [minListPrice, setMinListPrice] = useState(new BigNumber(0))
    const [minOfferPercentage, setMinOfferPercentage] = useState(0)
    const [minOfferIncrementPercentage, setMinOfferIncrementPercentage] = useState(0)
    const [isPaused, setIsPaused] = useState(true)
    const dustForm = toTopia(balance.toString(), 3)
    const { balance: sweepersBalance } = useSweepersBalance(account)
    const [exchangeStats, setExchangeStats] = useState({})
    const [gettingExchangeStats, setGettingExchangeStats] = useState(false)
    const [listings, setListings] = useState([
        /*               
           {
                id: 1,
                lister: '0x0000000000000000000000000000000000000000',
                amount: new BigNumber(300).multipliedBy(10 ** 18),
                price: new BigNumber(9).multipliedBy(10 ** 18),
                startTime: new Date().getTime(),
                endTime: (new Date().getTime() + (1000 * 60 * 60)),
                offerer: '0x0000000000000000000000000000000000000000',
                currentOffer: null,
                previousOffer: null,
                activeOfferId: 1,
                offerCount: 0,
                status: 'active',
                offers: []
            },
            {
                id: 2,
                lister: '0x0000000000000000000000000000000000000000',
                amount: new BigNumber(19).multipliedBy(10 ** 18),
                price: new BigNumber(11).multipliedBy(10 ** 18),
                startTime: new Date().getTime(),
                endTime: (new Date().getTime() + (1000 * 60 * 20)),
                offerer: '0x0000000000000000000000000000000000000000',
                currentOffer: null,
                previousOffer: null,
                activeOfferId: 1,
                offerCount: 0,
                status: 'active',
                offers: []
            },
            {
                id: 3,
                lister: '0x61F8Db136DCdsafdasfdasfdesE6a17109Ce4C6C2547e4105425',
                amount: new BigNumber(20).multipliedBy(10 ** 18),
                price: new BigNumber(10).multipliedBy(10 ** 18),
                startTime: new Date().getTime(),
                endTime: (new Date().getTime() + (1000 * 60 * 10)),
                offerer: '0x61F8Db136DC888E6a17109Ce4C6C2547e4105425',
                currentOffer: new BigNumber(10).multipliedBy(10 ** 18),
                previousOffer: null,
                activeOfferId: 1,
                offerCount: 1,
                status: 'active',
                offers: [
                    {
                        id: 1,
                        offerer: '0x61F8Db136DC888E6a17109Ce4C6C2547e4105425',
                        price: new BigNumber(10).multipliedBy(10 ** 18),
                        status: 'active'
                    }
                ]
            } 
        */
    ])
    useEffect(() => {
        if (!gettingExchangeStats) {
            setGettingExchangeStats(true)
            fetch(`${process.env.REACT_APP_EXCHANGE_API}/exchange-stats`).then(res => res.json()).then(data => {
                if (data.error) return console.log(data.message || JSON.stringify(error))
                setExchangeStats(data)
            }).catch(e => {
                console.log(e)
            }).finally(() => setGettingExchangeStats(false))
        }
    }, [])
    useEffect(() => {
        if (contract && !gotListings && !loadingListings) {
            setLoadingListings(true)
            console.log('getting listings')
            fetch(`${process.env.REACT_APP_EXCHANGE_API}/listings`).then(res => res.json()).then(async data => {
                if (data.error) return console.log(data.message || JSON.stringify(error))
                let transformedListings = data.map(d => ({ ...d, amount: new BigNumber(d.amount), price: new BigNumber(d.price), currentOffer: new BigNumber(d.currentOffer), previousOffer: new BigNumber(d.previousOffer), status: d.status !== 'active' && new Date() - d.endTime > 0 ? 'ended' : d.status }))
                const gatherMinListPrice = () => {
                    return new Promise((res, rej) => {
                        getMinListPrice().then(minListPrice => {
                            setMinListPrice(minListPrice)
                            res()
                        }).catch(e => rej(e))
                    })
                }
                const gatherMinOfferPercentage = () => {
                    return new Promise((res, rej) => {
                        getMinOfferPercentage().then(minOfferPercentage => {
                            setMinOfferPercentage(minOfferPercentage)
                            res()
                        }).catch(e => rej(e))
                    })
                }
                const gatherMinOfferIncrementPercentage = () => {
                    return new Promise((res, rej) => {
                        getMinOfferIncrementPercentage().then(minOfferIncrementPercentage => {
                            setMinOfferIncrementPercentage(minOfferIncrementPercentage)
                            res()
                        }).catch(e => rej(e))
                    })
                }
                await gatherMinListPrice().catch(e => { console.error(e); setMinListPrice(new BigNumber(0)) })
                await gatherMinOfferPercentage().catch(e => { console.error(e); setMinOfferPercentage(0) })
                await gatherMinOfferIncrementPercentage().catch(e => { console.error(e); setMinOfferIncrementPercentage(0) })
                setListings(transformedListings)
                setLoadingListings(false)
                setGotListings(true)
                return getPaused()
            }).then(result => {
                setIsPaused(result)
            }).catch(e => {
                console.log(e)
                flash(e.message)
            }).finally(() => {
                setLoadingListings(false)
            })
        } else if (!contract && listings.length) {
            setListings([])
        }
    }, [contract, gotListings])
    useEffect(() => {
        if (gotListings) { console.log('refresh listings'); setGotListings(false) }
    }, [listingRefreshes])
    const showListing = useMemo(() => {
        return listings?.find(l => l.id == queries.listing)
    }, [queries, listings])
    const pollListing = useMemo(() => {
        return Boolean(showListing)
    }, [showListing])
    const [gatheringCurrentListing, setGatheringCurrentListing] = useState(false)
    useEffect(() => {
        if (pollListing) {
            const interval = setInterval(() => {
                if (gatheringCurrentListing) return
                console.log('refreshing current listing')
                setGatheringCurrentListing(true)
                fetch(`${process.env.REACT_APP_EXCHANGE_API
                    }/listing?id=${showListing.id}`).then(r => r.json()).then(r => {
                        if (r.error) return console.log(r.message || JSON.stringify(r))
                        const transformedListing = { ...r, amount: new BigNumber(r.amount), price: new BigNumber(r.price), currentOffer: new BigNumber(r.currentOffer), previousOffer: new BigNumber(r.previousOffer), status: r.status !== 'active' && new Date() - r.endTime > 0 ? 'ended' : r.status }
                        setListings(listings => {
                            return listings.map(l => {
                                if (l.id == transformedListing.id) {
                                    return transformedListing
                                } else {
                                    return l
                                }
                            })
                        })
                    }).catch(e => {
                        flash(e?.message)
                        console.log(e)
                    }).finally(() => {
                        setGatheringCurrentListing(false)
                    })
            }, 1000 * 10)
            return () => clearInterval(interval)
        }
    }, [showListing])
    const [showingFilters, setShowingFilters] = useState(false)
    const sorter = useCallback((a, b) => {
        if (a && !b) return -1
        if (!a && b) return 1
        if (!a && !b) return 0
        if (sortMode == 'price') {
            return (a.price?.dividedBy(a.amount)).comparedTo(b.price.dividedBy(b.amount))
        } else if (sortMode == 'amount') {
            return b.amount?.comparedTo(a.amount)
        } else if (sortMode == 'end') {
            return a.endTime - b.endTime
        } else {
            return a.startTime - b.startTime
        }
    }, [sortMode])
    const filter = useCallback((listings) => {
        return showListers ? listings.filter(a => a.lister === account) : showActive ? listings.filter(a => a.status === 'active' && a.endTime > new Date().getTime()) : listings.filter(a => a.status !== 'active' || a.endTime <= new Date().getTime())
        /*         if (!filters?.length) return listings
                let countFilter = filters.find(f => f.type == 'count')
                let priceFilter = filters.find(f => f.type == 'price')
                let amountFilter = filters.find(f => f.type == 'amount')
                let startFilter = filters.find(f => f.type == 'start')
                let endFilter = filters.find(f => f.type == 'end')
                let offerPriceFilter = filters.find(f => f.type == 'offerPrice')
                return listings.filter(l => {
                    let count = countFilter?.value ?? 0
                    let price = priceFilter?.value ?? 0
                    let amount = amountFilter?.value ?? 0
                    let start = startFilter?.value ?? 0
                    let end = endFilter?.value ?? 0
                    let offerPrice = offerPriceFilter?.value ?? 0
                    let countPass = count ? l.offerCount >= count : true
                    let pricePass = price ? l.price.comparedTo(price) >= 0 : true
                    let amountPass = amount ? l.amount.comparedTo(amount) >= 0 : true
                    let startPass = start ? l.startTime >= start : true
                    let endPass = end ? l.endTime <= end : true
                    let offerPricePass = offerPrice ? l.currentOffer?.comparedTo(new BigNumber(offerPrice).multipliedBy(10 ** 18)) >= 0 : true
                    return countPass && pricePass && amountPass && startPass && endPass && offerPricePass
                }) */
    }, [filters, showActive, showListers])
    const filteredListings = useMemo(() => {
        return filter(listings.filter((a, i) => i < listings.length - 1)).sort(sorter)
    }, [listings, filter, sorter])
    const { items: reducedListings = [], Paginator } = usePagination(filteredListings, PERPAGE)
    return (<Paralax image={''} style={{ flexGrow: 2 }}>
        <div id="exchange" style={{ padding: '5rem 0' }}>
            {showCreateListing && <CreateListing minListPrice={minListPrice} create={(amount, price, endTime) => {
                return new Promise((res, rej) => {
                    createListing(amount, price, endTime).then((result) => {
                        if (!result) return rej('Failed to get created info')
                        const [listingId, startTime, endtime, topiaAmount, requestedEth] = result
                        const created = {
                            id: listingId,
                            lister: account,
                            amount: new BigNumber(topiaAmount.toString()),
                            price: new BigNumber(requestedEth.toString()),
                            startTime: startTime.toNumber() * 1000,
                            endTime: endtime.toNumber() * 1000,
                            offerer: null,
                            currentOffer: null,
                            previousOffer: null,
                            activeOfferId: null,
                            offerCount: 0,
                            status: 'active',
                            offers: []
                        }
                        setListings(l => [...l, created])
                        res(created)
                    }).catch(rej)
                })
            }} close={() => setShowCreateListing(false)} />}
            {showListing ? (<Listing toDollars={toDollars} minOfferPercentage={minOfferPercentage} minOfferIncrementPercentage={minOfferIncrementPercentage} showListing={showListing} />) : (<>
                {showingFilters && <div className="filters">
                    {/* 

                FILTER BY
                    ETH/DUST - GT/LT
                    AMOUNT - GT/LT
                    END TIME - GT/LT
                    START TIME - GT/LT
                    STATUS - ACTIVE/SETTLED/CANCELED/FAILED
                    OFFER COUNT - GT/LT
                    OFFER PRICE - GT/LT

                    {type, value}

*/}

                </div>}
                <div className="vault-info-container">
                    <div className="vault-text">
                        <h1>$DUST Exchange</h1>
                        <h5>{`You have ${sweepersBalance} ${sweepersBalance === 1 ? 'Sweeper' : 'Sweepers'} and ${parseFloat(dustForm)} $DUST`}</h5>
                        <p>The $DUST exchange is a platform that allows holders to buy or sell $DUST to other users. This peer-to-peer exchange platform has no limit on how much $DUST can be sold. Here, holders can list their $DUST Token for a buy-it-now price and accept offers on their chosen balance.</p>
                    </div>
                    <Container className="exchange-stats">
                        <h4>Exchange Info</h4>
                        {Object.entries(exchangeStats).filter(([k]) => statKeys.includes(k)).map(([k, v], i) => (<div key={i} className="listing-details">
                            <span className="heading">{k}:</span>
                            <span className="subheading">{v}</span>
                        </div>))}
                    </Container>
                </div>
                <div className="column jfs afs w100">
                    <h3 style={{ margin: 0, marginTop: '1rem' }}>Global Listings</h3>
                    <div className="exchange-options">
                        <div className="sorts-displays-options">
                            <div className="sorts">
                                <span style={{ whiteSpace: 'nowrap' }}>Sort By:</span>
                                <select value={sortMode} onChange={e => {
                                    localStorage.setItem(sortKey, e.target.value)
                                    setSortMode(e.target.value)
                                }}>
                                    <option value="price">Best Value</option>
                                    <option value="amount">Quantity</option>
                                    <option value="start">Start Time</option>
                                    <option value="end">End Time</option>
                                </select>
                            </div><div className="b2 gap jfs">
                                {!showActive ? <Button className="borderless-button" alt onClick={e => { setShowActive(current => !current); setShowListers(false) }}>Active</Button> : <Button className="borderless-button" alt onClick={e => { setShowActive(current => !current); setShowListers(false) }} >Inactive</Button>}
                                {!showListers ? <Button className="borderless-button" alt onClick={e => setShowListers(current => !current)}>Your Listings</Button> : <Button className="borderless-button" alt onClick={e => setShowListers(current => !current)} >All</Button>}
                            </div>
                            {/* <span className="filters-toggle" onClick={() => setShowingFilters(a => !a)}>{!showingFilters ? 'Show Filters' : 'Hide Filters'}</span> */}
                            <div className="displays">
                                <div onClick={() => { localStorage.setItem(displayKey, 'grid'); setDisplay('grid') }} className={`display-icon pointer ${display === 'grid' ? 'active' : ''}`}>{gridIcon}</div>
                                <div onClick={() => { localStorage.setItem(displayKey, 'table'); setDisplay('table') }} className={`display-icon pointer ${display === 'table' ? 'active' : ''}`}>{tableIcon}</div>
                            </div>
                        </div>
                        {!isPaused && <div className="cta-options">
                            <Button disabled={!account} onClick={() => {
                                setShowCreateListing(true)
                            }}>Create</Button>
                            <div className="refresh-icon" onClick={() => refreshListings()}>{refreshIcon}</div>
                        </div>}
                    </div>
                    <div id="listings">
                        {loadingListings ? <span>Loading listings...</span> : reducedListings?.length ? <>
                            {display === 'table' ? <div className="table-view">
                                {reducedListings.map(({ id, lister, amount, price, endTime = 0, offerCount, currentOffer, status }, i) => <Container key={i} className="table-item">
                                    <div className="column ac">
                                        <span className="heading">Address:</span>
                                        <span className="subheading"><ShortAddress address={lister} /></span>
                                    </div>
                                    <div className="column ac">
                                        <span className="heading">Listing:</span>
                                        <span className="subheading">{parseFloat(toTopia(amount, DECIMALS))} $DUST</span>
                                    </div>
                                    <div className="column ac">
                                        <span className="heading">Wants:</span>
                                        <span className="subheading">{parseFloat(toTopia(price, DECIMALS))} ETH</span>
                                    </div>
                                    <div className="column ac">
                                        <span className="heading">Rate:</span>
                                        <span className="subheading">{Number(amount.dividedBy(price)).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4 })} $DUST/ETH</span>
                                    </div>
                                    <div className="column ac">
                                        <span className="heading">Ends In:</span>
                                        <span className="subheading"><TimeSpan endTime={endTime} /></span>
                                    </div>
                                    {offerCount > 0 && <div className="column ac">
                                        <span className="heading">Offers:</span>
                                        <span className="subheading">{offerCount}</span>
                                    </div>}
                                    {currentOffer && <div className="column ac">
                                        <span className="heading">Current Offer:</span>
                                        <span className="subheading">{parseFloat(toTopia(currentOffer))} ETH (${toDollars(currentOffer).toString()})</span>
                                    </div>}
                                    <div className="column ac">
                                        <span className="heading">Status:</span>
                                        <span className="subheading">{capitalize(status)}</span>
                                    </div>
                                    <div className="cta-buttons">
                                        {status === 'active' && new Date().getTime() < endTime && <BuyNow price={price} className="square variant" id={id} onBought={refreshListings} />}
                                        <Button className="square transparent" alt onClick={() => redirect(`/exchange?listing=${id}`)}>View <div className="carrot">{eyeBall}</div></Button>
                                    </div>
                                </Container>)}
                            </div> : <div className="grid-view">
                                {reducedListings.map(({ id, lister, amount, price, endTime = 0, currentOffer, offerCount, status }, i) => <Container key={i} className="grid-item">
                                    <div className="column afs jfs w100">
                                        <span className="grid-heading"><ShortAddress address={lister} /></span>
                                    </div>
                                    <div className="input-child column afs w100">
                                        <span className="heading">Offering:</span>
                                        <span className="input-display">{parseFloat(toTopia(amount, DECIMALS))} $DUST</span>
                                    </div>
                                    <div className="input-child column afs w100">
                                        <span className="heading">Wants:</span>
                                        <span className="input-display">{parseFloat(toTopia(price, DECIMALS))} ETH</span>
                                    </div>
                                    <span className="align-text-left w100">{toDollars(price).toString()} USD</span>
                                    <div className="responsive-two-column">
                                        <div className="grid-item-child column ac jc w100">
                                            <span className="heading">Rate:</span>
                                            <span className="subheading">{Number(amount.dividedBy(price)).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4 })} $DUST/ETH</span>
                                        </div>
                                        {status === 'active' && <div className="grid-item-child  column ac jc w100">
                                            <span className="heading">Ends in:</span>
                                            <span className="subheading"><TimeSpan endTime={endTime} /></span>
                                        </div>}
                                        {offerCount > 0 && <div className="grid-item-child  column ac jc w100">
                                            <span className="heading">Offers:</span>
                                            <span className="subheading">{offerCount}</span>
                                        </div>}
                                        {offerCount > 0 && currentOffer && <div className="grid-item-child  column ac jc w100">
                                            <span className="heading">Current Offer:</span>
                                            <div className="column ac">
                                                <span className="subheading">{parseFloat(toTopia(currentOffer))} ETH</span>
                                                <span>{toDollars(currentOffer).toString()} USD</span>
                                            </div>
                                        </div>}

                                        {/* <div className={`grid-item-child ${status === 'active' ? 'b2 jsb long-row' : 'column afs jfs'}  w100`}> */}
                                        <div className="grid-item-child b2 jsb long-row w100">
                                            <span className="heading">Status:</span>
                                            <span className="subheading">{capitalize(status)}</span>
                                        </div>
                                    </div>
                                    <div className="cta-buttons">
                                        {account === lister && status === 'active' && new Date().getTime() >= endTime ? <ClaimRefund id={id} onClaimed={() => { console.log('claimed') }} /> : account === lister && status === 'active' ? <CancelListing id={id} onCancelled={() => {
                                            setListings(a => a.map(u => {
                                                if (u.id === id) {
                                                    u.status = 'canceled'
                                                }
                                                return u
                                            }))
                                        }} /> : status === 'active' && new Date().getTime() < endTime && <BuyNow id={id} price={price} onBought={refreshListings} />}
                                        <Button alt onClick={() => redirect(`/exchange?listing=${id}`)}>View</Button>
                                    </div>
                                </Container>)}
                            </div>}
                        </> : <div className="no-listings column jfs"><span style={{ textAlign: 'center' }}>No listings found</span>
                            <Button onClick={() => setShowCreateListing(true)}>Create a Listing</Button>
                        </div>}
                    </div>
                    {Paginator}
                </div>
            </>)}
        </div>
    </Paralax>)
}